import {
  getAllReports,
  getAllReportParamenter,
  generateAReport,
  generateTellerReport,
  getOffices,
  getStaffSelects,
  getStaffSelectBranchs,
  getStaffInOffice,
  getCurrency,
  getALoanProduct,
  getALoanPurpose,
  getALoanFund,
  getASavingsProduct,
  getAReportStatus,
  getALoanParSelect,
  getAllPaymentTypes,
  getAllAccount,
  getAllObligations,
  getReportFullParamenterList,
  getAccountOfficerSelects,
  getClientSelects
} from "../services/Api/report.api.services";

import {
  SET_ALL_REPORTS,
  GET_ALL_REPORTS_PARAMETERS,
  GET_ALL_REPORTS_FULL_PARAMETERS,
  GENERATED_REPORT,
  GET_ALL_OFFICES,
  GET_ALL_STAFF_SELECTS,
  GET_ALL_STAFF_SELECT_BRANCHS,
  GET_ALL_STAFFS,
  GET_ALL_CURRENCIES,
  GET_LOAN_PRODUCT,
  GET_LOAN_FUND,
  GET_LOAN_PURPOSE,
  GET_REPORT_STATUS,
  GENERATING_REPORTS,
  GET_SAVINGS_PRODUCT,
  GET_PAR_SELECT,
  GET_REPORT_PAYMENT_TYPES,
  GET_REPORT_ACCOUNT,
  GET_REPORT_LOAN_OBLIGATIONS,
  // GET_ALL_ACCOUNTOFFICER_SELECTS,
  GET_ACCOUNT_OFFICER_SELECTS,
  GET_ALL_CLIENT_SELECTS,
  ALL_STAFF_SELECT_RESET
} from "../constants/reportsConstants";
import { SET_LOADING_STATE } from "../constants/utilityConstants";

import Toast from "../util/Toast";

const handleError = (ex, dispatch) => {
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: "error",
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
};

const getReports = () => {
  return async (dispatch) => {
    dispatch({ type: SET_ALL_REPORTS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllReports()
      .then((response) => {
        dispatch({
          type: SET_ALL_REPORTS,
          payload: response.data,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getReportParameters = (params) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_REPORTS_PARAMETERS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllReportParamenter(params)
      .then((response) => {
        dispatch({
          type: GET_ALL_REPORTS_PARAMETERS,
          payload: response.data,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};
const getReportFullParameters = (reportListing) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_REPORTS_FULL_PARAMETERS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getReportFullParamenterList(reportListing)
      .then((response) => {
        dispatch({
          type: GET_ALL_REPORTS_FULL_PARAMETERS,
          payload: response.data,
        });
        // console.log(response)
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const generateReport = (name, params, report) => {
  return async (dispatch) => {
    dispatch({ type: GENERATED_REPORT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await generateAReport(name, params)
      .then((response) => {
        if (!!report && !!report.type && report.type !== "R_exportJSON") {
          dispatch(
            Toast({
              text: "Generating report. You would be notified after generating report",
              icon: "success",
              duration: 4,
            })
          );
          report.code = response.requestId;
          dispatch({
            type: GENERATING_REPORTS,
            payload: report,
          });
        }
        dispatch({
          type: GENERATED_REPORT,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const generateTelReport = (tellerId , dropdownValue) => {
  return async (dispatch) => {
    dispatch({ type: GENERATED_REPORT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await generateTellerReport(tellerId , dropdownValue)
      .then((response) => {
        dispatch(
          Toast({
            text: "Generating report. Report would download shortly.",
            icon: "success",
            duration: 4,
          })
        );
        const report = {};
        report.code = response.requestId;
        report.status = response.status;
        dispatch({
          type: GENERATING_REPORTS,
          payload: report,
        });

        dispatch({
          type: GENERATED_REPORT,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getReportOffices = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_OFFICES });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getOffices()
      .then((response) => {
        dispatch({
          type: GET_ALL_OFFICES,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// Get Report Staff Select 
const getReportStaffSelects = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_STAFF_SELECTS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getStaffSelects()
      .then((response) => {
        dispatch({
          type: GET_ALL_STAFF_SELECTS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// Get Report Staff Select By Branch
const getReportStaffBranchs = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_STAFF_SELECT_BRANCHS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getStaffSelectBranchs(officeId)
      .then((response) => {
        dispatch({
          type: GET_ALL_STAFF_SELECT_BRANCHS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

//Reset Report Staff Branch Select
const resetGetReportStaffBranchs = () => {
  return (dispatch) => {
    dispatch({type : ALL_STAFF_SELECT_RESET});
  }
}

// Get Account Officer Select Report
const getReportAccountOfficerSelects = (clientId) => {
  return async (dispatch) => {
    dispatch({ type: GET_ACCOUNT_OFFICER_SELECTS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAccountOfficerSelects(clientId)
      .then((response) => {
        // console.log(response, "response")
        dispatch({
          type: GET_ACCOUNT_OFFICER_SELECTS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

// Get Client Select Report
const getReportClientSelects = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_CLIENT_SELECTS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getClientSelects()
      .then((response) => {
        dispatch({
          type: GET_ALL_CLIENT_SELECTS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getReportStaffs = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_STAFFS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getStaffInOffice(id)
      .then((response) => {
        dispatch({
          type: GET_ALL_STAFFS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getReportTypes = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_STAFFS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllPaymentTypes()
      .then((response) => {
        dispatch({
          type: GET_REPORT_PAYMENT_TYPES,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllCurrency = () => {
  return async (dispatch) => {
    dispatch({ type: GET_ALL_CURRENCIES });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getCurrency()
      .then((response) => {
        dispatch({
          type: GET_ALL_CURRENCIES,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllLoanProduct = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_LOAN_PRODUCT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getALoanProduct(id)
      .then((response) => {
        dispatch({
          type: GET_LOAN_PRODUCT,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllSavingsProduct = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_SAVINGS_PRODUCT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getASavingsProduct(id)
      .then((response) => {
        dispatch({
          type: GET_SAVINGS_PRODUCT,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllLoanPurpose = () => {
  return async (dispatch) => {
    dispatch({ type: GET_LOAN_PURPOSE });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getALoanPurpose()
      .then((response) => {
        dispatch({
          type: GET_LOAN_PURPOSE,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllParSelect = () => {
  return async (dispatch) => {
    dispatch({ type: GET_PAR_SELECT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getALoanParSelect()
      .then((response) => {
        dispatch({
          type: GET_PAR_SELECT,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAccounts = () => {
  return async (dispatch) => {
    dispatch({ type: GET_REPORT_ACCOUNT });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllAccount()
      .then((response) => {
        dispatch({
          type: GET_REPORT_ACCOUNT,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllLoanFund = () => {
  return async (dispatch) => {
    dispatch({ type: GET_LOAN_FUND });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getALoanFund()
      .then((response) => {
        dispatch({
          type: GET_LOAN_FUND,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getAllLoanObligation = () => {
  return async (dispatch) => {
    dispatch({ type: GET_REPORT_LOAN_OBLIGATIONS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAllObligations()
      .then((response) => {
        dispatch({
          type: GET_REPORT_LOAN_OBLIGATIONS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

const getReportStatus = (code) => {
  return async (dispatch) => {
    dispatch({ type: GET_REPORT_STATUS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getAReportStatus(code)
      .then((response) => {
        dispatch({
          type: GET_REPORT_STATUS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
      });
  };
};

export {
  getReports,
  getReportParameters,
  generateReport,
  getReportOffices,
  getReportStaffSelects,
  generateTelReport,
  getReportStaffs,
  getAllCurrency,
  getAllLoanProduct,
  getAllLoanPurpose,
  getAllLoanFund,
  getAllSavingsProduct,
  getReportStatus,
  getAllParSelect,
  getReportTypes,
  getAccounts,
  getAllLoanObligation,
  getReportFullParameters,
  getReportAccountOfficerSelects,
  getReportStaffBranchs,
  getReportClientSelects,
  resetGetReportStaffBranchs
};
