import config from "../../config";
import axiosData from "../axiosData";
import AuthModule from "../../modules/Auth.module";

export const getOneSavingsAccount = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?associations=all`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getOneSavingsAccountTransactions = (id, dataToSend, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/transactions${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAllSavingsAccountEvents = (id, dataToSend, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/events/savings/${id}/${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAllOptionsAccountEvents = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        url: `${config.apiGateway.BASE_API_URL}/events`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const handleAnEventUpdate = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/events/association`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getOneLoanAccountTransactions = (id, params) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/transactions${params}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getOneSavingsAccountTemplate = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getOneLoanDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}?associations=all&exclude=guarantors,futureSchedule`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getACustomerStaff = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/clients/${id}?staffInSelectedOfficeOnly=true&template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const assignAStafftoCustomer = (id, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/clients/${id}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const assignAStafftoAccount = (id, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const approveASavingsAccount = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?command=approve`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getSavingsTransactionReceipt = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.DYNAMIC_REPORT_API_URL}/reports/transaction_receipt?transactionId=${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const payASavingsAccountCharge = (id, chargeId, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/charges/${chargeId}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const waiveASavingsAccountCharge = (
  id,
  chargeId,
  dataToSend,
  action
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/charges/${chargeId}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const addLoanCharge = (loanId, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/charges`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const payALoanAccountCharge = (id, chargeId, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/charges/${chargeId}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const waiveALoanAccountCharge = (id, chargeId, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/charges/${chargeId}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const activateASavingsAccount = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?command=activate`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getAccountEmailNotification = (accountNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/datatables/Email%20Notifications/${accountNumber}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getAccountSMSNotification = (accountNumber) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/datatables/SMS%20Notifications/${accountNumber}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const setAccountSMSNotification = (
  accountNumber,
  dataToSend,
  actionType
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: actionType,
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/datatables/SMS%20Notifications/${accountNumber}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const setAccountEmailNotification = (
  accountNumber,
  dataToSend,
  actionType
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: actionType,
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/datatables/Email%20Notifications/${accountNumber}?genericResultSet=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const postSavingsAccountIntrest = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?command=postInterest`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getLoanAccountsChargeTemplate = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/charges/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const getSavingsAccountsChargeTemplate = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/charges/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const addASavingsAccountCharge = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/charges`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const blockASavingsAccountCharge = (id, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getASavingsAccountTrasactionTemplate = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}/transactions/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const closeASavingsAccount = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?command=close`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const rejectAndWithdrawSavingsAccount = (id, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const deleteASavingsAccount = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const activateAPndSavindsAccount = (id, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getATransferSavingsAccountTemplate = (id, otherAction) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/accounttransfers/template?fromAccountId=${id}&fromAccountType=2&${otherAction}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getALoanDocument = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        data: id,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/documents`,
        isBearerToken: true,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const uploadALoanDocument = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/documents`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAloanOfficer = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}?fields=id,loanOfficerId,loanOfficerOptions&staffInSelectedOfficeOnly=true&template=true`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const assignAloanOfficer = (id, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getAloanDisburseDetails = (id, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/transactions/template?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const disburseALoanAccount = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}?command=disbursetosavings`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const withdrawALoan = (id, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const forClosureALoan = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        // url: `${config.apiGateway.BASE_API_URL}/loans/${id}/transactions?command=foreclosure`,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/transactions?command=foreclosureWithLinked`,
        // command=foreclosureWithLinked
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// actions = repay, repayment
export const getARepayLoanTemplate = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/transactions/template?command=prepayLoan`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const deleteALoan = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const handleALoanTransaction = (id, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/transactions?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// undoApprovedALoanAccount
export const undoApprovedALoanAccount = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}?command=undoapproval`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const undoApprovedASavingAccount = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/savingsaccounts/${id}?command=undoapproval`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// undoDisburseALoanAccount
export const undoDisburseALoanAccount = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}?command=undodisbursal`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};
export const postALoanCommand = (id, dataToSend, action) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}?command=${action}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const multipleAccTransfer = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        // https://core.staging.woodcoreapp.com/woodcore/api/v1/multipletransfers
        url: `${config.apiGateway.BASE_API_URL}/multipletransfers`,

      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const accTransfer = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/accounttransfers`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const deleteALoanDocument = (id, documentId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // signal: cancelToken ? cancelToken : false,
        data: documentId,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/documents/${documentId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getRescheduleLoanTemplate = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/rescheduleloans/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getRescheduleLoanDetails = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/rescheduleloans/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const previewLoanReschedule = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/rescheduleloans/${id}?command=previewLoanReschedule`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const rescheduleALoan = (dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/rescheduleloans?command=reschedule`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const approveReschedule = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/rescheduleloans/${id}?command=approve`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const rejectReschedule = (id, dataToSend) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/rescheduleloans/${id}?command=reject`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const createALoanGuarantor = (dataToSend, loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // signal: cancelToken ? cancelToken : false,
        data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/guarantors`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getASavingsAccountAudit = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.PRE_AUTH_API_URL}/audit/savings/1?from_page=5&to_page=0`,
        headers: {
          "Content-Type": "woodcoretenant/default",
        },
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getALoanAccountAudit = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.PRE_AUTH_API_URL}audit/loans/1?from_page=5&to_page=0`,
        headers: {
          "Content-Type": "woodcoretenant/default",
        },
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getALoanAccountCharges = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.BASE_API_URL}/loans/2/charges/1`,
        headers: {
          Authorization: `Bearer ${AuthModule.getToken()}`,
          "Content-Type": "Woodcoretenant/default",
        },
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getASavingsAccountChart = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.PRE_AUTH_API_URL}/statistics/savings/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getLoanGuarantorTemplate = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/guarantors/template`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getLoanGuarantors = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: activity,
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/guarantors`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Delete Guarantor
export const deleteLoanGuarantor = (
  loanId,
  guarantorId,
  guarantorFundingId
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        // data: holidayData,
        url: guarantorFundingId
          ? `${config.apiGateway.BASE_API_URL}/loans/${loanId}/guarantors/${guarantorId}?guarantorFundingId=${guarantorFundingId}`
          : `${config.apiGateway.BASE_API_URL}/loans/${loanId}/guarantors/${guarantorId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// recover Guarantor
export const recoverLoanGuarantor = (loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        // data: holidayData,
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}?command=recoverGuarantees`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const getALoanAccountChart = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        // signal: cancelToken ? cancelToken : false,
        // data: dataToSend,
        url: `${config.apiGateway.PRE_AUTH_API_URL}/statistics/loan/${id}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

// Get  a loan account
const getALoanAccount = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}?associations=charges,collateral,meeting,multiDisburseDetails&staffInSelectedOfficeOnly=true&template=true`,

        // v1/loans/40?associations=charges,collateral,meeting,multiDisburseDetails&staffInSelectedOfficeOnly=true&template=true
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Update Loan Account information
 */
export const editLoanAccountInfo = (userData, loanId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        data: userData,
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

/**
 * Get loan collateral template
 */
export const generateRepaymentSchedule = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        url: `${config.apiGateway.BASE_API_URL}/loans?command=calculateLoanSchedule`,
        data: payload,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const editRepaymentSchedule = (loanId, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/modifyrepaymentschedule`,
        data: payload,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const fetchLoanNotes = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/notes`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const createLoanNote = (id, dataPayload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataPayload,
        url: `${config.apiGateway.BASE_API_URL}/loans/${id}/notes`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const updateLoanNote = (loanId, noteId, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/notes/${noteId}`,
        data: payload,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const deleteLoanNote = (loanId, noteId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        url: `${config.apiGateway.BASE_API_URL}/loans/${loanId}/notes/${noteId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const fetchSavingsNotes = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "GET",
        url: `${config.apiGateway.BASE_API_URL}/savings/${id}/notes`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const createSavingsNote = (id, dataPayload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "POST",
        data: dataPayload,
        url: `${config.apiGateway.BASE_API_URL}/savings/${id}/notes`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const updateSavingsNote = (savingsId, noteId, payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "PUT",
        url: `${config.apiGateway.BASE_API_URL}/savings/${savingsId}/notes/${noteId}`,
        data: payload,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export const deleteSavingsNote = (savingsId, noteId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data, error } = await axiosData({
        method: "DELETE",
        url: `${config.apiGateway.BASE_API_URL}/savings/${savingsId}/notes/${noteId}`,
      });
      if (data) {
        resolve(data);
      } else {
        reject(error);
      }
    } catch (ex) {
      reject(ex);
    }
  });
};

export default {
  getALoanAccount,
};
