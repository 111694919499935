import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ContentWrapper, GeneratedReportContainer, IconButton } from "./styles";
import { Table } from "antd";
import { getReports } from "../../../actions/reportActions";
import { formatDate } from "../../../util/FormatDate";
import Countdown from "react-countdown";
import ModalContainer from "../components/ModalContainer";
import ReportDetailsModal from "./ReportDetailsModal";
import styled from "styled-components";

const ReportView = ({ history, repData }) => {
  const [expiredStatus, setExpiredStatus] = useState([]);
  const [modalState, toggleModal] = useState(false);
  const [currentReport, setCurrentReport] = useState({});
  const [dataToMap, setDataToMap] = useState([]);
  const dispatch = useDispatch();

  const reportData = useSelector((state) => state.reportData);

  let {
    allReports = [],
    generatedReport = {},
    generatingReports = [],
  } = reportData || {};

  let { columnHeaders = [], data = [] } = generatedReport || {};
  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Category", dataIndex: "category", key: "category" },
  ];

  let reportTableData = [];
  let letdata = [];
  const generatedReportData = [];


  data.map((mydata, index) => {

    let { row } = mydata;
    let myExcelArray = {};
    columnHeaders.map((header, index) => {
      myExcelArray[header.columnName] = row[index];
    });
    letdata.push(myExcelArray);
  });

  useEffect(() => {
    dispatch(getReports());
  }, []);


  !!dataToMap &&
    !!dataToMap.length &&
    dataToMap.forEach((report) => {
      const { reportName = "", reportCategory = "" } = report;

      reportTableData.push({
        name: reportName && reportName === "C Over" ? "Call Over Report" : reportName ,
        category: reportCategory,
        report,
      });
    });
  useEffect(() => {
    let newData = allReports?.filter(
      (eachReport) => eachReport?.reportCategory === repData?.filter
    );
    setDataToMap(newData);
  }, [allReports]);


  const handleOnHide = () => {
    toggleModal(false);
  };

  !!generatingReports &&
    !!generatingReports.length &&
    generatingReports.forEach((report, index) => {
      const {
        reportName,
        type,
        reportCategory,
        generatedOn,
        status,
        expiresIn,
        file,
      } = report;

      let currentDate = Date.now();

      if (status !== "expired" && !!expiresIn && expiresIn > currentDate) {
        generatedReportData.push({
          key: index + 1,
          id: index + 1,
          name: reportName,
          category: reportCategory,
          status: status,
          format: type,
          generatedOn: !!generatedOn ? formatDate(generatedOn) : "",
          expiresIn: !!expiresIn ? (
            <Countdown
              onComplete={() =>
                setExpiredStatus([
                  ...expiredStatus,
                  { id: generatedReportData?.id, status: "expired" },
                ])
              }
              date={expiresIn}
            />
          ) : (
            ""
          ),
          file: file,
        });
      } else if (!expiresIn) {
        generatedReportData.push({
          key: index + 1,
          id: index + 1,
          name: reportName,
          category: reportCategory,
          status: status,
          format: type,
          generatedOn: !!generatedOn ? formatDate(generatedOn) : "",
          expiresIn: !!expiresIn ? (
            <Countdown
              onComplete={() =>
                setExpiredStatus([
                  ...expiredStatus,
                  { id: generatedReportData?.id, status: "expired" },
                ])
              }
              date={expiresIn}
            />
          ) : (
            ""
          ),
          file: file,
        });
      }
    });

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && (
          <ReportDetailsModal
            onHide={handleOnHide}
            currentReport={currentReport}
          />
        )}
      </ModalContainer>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3">
            <h3 className="page-title">{repData?.name}</h3>
            <p className="sub-title">{repData?.overview}</p>
          </div>
        </div>
        <ContentWrapper>
          <Table
            columns={columns}
            dataSource={reportTableData}
            pagination={{ defaultPageSize: 8 }}
            defaultPageSize={8}
            onRow={(r) => ({
              onClick: () => {
                setCurrentReport(r);
                toggleModal(true);
              },
            })}
          />
          <GeneratedReportContainer>
            <p className="heading">Generated reports</p>
            <hr />
            {!!generatedReportData &&
            !!generatedReportData.length &&
            generatedReportData ? (
              generatedReportData
                .filter((eachRep) => eachRep?.category === repData?.filter)
                .map((eachReport) => {
                  return (
                    <div
                      key={eachReport?.id}
                      className="position-relative mt-3"
                    >
                      <div className="report-type">
                        <img
                          src={
                            eachReport?.format === "xlsx"
                              ? require("../../../assets/images/excel.svg")
                                  .default
                              : require("../../../assets/images/csv.svg")
                                  .default
                          }
                          alt=""
                        />
                      </div>
                      <GeneratedReport>
                        <h4>{eachReport?.name}</h4>
                        <div>
                          <div>
                            <span className="time_query">Created: </span>
                            <span className="time_value">
                              {eachReport?.generatedOn}
                            </span>
                          </div>
                          <span className="time_query">Expires in: </span>
                          {eachReport?.expiresIn}
                        </div>
                        {eachReport?.status === "pending" &&
                        !eachReport?.file ? (
                          <p className="pending">Pending</p>
                        ) : (
                          <a
                            target="_blank"
                            href={eachReport?.file}
                            rel="noreferrer"
                          >
                            <IconButton color="#2C1DFF">
                              <img
                                src={
                                  require("../../../assets/images/icons/download.svg")
                                    .default
                                }
                                alt="download"
                                className="icon-img"
                              />
                            </IconButton>
                          </a>
                        )}
                      </GeneratedReport>
                    </div>
                  );
                })
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center m-auto">
                <img
                  src={
                    require("../../../assets/images/icons/empty-report.svg")
                      .default
                  }
                  alt="download"
                  className="icon-img"
                />
                <p className="center-text mt-3">
                  You have no generated reports yet!
                </p>
              </div>
            )}
          </GeneratedReportContainer>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ReportView;

const GeneratedReport = styled.div`
  background: #ffffff;
  grid-gap: 3px;
  border: 0.5px solid #969cba;
  border-radius: 5px;
  margin: 15px 5px;
  display: grid;
  grid-template-columns: 4fr 4fr 1fr;
  padding: 15px;
  align-items: center;
  position: relative;

  span {
    font-size: 13px;
  }
  h4 {
    color: #333333;
  }
  .pending {
    font-size: 12px;
    margin: 0px 3px;
    color: #969cba;
  }
  .time_query {
    font-size: 12px;
    color: #969cba;
  }
  .time_value {
    font-size: 12px;
  }
`;
