import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Checkbox } from "antd";
import {
  Input,
  Textarea,
  Select,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";

import { Select as AntSelect } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import {
  FormClass,
  FormElements,
  ActionButton,
} from "../../CustomerInformation/styles";
import {
  getLoanDisburseDetails,
  getAllLoanGuarantorTemplate,
  createLoanGuarantor,
  handleLoanTransactions,
} from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate.js";
import AxiosData from "../../../../../services/axiosData";
import config from "../../../../../config";
import { textToUpperCase } from "../../../../../util/Tools";
import { fetch } from "../../../../TellerInterface/Transaction/search";
import Toast from "../../../../../util/Toast";
import { getCreateCustomerDetails } from "../../../../../actions/utilityActions";
import { getAllCountries, getAllStates } from "../../../../../actions/customerActions.js";
import { formatAmount } from "../../../../../util/FormatAmount.js";
import { removeCommas } from "../../../../../util/FormatNumber.js";

const LoanGuarantorModal = ({ onHide, id, clientId, currency, history }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  let { loanGuarantorTemplate } = savingsAndLoan || {};

  const { countryData } = useSelector((state) => state.getCountryReducer);
  const { stateData } = useSelector((state) => state.getStateReducer);

  useEffect(() => {
    dispatch(getLoanDisburseDetails(id, "prepayLoan"));
  }, [dispatch, id]);

  const [guarantorType, setGuarantorType] = useState("1");

  //State for internal guarantors
  const [guarantorName, setGuarantorName] = useState("");
  const [guarantorId, setGuarantorId] = useState("");
  const [savingsAccount, setSavingsAccount] = useState("");
  const [savingsId, setSavingsId] = useState("");
  const [accountBalance, setAccountBalance] = useState(null);
  const [payAmount, setPayAmount] = useState("");
  const [intRelationship, setIntRelationship] = useState("");
  const [intComment, setIntComment] = useState("");

  //State for external guarantors
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [dob, setDob] = useState("");
  const [extRelationship, setExtRelationship] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [extComment, setExtComment] = useState("");

  const [savingsOpt, setOpt] = useState([]);
  const [dat, setDat] = useState([]);
  const [data, setData] = useState([]);
  const [fetching, setFetching] = useState(false);
  const { Option } = AntSelect;

  const searchOption =
    data &&
    data?.map((d) => (
      <Option key={d.value}>
        {textToUpperCase(d.clientName)} {d.clientCIFNumber}
      </Option>
    ));

  const savingsAccOption =
    savingsOpt &&
    savingsOpt?.map((d) => (
      <Option key={d.value}>{textToUpperCase(d.name)}</Option>
    ));

  const getSavingsAcc = useCallback((id) => {
    if (!id) return;
    if (id) {
      return new Promise(async (resolve, reject) => {
        AxiosData({
          method: "GET",
          url: `${config.apiGateway.BASE_API_URL}/clients/${id}/accounts?fields=savingsAccounts`,
        })
          .then(({ data = [] }) => {
            if (data?.savingsAccounts) {
              setDat(data);
            } else if (!data?.savingsAccounts) {
              setOpt([]);
            }
            resolve(
              data?.savingsAccount?.map(({ accountNo, productName }) => ({
                value: accountNo,
                id: id,
                name: `${accountNo}(${productName})`,
              }))
            );
          })
          .catch(reject);
      });
    } else {
      return;
    }
  }, []);

  useEffect(() => {
    if (dat && dat?.savingsAccounts && dat?.savingsAccounts?.length) {
      const newdata = dat?.savingsAccounts?.filter(account => account.status.value === "Active")?.map(
        ({ accountNo, productName, accountBalance }) => ({
          value: accountNo,
          id: id,
          name: `${accountNo}${" "}(${productName})`,
          accountBalance,
        })
      );
      setOpt(newdata);
    }
  }, [dat]);

  useEffect(() => {
    dispatch(getAllLoanGuarantorTemplate(id));
    dispatch(
      getCreateCustomerDetails(
        "clients/template?staffInSelectedOfficeOnly=true"
      )
    );
    dispatch(getAllCountries());
  }, []);
  
  useEffect(() => {
    if (country?.name) {
      setState(null);
      dispatch(getAllStates(country?.name));
    }
  }, [country])

  const handleSearch = (value) => {
    if (!value.length) {
      setData([]);
      setFetching(false);
    }
    setFetching(true);
    fetch(value, ({ filteredData }) => {
      setFetching(false);
      setData(filteredData?.filter(
        ({ type, value }) => type === 'CLIENT' && value !== clientId
      ));
    });
  };  

  const handleClientToChange = (timeStamp, options) => {
    getSavingsAcc(timeStamp);
    setGuarantorId(options?.value);
  };

  const handleCustomerAccountToChange = (timeStamp, options) => {
    const savingsAccount =
      dat && dat.savingsAccounts?.find((e) => e.accountNo === options?.value);
    setSavingsId(savingsAccount?.id);
    setAccountBalance(savingsAccount?.accountBalance);
  };

  const handleSubmitLoanGuarantor = async () => {
    let intGuarantorData = {
      guarantorTypeId: guarantorType,
      entityId: guarantorId,
      savingsId: savingsId,
      amount: payAmount,
      locale: "en",
      clientRelationshipTypeId: intRelationship,
      comment: intComment,
    };

    let extGuarantorData = {
      guarantorTypeId: guarantorType,
      firstname: firstName,
      lastname: lastName,
      mobileNumber: mobileNo,
      dob: formatDate(dob, true),
      dateFormat: "dd MMMM yyyy",
      locale: "en",
      clientRelationshipTypeId: extRelationship,
      addressLine1: addressLine1,
      country: Number(country?.id),
      state: Number(state?.id),
      comment: extComment,
    };

    if (guarantorType === "1") {
      if (!guarantorName) {
        dispatch(Toast({ text: "Please select a guarantor", icon: "info" }));
      } else if (!savingsId) {
        dispatch(Toast({ text: "Please select a savings account", icon: "info" }));
      } else {
        dispatch(createLoanGuarantor(intGuarantorData, history, id));
        onHide();
      }
    } else if (guarantorType === "3") {
      dispatch(createLoanGuarantor(extGuarantorData, history, id));
      onHide();
    }
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>Add guarantor</Modal.Title>
      </Modal.Header>
      <Modal.Body className="wc-modal">
        <div className="d-flex align-items-center justify-content-center flex-direction-coloumn">
          <FormClass style={{ width: "90%" }}>
            <FormElements style={{ width: "100%" }}>
              <div className="form-group d-flex justify-content-between align-items-center m-1">
                <div className="w-100">
                  <RadioGroup
                    onChange={(e) => setGuarantorType(e)}
                    value={guarantorType}
                    className="d-flex w-100 justify-content-center align-items-center gap-5"
                  >
                    <Radio colorScheme="blue" value="1">
                      <span className="text-label">Internal guarantor</span>
                    </Radio>
                    <Radio colorScheme="blue" value="3">
                      <span className="text-label">External guarantor</span>
                    </Radio>
                  </RadioGroup>
                </div>
              </div>
              {guarantorType === "1" ? (
                <>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">
                      Guarantor name<span className="required-color">*</span>
                    </label>
                    <AntSelect
                      notFoundContent={
                        fetching ? (
                          <LoadingOutlined size="small" />
                        ) : (
                          "No result"
                        )
                      }
                      allowClear
                      loading={true}
                      showSearch
                      placeholder="Search customers"
                      showArrow={false}
                      filterOption={false}
                      onSearch={handleSearch}
                      onChange={(value) => setGuarantorName(value)}
                      value={guarantorName}
                      onSelect={(selected, options) => {
                        handleClientToChange(selected, options);
                      }}
                      className="w-60"
                    >
                      {searchOption}
                    </AntSelect>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Select account<span className="required-color">*</span></label>
                    <AntSelect
                      optionFilterProp="children"
                      notFoundContent={"Customer has no savings account"}
                      showSearch
                      onSelect={(value, options) => {
                        handleCustomerAccountToChange(value, options);
                      }}
                      value={savingsAccount}
                      onChange={(value) => setSavingsAccount(value)}
                      name="savings acc"
                      placeholder="Select savings account"
                      className="w-60"
                    >
                      {savingsAccOption}
                    </AntSelect>
                  </div>
                  {savingsAccount ? (
                    <div
                      className={`ml-auto mt-1 mb-3 text-${
                        accountBalance >= removeCommas(payAmount)
                          ? 'success' 
                          : accountBalance < removeCommas(payAmount)
                          ? 'danger'
                          : 'secondary'
                      }`}
                      style={{
                        width: "60%",
                        fontSize: '11px',
                      }}
                    >
                      Account Balance:{" "}
                      {(accountBalance != null || accountBalance != undefined)
                        ? currency?.code : <></>
                      }{" "}
                      {(accountBalance != null || accountBalance != undefined)
                        ? formatAmount(Number(accountBalance)) : <></>
                      }
                      {(accountBalance == null || accountBalance == undefined) ? (
                        <span>&nbsp;unavailable</span>
                      ) : accountBalance < removeCommas(payAmount) ? (
                        <span>&nbsp;(insufficient)</span>
                      ) : <></>}
                    </div>
                  ) : <></>}
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Payback Amount<span className="required-color">*</span></label>
                    <CurrencyFormat
                      value={payAmount}
                      thousandSeparator={true}
                      className="amount-input "
                      style={{
                        width: "60%",
                      }}
                      onChange={(e) => setPayAmount(e.target.value)}
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Relationship</label>
                    <Select
                      placeholder="Select relationship type"
                      size="md"
                      name="intRelationship"
                      value={intRelationship}
                      onChange={(e) => setIntRelationship(e.target.value)}
                    >
                      {!!loanGuarantorTemplate &&
                      loanGuarantorTemplate.allowedClientRelationshipTypes ? (
                        loanGuarantorTemplate.allowedClientRelationshipTypes.map(
                          ({ name, id }) => {
                            return (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            );
                          }
                        )
                      ) : (
                        <option>Loading</option>
                      )}
                    </Select>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Comment</label>
                    <Textarea
                      style={{ width: "60%", fontSize: "12px" }}
                      name="note"
                      value={intComment}
                      onChange={(e) => setIntComment(e.target.value)}
                      size="md"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">First name</label>
                    <Input
                      style={{ width: "60%" }}
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      size="md"
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Last name</label>
                    <Input
                      style={{ width: "60%" }}
                      name="lastName"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      size="md"
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Mobile no</label>
                    <Input
                      style={{ width: "60%" }}
                      name="mobileNo"
                      value={mobileNo}
                      onChange={(e) => setMobileNo(e.target.value)}
                      size="md"
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Date of birth</label>
                    <FormControl style={{ width: "60%" }}>
                      <div className="datepicker-100-width">
                        <DatePicker
                          id="published-date"
                          selected={dob}
                          onChange={(date) => setDob(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="dd/mmm/yyyy"
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Relationship</label>
                    <Select
                      placeholder="Select relationship type"
                      size="md"
                      name="extRelationship"
                      value={extRelationship}
                      onChange={(e) => setExtRelationship(e.target.value)}
                    >
                      {!!loanGuarantorTemplate &&
                      loanGuarantorTemplate.allowedClientRelationshipTypes ? (
                        loanGuarantorTemplate.allowedClientRelationshipTypes.map(
                          ({ name, id }) => {
                            return (
                              <option key={id} value={id}>
                                {name}
                              </option>
                            );
                          }
                        )
                      ) : (
                        <option>Loading</option>
                      )}
                    </Select>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Address</label>
                    <Input
                      style={{ width: "60%" }}
                      name="addressLine1"
                      value={addressLine1}
                      onChange={(e) => setAddressLine1(e.target.value)}
                      size="md"
                    />
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Country</label>
                    <AntSelect
                      showSearch
                      placeholder="Select country"
                      optionFilterProp="children"
                      className="chakra-select__wrapper"
                      value={country?.name}
                      onChange={(_, option) => setCountry({ id: option?.key, name: option?.children })}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {countryData?.map(country => (
                        <option value={country?.id} key={country?.id}>
                          {country?.name}
                        </option>
                      ))}
                    </AntSelect>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">State</label>
                    <AntSelect
                      showSearch
                      placeholder="Select state"
                      optionFilterProp="children"
                      className="chakra-select__wrapper"
                      value={state?.name}
                      onChange={(_, option) => setState({ id: option?.key, name: option?.children })}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {stateData?.map(state => (
                        <option value={state?.name} key={state?.id}>
                          {state?.name}
                        </option>
                      ))}
                    </AntSelect>
                  </div>
                  <div className="form-group d-flex justify-content-between align-items-center">
                    <label className="text-label">Comment</label>
                    <Textarea
                      style={{ width: "60%", fontSize: "12px" }}
                      name="extComment"
                      value={extComment}
                      onChange={(e) => setExtComment(e.target.value)}
                      size="md"
                    />
                  </div>
                </>
              )}
            </FormElements>
          </FormClass>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {" "}
        <div className="float-right mt-1">
          <ActionButton color="#2C1DFF" onClick={onHide}>
            <img
              src={
                require("../../../../../assets/images/icons/cancel.svg").default
              }
              alt="cancel icon"
            />
            Cancel
          </ActionButton>
          <ActionButton
            onClick={handleSubmitLoanGuarantor}
            color="#2C1DFF"
            style={{ margin: "0px 0px 0 10px" }}
          >
            <img
              src={
                require("../../../../../assets/images/icons/save.svg").default
              }
              alt="submit icon"
            />
            Submit
          </ActionButton>
        </div>
      </Modal.Footer>
    </>
  );
};

export default LoanGuarantorModal;
