import { Modal } from "react-bootstrap";
import { ActionButton, FormClass } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchRescheduleLoanDetails, previewReschedule } from "../../../../../actions/SavingsAndLoanActions";
import { formatDate } from "../../../../../util/FormatDate";
import CheckPermission from "../../../../../util/CheckPermission";

export const RescheduleRequestModal = ({
	onClose,
	rescheduleData,
	changeCurrentView,
	setResourceId,
	toggleApprovalModal,
	toggleRejectionModal,
	localState,
}) => {
	const dispatch = useDispatch();
	const { loanAccountRescheduleDetails } = useSelector(state => state.savingsAndLoan);
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

	useEffect(() => {
		loanAccountRescheduleDetails && setResourceId(loanAccountRescheduleDetails.id);
	}, [loanAccountRescheduleDetails]);

	const getNewSchedule = () => {
		dispatch(previewReschedule(loanAccountRescheduleDetails.id));
		changeCurrentView('view_schedule');
	};

	const rescheduleRequestDetails = [
		{
			label: 'Select from installment',
			value: formatDate(loanAccountRescheduleDetails?.rescheduleFromDate, true),
		},
		{
			label: 'Reason for rescheduling',
			value: loanAccountRescheduleDetails?.rescheduleReasonCodeValue?.name,
		},
		{
			label: 'Installment rescheduled to',
			value: formatDate(localState?.adjustedDueDate, true),
		},
		{
			label: 'Introduce midterm grace period',
			value: localState?.introduceGracePeriod ? 'Yes' : 'No',
		},
		{
			label: 'Principal grace period',
			value: localState.graceOnPrincipal,
		},
		{
			label: 'Interest grace period',
			value: localState.graceOnInterest,
		},
		{
			label: 'Extend repayment period',
			value: localState?.extendRepaymentPeriod ? 'Yes' : 'No',
		},
		{
			label: 'Current number of installment',
			value: '',
		},
		{
			label: 'Increase repayment by',
			value: localState?.extraTerms,
		},
		{
			label: 'Adjust interest rates for remainder of loan',
			value: localState?.adjustInterestRate ? 'Yes' : 'No',
		},
		{
			label: 'New interest rate',
			value: localState.newInterestRate ? `${localState.newInterestRate}%` : null,
		},
		{
			label: 'Submitted on',
			value: formatDate(loanAccountRescheduleDetails?.timeline?.submittedOnDate, true),
		},
		{
			label: 'Description',
			value: loanAccountRescheduleDetails?.rescheduleReasonComment,
		},
	];

	return (
		<>
			<Modal.Header>
				<Modal.Title>Loan Reschedule Request</Modal.Title>
				<div onClick={onClose} style={{ color: 'black', cursor: 'pointer', fontWeight: 'bold' }}>
					&#x2715;
				</div>
			</Modal.Header>
			<Modal.Body>
				<div
					className="fs-13 w-100 p-3 rounded d-flex flex-column gap-4"
					style={{ backgroundColor: '#F3F6FF' }}
				>
					{rescheduleRequestDetails?.map((details, index) => details.value && (
						<div key={index} className="d-flex justify-content-between align-items-center w-100">
							<div className="font-bolder">{details.label}</div>
							<div className="">{details.value}</div>
						</div>
					))}
				</div>
			</Modal.Body>
			<div className="d-flex gap-2 justify-content-center mt-2 mb-4">
				{CheckPermission('REJECT_RESCHEDULELOAN', permissions) && (
					<ActionButton color="#FF0000" onClick={toggleRejectionModal}>
						<img
							src={
								require("../../../../../assets/images/icons/cancel.svg").default
							}
							alt="cancel icon"
						/>
						Reject
					</ActionButton>
				)}
				{CheckPermission('APPROVE_RESCHEDULELOAN', permissions) && (
					<ActionButton
						onClick={toggleApprovalModal}
						color="#008F28"
					>
						<img
							src={
								require("../../../../../assets/images/icons/save.svg").default
							}
							alt="submit icon"
						/>
						Approve
					</ActionButton>
				)}
				<ActionButton
					onClick={getNewSchedule}
					color="#2C1DFF"
				>
					View new repayment schedule
				</ActionButton>
			</div>
		</>
	);
};
