import styled from "styled-components";

export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  // flex-wrap: wrap;
  // flex: 2 1 1 1;
  width: 100%;
`;

export const ActionButton = styled.div`
  width: max-content !important;
  height: 35px;
  background: #2c1dff !important;
  margin: 0 2px;
  font-family: "AventaMedium";
  border: none;
  padding: 0 15px;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff !important;
  a {
    width: max-content !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const SecondButton = styled.div`
  width: max-content !important;
  height: 35px;
  background: #ffffff !important;
  margin: 0 2px;
  border: none;
  padding: 0 15px;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #2c1dff !important;
  a {
    width: max-content !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ActionAnchorButton = styled.div`
  width: max-content !important;
  height: 35px;
  margin: 0 2px;
  border: none;
  padding: 0 15px;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #2c1dff !important;
  text-decoration: underline;
  a {
    width: max-content !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
`;

export const NewActionButton = styled.div`
  width: max-content !important;
  height: 35px;
  background: #fff !important;
  margin: 0 2px;
  border: 1px solid #2c1dff;
  padding: 0 15px;
  font-family: "AventaMedium";
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #2c1dff !important;
  a {
    width: max-content !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  margin-top: 20px;
  table {
    font-size: 14px;
  }
`;

export const IconButton = styled.button`
  background: ${(props) => props.color} !important;
  width: 40px;
  height: 40px;
  background: #2c1dff;
  text-align: center;
  border-radius: 5px;
  margin-right: 10px;
  img {
    // height: 16px;
    // margin-right: 10px;
    display: inline;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ModalActionButton = styled.button`
  background: ${(props) => props.color} !important;
  border-radius: 5px !important;
  color: #fff !important;
  margin: 0 3px;
  border: none;
  display: inline;
  font-size: 14px;
  font-family: "AventaMedium";
  height: 35px;
  transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
  position: relative;
  padding: 8px;
  cursor: pointer;
  img {
    height: 14px;
    margin-right: 10px;
    display: inline;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;