import React, { useState, useEffect } from 'react';
import { Table, Modal, Button, DatePicker, Input, Tooltip, Popover } from 'antd';
import { Flex, FormControl } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import ReactDatePicker from 'react-datepicker';
import { OverlayTrigger } from 'react-bootstrap';
import { formatCurrency } from '../../../../../util/FormatAmount';
import ModalContainer from '../../../components/ModalContainer';
import TransactionDetailsModal from './TransactionDetailsModal';
import config from '../../../../../config';
import AuthModule from '../../../../../modules/Auth.module';

import Toast from '../../../../../util/Toast';
import { formatDate } from '../../../../../util/FormatDate';

import { ActionButton, IconButton } from '../styles';
import { ActionAnchorButton, NewActionButton } from "../../styles.jsx";

import { getLoanAccountTransactions } from '../../../../../actions/SavingsAndLoanActions';
import {
  downloadTransactionReceipt,
  resetDownloadTransactionReceiptState,
} from '../../../../../actions/customerActions';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ReactComponent as ThreeDots } from "../../../../../assets/images/icons/three-dots.svg";
import LedgerDetailsModal from '../../CustomerFixedDepositAccount/Transactions/TransactionDetailsModal/ledgermodal';
import { ReactComponent as SearchIcon } from "../../../../../assets/images/icons/search.svg";

const LoanTransactions = ({ transactions = [], id, accountNo }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );


  let { loanAccountTransactions = {} } = savingsAndLoan || {};
  let { pageItems = [], totalFilteredRecords } = loanAccountTransactions || {};

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
  });

  const [currentPagination, setCurrentPagination] = useState(1);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [myData, setMyData] = useState([]);
  const [amount, setAmount] = useState('');
  const [order, setOrder] = useState('DESC');
  const [transId, setTransId] = useState('');

  const toggleOrder = () => {
    setOrder(order => order === 'DESC' ? 'ASC' : 'DESC');
  };
  

  const handleSearchValueChange = (event) => {
    setTransId(event.target.value);
  };

  const handleTransactionsSearch = (event) => {
    event.preventDefault();
    dispatch(getLoanAccountTransactions(id, `?offset=0&limit=5&transactionId=${transId}&orderBy=transactionDate ${order}`));
  };

  useEffect(() => {
    pageItems && setMyData(pageItems);
  }, [pageItems]);

  const handleFilterDate = (_, dateString) => {
    const startDate = dateString[0];
    const endDate = dateString[1];
    setStartDate(formatDate(startDate, true));
    setEndDate(formatDate(endDate, true));
  };

  const applyDateFilter = (event) => {
    event.preventDefault();
    dispatch(
      getLoanAccountTransactions(id, `?offset=0&limit=5&fromDate=${startDate}&toDate=${endDate}&orderBy=transactionDate ${order}`)
    );
  };

  useEffect(() => {
    if (totalFilteredRecords) {
      setPagination({
        current: currentPagination,
        pageSize: 5,
        total: totalFilteredRecords,
      });
    }
  }, [totalFilteredRecords, currentPagination]);

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPagination(pagination.current);
    setTransId('');
    let dataToSend = `?offset=${
      (pagination.current - 1) * 5
    }&limit=5&orderBy=transactionDate ${order}`;
    dispatch(getLoanAccountTransactions(id, dataToSend));
  };

  useEffect(() => {
    dispatch(getLoanAccountTransactions(id, `?offset=0&limit=5&orderBy=transactionDate ${order}`));
  }, [id, dispatch, order]);

  const [modalState, toggleModal] = useState(false);

  const handleRefresh = () => {
    dispatch(getLoanAccountTransactions(id, `?offset=0&limit=5&orderBy=transactionDate ${order}`));
    setCurrentPagination(1);
    setPagination({
      current: 1,
      pageSize: 5,
    });
  };
  const handleOnHide = () => {
    toggleModal(false);
  };

  const [transactionDetails, setTransactionDetails] = useState({});
  const [modalContentName, setModalContentName] = useState("");

  const { RangePicker } = DatePicker;

  const [currentView, setCurrentView] = useState(1);
  const [currentTransId, setCurrentTransId] = useState();

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Office', dataIndex: 'office', key: 'office' },
    {
      title: (
        <Tooltip title={`${order === 'ASC' ? 'Oldest to Newest' : 'Newest to Oldest'} (tap to change)`}>
          <span onClick={toggleOrder} className='pointer'>
            Transaction Date
            <span
              className="font-bold px-1 rounded-circle border border-secondary ml-2 fs-13"
              style={{ backgroundColor: '#e3e3e3' }}
            >
              {order === 'ASC' ? <>&#8593;</> : <>&#8595;</>}
            </span>
          </span>
        </Tooltip>
      ),
      dataIndex: 'transdate',
      key: 'transdate',
    },
    { title: 'Transaction Type', dataIndex: 'transType', key: 'transType' },
    { title: 'Amount', dataIndex: 'amount', key: 'amount' },
    {
      title: 'Principal',
      dataIndex: 'principal',
      key: 'principal',
    },
    { title: 'Interest', dataIndex: 'interest', key: 'interest' },
    { title: 'Fees', dataIndex: 'fees', key: 'fees' },
    { title: 'Penalties', dataIndex: 'penalties', key: 'penalties' },
    { title: 'Loan Balance', dataIndex: 'loanBalance', key: 'loanBalance' },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  let transactionData = [];

  const RenderTooltip = ({ text, id, children }) => (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
      >
        {children}
      </OverlayTrigger>
    </>
  );

  // ##############################
  const transactionReceipt = useSelector((state) => state.transactionReceipt);

  const openPdfNewTab = (file) => {
    setTimeout(() => {
      window.open(file);
    }, 3000);
  };

  useEffect(() => {
    const file = transactionReceipt?.transactionReceipt?.message?.downloadUrl;
    if (
      transactionReceipt?.transactionReceipt?.status === '01' &&
      !!transactionReceipt?.transactionReceipt?.message
    ) {
      return [
        openPdfNewTab(file),
        dispatch(resetDownloadTransactionReceiptState()),
      ];
    }
  }, [transactionReceipt, dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    let transactionReceiptData = {
      transactionId: currentTransId,
    };
    const funcTorun = () => handleOnHide();

    if (transactionReceiptData && transactionReceiptData?.transactionId) {
      dispatch(downloadTransactionReceipt(transactionReceiptData, funcTorun));
    }
  };

  myData?.forEach((transact, index) => {
    const {
      date = [],
      amount = 0,
      currency = {},
      feeChargesPortion,
      principalPortion,
      penaltyChargesPortion,
      id,
      interestPortion,
      officeName,
      outstandingLoanBalance,
      // submittedOnDate = [],
      type = {},
    } = transact;

    transactionData?.push({
      key: index,
      id: id,
      office: officeName,
      transdate: formatDate(date),
      transType: (
        <>
          <div>
            {type.value.toLowerCase() === "disbursement" ? (
              <span className="new-text-info"> {type.value || ""}</span>
            ) : (
              ""
            )}
            {type.value.toLowerCase() === "accrual" ? (
              <>
                {" "}
                <span className="new-text-pending">
                  {" "}
                  {type.value || ""}
                </span>{" "}
              </>
            ) : (
              ""
            )}
            {type.value.toLowerCase() === "repayment" ? (
              <>
                {" "}
                <span className="new-text-success">
                  {" "}
                  {type.value || ""}
                </span>{" "}
              </>
            ) : (
              ""
            )}
          </div>
        </>
      ),
      amount: `${currency.code} ${formatCurrency(amount)}`,
      principal: `${currency.code} ${formatCurrency(principalPortion)}`,
      interest: `${currency.code} ${formatCurrency(interestPortion)}`,
      fees: `${currency.code} ${formatCurrency(feeChargesPortion)}`,
      penalties: `${currency.code} ${formatCurrency(penaltyChargesPortion)}`,
      loanBalance: `${currency.code} ${formatCurrency(outstandingLoanBalance)}`,
      actions: (
        <>
          <Menu direction="rtl">
            {({ isOpen }) => (
              <>
                <MenuButton transition="all 0.2s">
                  <ThreeDots />
                </MenuButton>
                <MenuList className="menu-list-teller">
                  <MenuItem
                    onClick={() => handleViewTransactions(transact)}
                    className="text-label"
                  >
                    View transaction details
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleViewLedgerTransactions(transact)}
                    className="text-label"
                  >
                    View ledger details
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu></>
      ),
      transact,
    });
  });

  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [showGeneratedReport, setShowGeneratedReport] = useState(false);

  const generateReport = () => {
    if (!fromDate || !toDate) {
      dispatch(Toast({ text: 'Please select date', icon: 'info' }));
    } else {
      setShowGeneratedReport(true);
    }
  };
  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "viewTransaction":
        return <TransactionDetailsModal transactionDetails={transactionDetails} onHide={handleModalClose} />;
      case "ledgerTransaction":
        return (
          <LedgerDetailsModal
            transactions={transactionDetails}
            onHide={handleModalClose}
            module={'loan'}
          />
        );
      default:
        return;
    }
  };

  const handleViewTransactions = (transaction) => {
    setTransactionDetails(transaction);
    setCurrentTransId(transaction.id);
    setModalContentName("viewTransaction");
    toggleModal(true);
  };
  const handleViewLedgerTransactions = (transaction) => {
    setTransactionDetails(transaction);
    setCurrentTransId(transaction.id);
    setModalContentName("ledgerTransaction");
    toggleModal(true);
  };

  return (
    <>
      <ModalContainer show={modalState}>
        {modalState && displayModalContent()}
      </ModalContainer>
      <div className="w-100 d-flex justify-content-between align-items-center mb-2">
        <form onSubmit={handleTransactionsSearch} className="d-flex align-items-center">
          <Input
            className="rounded"
            placeholder="Search transactions by ID"
            style={{ width: '200px' }}
            value={transId}
            onChange={handleSearchValueChange}
            required
          />
          <button type="submit" className="p-2 rounded">
            <SearchIcon />
          </button>
        </form>
        <div className="d-flex align-items-center gap-1">
          <Popover
            title="Filter by date"
            trigger="click"
            className="rounded-3"
            content={(
              <form onSubmit={applyDateFilter} className="d-flex flex-column gap-4 w-100">
                <RangePicker
                  className='w-100'
                  onChange={handleFilterDate}
                  dateFormat="YYYY/MM/DD"
                />
                <div className="ml-auto">
                  <ActionButton color="#2C1DFF">Apply filter</ActionButton>
                </div>
              </form>
            )}
          >
            <NewActionButton>Filter</NewActionButton>
          </Popover>
          <div
            onClick={(e) => {
              e.preventDefault();
              handleRefresh();
              setCurrentView(1);
              setTransId("")
            }}
          >
            <NewActionButton>Refresh Transactions</NewActionButton>
          </div>
        </div>
      </div>

      {currentView === 1 ? (
        <div className='overflow-auto'>
          <Table
            style={{
              overflow: 'hidden',
              overflowX: 'scroll',
            }}
            columns={columns}
            dataSource={transactionData}
            pagination={pagination}
            defaultPageSize={5}
            loading={isTableLoading}
            onChange={handleTableChange}
          />{' '}
        </div>
      ) : (
        <></>
      )}

      {currentView === 2 ? (
        <>
          <p className="BackPointer" onClick={(e) => setCurrentView(1)}>
            Go Back
          </p>
          <object
            data={`${
              config.apiGateway.BASE_API_URL
            }/runreports/Loan%20Transaction%20Receipt?output-type=PDF&tenantIdentifier=${
              config.apiGateway.IS_PRODUCTION === `true`
                ? AuthModule.getTenant()
                : config.apiGateway.WOODCORE_TENANT
            }&locale=en&R_transactionId=${currentTransId}`}
            type="application/pdf"
            width="100%"
            height="900"
          >
            <p>
              You don't have a PDF plugin, but you can
              <a
                href={`${
                  config.apiGateway.BASE_API_URL
                }/runreports/Loan%20Transaction%20Receipt?output-type=PDF&tenantIdentifier=${
                  config.apiGateway.IS_PRODUCTION === `true`
                    ? AuthModule.getTenant()
                    : config.apiGateway.WOODCORE_TENANT
                }&locale=en&R_transactionId=${currentTransId}`}
              >
                download the PDF file.
              </a>
            </p>
          </object>
        </>
      ) : (
        ''
      )}
      {currentView === 3 ? (
        <div className="my-10">
          <p className="BackPointer" onClick={(e) => setCurrentView(1)}>
            Go Back
          </p>
          <div className="row mt-5">
            <div className="col-sm-12 col-md-12 col-xl-6">
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">From Date*</label>
                <FormControl style={{ width: "70%" }}>
                  <ReactDatePicker
                    id="fromDate"
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </FormControl>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-xl-6">
              <div className="form-group d-flex justify-content-between align-items-center">
                <label className="text-label">To Date*</label>
                <FormControl style={{ width: "70%" }}>
                  <ReactDatePicker
                    id="toDate"
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="dd/mmm/yyyy"
                  />
                </FormControl>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="spaceBetweenFlexEnd w-100">
              <div className="my-4 ml-4" onClick={() => setCurrentView(1)}>
                <div className="btn-small white">Back</div>
              </div>
              <div
                className="my-4 mr-4"
                onClick={(e) => {
                  e.preventDefault();
                  generateReport();
                }}
              >
                <div className="btn-small ml-4">Generate Report</div>
              </div>
            </div>
            {showGeneratedReport ? (
              <>
                <object
                  data={`${
                    config.apiGateway.BASE_API_URL
                  }/runreports/Client%Loan%20Account%20Schedule?output-type=PDF&tenantIdentifier=${
                    config.apiGateway.IS_PRODUCTION === `true`
                      ? AuthModule.getTenant()
                      : config.apiGateway.WOODCORE_TENANT
                  }&locale=en&&R_startDate=${formatDate(
                    fromDate,
                    false,
                    'string:yyyy-MMMM-dd'
                  )}&R_endDate=${formatDate(
                    toDate,
                    false,
                    'string:yyyy-MMMM-dd'
                  )}&R_selectLoan=${accountNo}`}
                  type="application/pdf"
                  width="100%"
                  height="900"
                >
                  <p>
                    You don't have a PDF plugin, but you can
                    <a
                      href={`${
                        config.apiGateway.BASE_API_URL
                      }/runreports/Client%Loan%20Account%20Schedule?output-type=PDF&tenantIdentifier=${
                        config.apiGateway.IS_PRODUCTION === `true`
                          ? AuthModule.getTenant()
                          : config.apiGateway.WOODCORE_TENANT
                      }&locale=en&&R_startDate=${formatDate(
                        fromDate,
                        false,
                        'string:yyyy-MMMM-dd'
                      )}&R_endDate=${formatDate(
                        toDate,
                        false,
                        'string:yyyy-MMMM-dd'
                      )}&R_selectLoan=${accountNo}`}
                    >
                      download the PDF file.
                    </a>
                  </p>
                </object>{' '}
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default LoanTransactions;
