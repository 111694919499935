import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, FormControl, Checkbox } from "@chakra-ui/react";
import {
  ContentWrapper,
  LineThrough,
  ActionButton,
  FormClass,
  FormElements,
} from "../styles";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import InterestCharts from "../components/fixedDeposit/interestCharts";
import InterestChartsTemp from "../components/fixedDeposit/interestChartsTemp";

import Charges from "../components/fixedDeposit/charges";

import {
  getFixedDepositAccountTemplates,
  createFixedDepositAccountTemplate,
  createFixedDepositAccounts,
  fixedDepositAccountTemplateReset,
} from "../../../../../actions/fixedDeposit";
import { formatDate } from "../../../../../util/FormatDate";
import Toast from "../../../../../util/Toast";

const FixedDeposit = ({ match, history }) => {
  const dispatch = useDispatch();
  const fixedDepositData = useSelector((state) => state.fixedDepositData);
  //Wip
  const fixedDepositAccountTemplate = useSelector(
    (state) => state.fixedDepositAccountTemplate
  );

  let { fixedDepositAccountCreationTemplate = {} } = fixedDepositData || {};
  //Wip
  let { fixedDepositAccountCreateTemplate = {} } =
    fixedDepositAccountTemplate || {};

  let {
    clientName = "",
    clientId = "",
    productOptions = [],
    fieldOfficerOptions = [],
    interestCompoundingPeriodTypeOptions = [],
    interestPostingPeriodTypeOptions = [],
    interestCalculationTypeOptions = [],
    interestCalculationDaysInYearTypeOptions = [],
    periodFrequencyTypeOptions = [],
    lockinPeriodFrequencyTypeOptions = [],
    preClosurePenalInterestOnTypeOptions = [],
    savingsAccounts = [],
    currency = {},
    taxGroup = {},
  } = fixedDepositAccountCreateTemplate || {};

  // useEffect(() => {
  //   dispatch(createFixedDepositAccountTemplate(match.params.id, ''));
  // }, [dispatch, match.params.id]);

  useEffect(() => {
    dispatch(getFixedDepositAccountTemplates(match.params.id, ""));
  }, [dispatch, match.params.id]);

  const [productId, setproductId] = useState("");
  const [withHoldTax, setWithHoldTax] = useState(false);
  const [transferInterestToSavings, setTransferInterestToSavings] =
    useState(false);
  const [preClosurePenalApplicable, setPreClosurePenalApplicable] =
    useState(false);
  const [submittedOnDate, setSubmittedOnDate] = useState(new Date());
  const [validFrom, handleValidFrom] = useState(new Date());
  const [validTo, handleValidTo] = useState(new Date());
  const [depositAmount, setDepositAmount] = useState("");

  const [lockinPeriodFrequency, setLockinPeriodFrequency] = useState("");
  const [lockinPeriodFreqType, stLockinPeriodFreqType] = useState("");
  const [lockinPeriodFrequencyType, stLockinPeriodFrequencyType] = useState("");

  const [minimumDepositTerm, setMinimumDepositTerm] = useState("");
  const [minimumDepTermValue, stMinimumDepTermValue] = useState("");
  // const [minDepositTermType, stMinDepositTermType] = useState("");
  const [minimunDepositTermType, SetMinimunDepositTermType] = useState("");

  const [maximumDepositTerm, setMaximumDepositTerm] = useState("");
  const [maximumDepTermValue, stMaximumDepTermValue] = useState("");
  const [maxDepositTermType, stMaxDepositTermType] = useState("");
  const [maturityInstruction, setMaturityInstruction] = useState("");
  const [transferToSavingsId, setTransferToSavingsId] = useState("");

  let defaultData = {
    amountRangeFrom: "",
    amountRangeTo: "",
    annualInterestRate: "",
    description: "",
    fromPeriod: "",
    incentives: [],
    locale: "en",
    periodType: "",
    toPeriod: "",
  };
  const [interests, setInterests] = useState([]);
  const [charges, setCharges] = useState([]);

  const [createFixedDepositAccount, setCreateFixedDepositAccount] = useState({
    clientId: "",
    depositPeriod: "",
    depositPeriodFrequencyId: "",
    fieldOfficerId: "",
    // inMultiplesOfDepositTerm: "",
    // inMultiplesOfDepositTermTypeId: "",
    interestCalculationDaysInYearType: "",
    interestCalculationType: "",
    interestCompoundingPeriodType: "",
    interestPostingPeriodType: "",
    linkAccountId: "",
    // transferToSavingsId: "",
    // lockinPeriodFrequency: '',
    // lockinPeriodFrequencyType: '',
    // lockinPeriodFrequencyTypeId: '',
    // maxDepositTerm: '',
    // maxDepositTermTypeId: '',
    // minDepositTerm: '',
    // minDepositTermTypeId: '',
    nominalAnnualInterestRate: "",
    preClosurePenalInterest: "",
    preClosurePenalInterestOnTypeId: "",
    taxGroupId: "",
  });

  const createFixedDepositAccountHandler = (e) => {
    setCreateFixedDepositAccount({
      ...createFixedDepositAccount,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (fixedDepositAccountCreateTemplate) {
      setWithHoldTax(
        !!fixedDepositAccountCreateTemplate.withHoldTax
          ? fixedDepositAccountCreateTemplate.withHoldTax
          : false
      );
      handleValidFrom(
        fixedDepositAccountCreateTemplate &&
          fixedDepositAccountCreateTemplate.accountChart &&
          fixedDepositAccountCreateTemplate.accountChart.fromDate
          ? new Date(fixedDepositAccountCreateTemplate.accountChart.fromDate)
          : ""
      );
      handleValidTo(
        fixedDepositAccountCreateTemplate &&
          fixedDepositAccountCreateTemplate.accountChart &&
          fixedDepositAccountCreateTemplate.accountChart.endDate
          ? new Date(fixedDepositAccountCreateTemplate.accountChart.endDate)
          : ""
      );
      setPreClosurePenalApplicable(
        !!fixedDepositAccountCreateTemplate.preClosurePenalApplicable
          ? fixedDepositAccountCreateTemplate.preClosurePenalApplicable
          : false
      );
      setCharges(
        !!fixedDepositAccountCreateTemplate.charges
          ? fixedDepositAccountCreateTemplate.charges
          : []
      );

      setCreateFixedDepositAccount({
        clientId: match.params.id,
        depositPeriod: "",
        depositPeriodFrequencyId: "",
        interestCompoundingPeriodType:
          !!fixedDepositAccountCreateTemplate.interestCompoundingPeriodType &&
          !!fixedDepositAccountCreateTemplate.interestCompoundingPeriodType.id
            ? fixedDepositAccountCreateTemplate.interestCompoundingPeriodType.id
            : "",
        interestPostingPeriodType:
          !!fixedDepositAccountCreateTemplate.interestPostingPeriodType &&
          !!fixedDepositAccountCreateTemplate.interestPostingPeriodType.id
            ? fixedDepositAccountCreateTemplate.interestPostingPeriodType.id
            : "",
        interestCalculationType:
          !!fixedDepositAccountCreateTemplate.interestCalculationType &&
          !!fixedDepositAccountCreateTemplate.interestCalculationType.id
            ? fixedDepositAccountCreateTemplate.interestCalculationType.id
            : "",
        interestCalculationDaysInYearType:
          !!fixedDepositAccountCreateTemplate.interestCalculationDaysInYearType &&
          !!fixedDepositAccountCreateTemplate.interestCalculationDaysInYearType
            .id
            ? fixedDepositAccountCreateTemplate
                .interestCalculationDaysInYearType.id
            : "",
        // lockinPeriodFrequency:
        //   !!fixedDepositAccountCreateTemplate.lockinPeriodFrequency
        //     ? fixedDepositAccountCreateTemplate.lockinPeriodFrequency
        //     : '',
        // lockinPeriodFrequencyType:
        //   !!fixedDepositAccountCreateTemplate.lockinPeriodFrequencyType &&
        //   !!fixedDepositAccountCreateTemplate.lockinPeriodFrequencyType.id
        //     ? fixedDepositAccountCreateTemplate.lockinPeriodFrequencyType.id
        //     : '',

        //     lockinPeriodFrequencyTypeId:
        //     !!fixedDepositAccountCreateTemplate.lockinPeriodFrequencyType &&
        //     !!fixedDepositAccountCreateTemplate.lockinPeriodFrequencyType.id
        //       ? fixedDepositAccountCreateTemplate.lockinPeriodFrequencyType.id
        //       : '',

        // minDepositTerm: !!fixedDepositAccountCreateTemplate.minDepositTerm
        //   ? fixedDepositAccountCreateTemplate.minDepositTerm
        //   : '',
        // minDepositTermTypeId:
        //   !!fixedDepositAccountCreateTemplate.minDepositTermType &&
        //   !!fixedDepositAccountCreateTemplate.minDepositTermType.id
        //     ? fixedDepositAccountCreateTemplate.minDepositTermType.id
        //     : '',

        // maxDepositTerm: !!fixedDepositAccountCreateTemplate.maxDepositTerm
        //   ? fixedDepositAccountCreateTemplate.maxDepositTerm
        //   : '',
        // maxDepositTermTypeId:
        //   !!fixedDepositAccountCreateTemplate.maxDepositTermType &&
        //   !!fixedDepositAccountCreateTemplate.maxDepositTermType.id
        //     ? fixedDepositAccountCreateTemplate.maxDepositTermType.id
        //     : '',
        // inMultiplesOfDepositTerm:
        //   !!fixedDepositAccountCreationTemplate.inMultiplesOfDepositTerm
        //     ? fixedDepositAccountCreationTemplate.inMultiplesOfDepositTerm
        //     : "",
        // inMultiplesOfDepositTermTypeId:
        //   !!fixedDepositAccountCreationTemplate.inMultiplesOfDepositTermType &&
        //   !!fixedDepositAccountCreationTemplate.inMultiplesOfDepositTermType.id
        //     ? fixedDepositAccountCreationTemplate.inMultiplesOfDepositTermType
        //         .id
        //     : "",
        preClosurePenalInterest:
          !!fixedDepositAccountCreateTemplate.preClosurePenalInterest
            ? fixedDepositAccountCreateTemplate.preClosurePenalInterest
            : "",
        preClosurePenalInterestOnTypeId:
          !!fixedDepositAccountCreateTemplate.preClosurePenalInterestOnType &&
          !!fixedDepositAccountCreateTemplate.preClosurePenalInterestOnType.id
            ? fixedDepositAccountCreateTemplate.preClosurePenalInterestOnType.id
            : "",
      });
    }
  }, [fixedDepositAccountCreateTemplate]);

  useEffect(() => {
    if (fixedDepositAccountCreateTemplate) {
      setLockinPeriodFrequency(
        !!fixedDepositAccountCreateTemplate.lockinPeriodFrequency
          ? fixedDepositAccountCreateTemplate.lockinPeriodFrequency
          : ""
      );
      stLockinPeriodFreqType(
        !!fixedDepositAccountCreateTemplate?.lockinPeriodFrequencyType?.value
          ? fixedDepositAccountCreateTemplate?.lockinPeriodFrequencyType?.value
          : ""
      );
      stLockinPeriodFrequencyType(
        fixedDepositAccountCreateTemplate.lockinPeriodFrequencyType?.id
      );
      setMinimumDepositTerm(
        !!fixedDepositAccountCreateTemplate.minDepositTerm
          ? fixedDepositAccountCreateTemplate.minDepositTerm
          : ""
      );
      stMinimumDepTermValue(
        !!fixedDepositAccountCreateTemplate?.minDepositTermType?.value
          ? fixedDepositAccountCreateTemplate?.minDepositTermType?.value
          : ""
      );
      SetMinimunDepositTermType(
        fixedDepositAccountCreateTemplate.minDepositTermType?.id
      );
      setMaximumDepositTerm(
        !!fixedDepositAccountCreateTemplate.maxDepositTerm
          ? fixedDepositAccountCreateTemplate.maxDepositTerm
          : ""
      );
      stMaximumDepTermValue(
        !!fixedDepositAccountCreateTemplate?.maxDepositTermType?.value
          ? fixedDepositAccountCreateTemplate?.maxDepositTermType?.value
          : ""
      );
      stMaxDepositTermType(
        fixedDepositAccountCreateTemplate.maxDepositTermType?.id
      );
    }
  }, [
    productId,
    fixedDepositAccountCreateTemplate,
    fixedDepositAccountCreateTemplate.lockinPeriodFrequency,
    fixedDepositAccountCreateTemplate.lockinPeriodFrequencyType,
    fixedDepositAccountCreateTemplate.minDepositTerm,
    fixedDepositAccountCreateTemplate.minDepositTermType,
    fixedDepositAccountCreateTemplate.maxDepositTerm,
    fixedDepositAccountCreateTemplate.maxDepositTermType,
  ]);

  useEffect(() => {
    if (productId) {
      dispatch(fixedDepositAccountTemplateReset);
      setLockinPeriodFrequency(
        !!fixedDepositAccountCreateTemplate.lockinPeriodFrequency
          ? fixedDepositAccountCreateTemplate.lockinPeriodFrequency
          : ""
      );
      stLockinPeriodFreqType(
        !!fixedDepositAccountCreateTemplate?.lockinPeriodFrequencyType?.value
          ? fixedDepositAccountCreateTemplate?.lockinPeriodFrequencyType?.value
          : ""
      );
      stLockinPeriodFrequencyType(
        fixedDepositAccountCreateTemplate.lockinPeriodFrequencyType?.id
      );
      setMinimumDepositTerm(
        !!fixedDepositAccountCreateTemplate.minDepositTerm
          ? fixedDepositAccountCreateTemplate.minDepositTerm
          : ""
      );
      stMinimumDepTermValue(
        !!fixedDepositAccountCreateTemplate?.minDepositTermType?.value
          ? fixedDepositAccountCreateTemplate?.minDepositTermType?.value
          : ""
      );
      SetMinimunDepositTermType(
        fixedDepositAccountCreateTemplate.minDepositTermType?.id
      );
      setMaximumDepositTerm(
        !!fixedDepositAccountCreateTemplate.maxDepositTerm
          ? fixedDepositAccountCreateTemplate.maxDepositTerm
          : ""
      );
      stMaximumDepTermValue(
        !!fixedDepositAccountCreateTemplate?.maxDepositTermType?.value
          ? fixedDepositAccountCreateTemplate?.maxDepositTermType?.value
          : ""
      );
      stMaxDepositTermType(
        fixedDepositAccountCreateTemplate.maxDepositTermType?.id
      );
    }
  }, [dispatch, productId]);

  //HANDLE LOCKED IN FREQUENCY
  const handleLockedInFreq = (e) => setLockinPeriodFrequency(e.target.value);

  //HANDLE LOCKED IN FREQUENCY TYPE
  const handleLockinFreqType = (e) => {
    stLockinPeriodFreqType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        stLockinPeriodFrequencyType(x.id);
      }
    });
  };

  //HANDLE MIN DEPOSIT TERM
  const handleMinDepositTerm = (e) => setMinimumDepositTerm(e.target.value);

  //HANDLE MIN DEPOSIT TERM TYPE
  const handleMinDepositType = (e) => {
    stMinimumDepTermValue(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        SetMinimunDepositTermType(x.id);
      }
    });
  };

  //HANDLE MAX DEPOSIT TERM
  const handleMaxDepositTerm = (e) => setMaximumDepositTerm(e.target.value);

  const handleMaturityChange = (e) => setMaturityInstruction(e.target.value);

  const handleTransferTo = (e) => setTransferToSavingsId(e.target.value);

  //HANDLE MAX DEPOSIT TERM TYPE
  const handleMaxDepositType = (e) => {
    stMaximumDepTermValue(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        stMaxDepositTermType(x.id);
      }
    });
  };

  useEffect(() => {
    if (productId) {
      dispatch(
        getFixedDepositAccountTemplates(
          match.params.id,
          `&productId=${productId}`
        )
      );
    }
  }, [productId]);

  let wales = [];

  !!fixedDepositAccountCreateTemplate &&
    !!fixedDepositAccountCreateTemplate.accountChart &&
    !!fixedDepositAccountCreateTemplate.accountChart.chartSlabs &&
    fixedDepositAccountCreateTemplate.accountChart.chartSlabs.length &&
    fixedDepositAccountCreateTemplate.accountChart.chartSlabs.forEach(
      (interest) => {
        let {
          incentives: myIncentives = [],
          periodType: myPeriodType,
          fromPeriod,
          toPeriod,
          description,
          annualInterestRate,
          amountRangeFrom,
          amountRangeTo,
        } = interest || {};

        let newIncentives = [];

        myIncentives.forEach((incentive) => {
          let {
            amount,
            attributeValue,
            attributeName,
            conditionType,
            incentiveType,
          } = incentive || {};

          newIncentives.push({
            amount,
            attributeName: `${attributeName.id}`,
            attributeValue,
            conditionType: `${conditionType.id}`,
            entityType: "2",
            incentiveType: `${incentiveType.id}`,
          });
        });

        wales.push({
          amountRangeFrom,
          amountRangeTo,
          toPeriod,
          periodType: !!myPeriodType && !!myPeriodType.id ? myPeriodType.id : 0,
          fromPeriod,
          description,
          annualInterestRate,
          incentives: newIncentives,
        });
      }
    );

  useEffect(() => {
    if (JSON.stringify(wales)) {
      setInterests(wales);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(wales)]);

  const handleSubmitFixedDeposit = () => {
    if (!createFixedDepositAccount.linkAccountId) {
      dispatch(Toast({ text: "Fill mandatory fields", icon: "warning" }));
    } else {
      let charts = [
        {
          chartSlabs: interests,
          dateFormat: "dd MMMM yyyy",
          endDate: formatDate(validTo, true),
          fromDate: formatDate(validFrom, true),
          locale: "en",
        },
      ];

      let newCharges = [];
      charges.map((charge) => {
        let {
          chargeId,
          id,
          amount,
          chargeTimeType,
          date = "",
          feeInterval,
          name,
        } = charge;

        let dayMonth = !!date ? formatDate(date, true) : "";

        if (chargeTimeType.value === "Weekly Fee") {
          newCharges.push({
            dueDate: dayMonth,
            chargeId: chargeId ? chargeId : id,
            amount,
            feeInterval: parseFloat(feeInterval),
          });
        } else if (
          chargeTimeType.value === "Withdrawal Fee" ||
          chargeTimeType.value === "Savings Activation" ||
          chargeTimeType.value === "Overdraft Fee" ||
          chargeTimeType.value === "Saving No Activity Fee"
        ) {
          newCharges.push({
            // chargeId,
            chargeId: chargeId ? chargeId : id,
            amount,
          });
        } else if (chargeTimeType.value === "Specified due date") {
          newCharges.push({
            // chargeId,
            chargeId: chargeId ? chargeId : id,
            amount,
            dueDate: dayMonth,
          });
        } else if (chargeTimeType.value === "Annual Fee") {
          newCharges.push({
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            // chargeId,
            chargeId: chargeId ? chargeId : id,
            amount,
          });
        } else {
          newCharges.push({
            // removing the year and the space b4 it as endpoint only accept month and day
            feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
            // chargeId,
            chargeId: chargeId ? chargeId : id,
            amount,
            feeInterval: parseFloat(feeInterval),
          });
        }
      });

      // const newNominalAnnualInterestRate = createFixedDepositAccount

      const nominalAnnualInterestRate =
        charts[0].chartSlabs[0].annualInterestRate;
      // console.log(nominalAnnualInterestRate, 'newNominalAnnualInterestRate');

      let dataToSend = {
        ...createFixedDepositAccount,

        // transferInterestToSavings: createFixedDepositAccount.linkAccountId,
        // minDepositTermTypeId: minDepositTermType,
        linkAccountId: createFixedDepositAccount.linkAccountId,
        minDepositTermTypeId: minimunDepositTermType,
        minDepositTerm: minimumDepositTerm,

        maxDepositTermTypeId: maxDepositTermType,
        maxDepositTerm: maximumDepositTerm,
        maturityInstructionId: maturityInstruction,
        transferToSavingsId,
        charts,
        lockinPeriodFrequency,
        lockinPeriodFrequencyType,
        // lockinPeriodFrequencyType : lockinPeriodFrequencyType,
        nominalAnnualInterestRate,
        charges: newCharges,
        locale: "en",
        depositAmount,
        preClosurePenalApplicable,
        transferInterestToSavings,
        dateFormat: "dd MMMM yyyy",
        withHoldTax,
        monthDayFormat: "dd MMM",
        submittedOnDate: formatDate(submittedOnDate, true),
        productId,
      };

      const newObject = { ...dataToSend };
      for (const key in newObject) {
        if (Object.prototype.hasOwnProperty.call(newObject, key)) {
          const element = newObject[key];
          if (!element && typeof element !== "number") {
            delete newObject[key];
          } else if (element === undefined || element === "") {
            delete newObject[key];
          } else if ((element + "").length <= 0) {
            delete newObject[key];
          }
        }
      }

      if (newObject.charts[0].endDate === "") {
        delete newObject.charts[0].endDate;
      }

      dataToSend = newObject;
      console.log(dataToSend, "dataToSend");

      dispatch(createFixedDepositAccounts(dataToSend, history));
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-5 mt-1">
            <h3 className="page-title">Fixed Deposit</h3>
            <p className="text-label sub-title">Customer overall details</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/clients">Customer</Link>
              </span>
              /{" "}
              <span className="text-info">
                <Link to={`/core/client/${clientId}`}>
                  {!!clientName ? `${clientName} /` : ""}
                </Link>
              </span>
              Fixed Deposit
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12 col-md-12">
            <ContentWrapper>
              <div
                className="container pl-5 pr-5 pt-2 pb-2"
                id="fixed-deposit-screen"
              >
                <div className="row">
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <h3 className="page-title">Details</h3>
                  </div>

                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      {/* Product */}
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Product*</label>
                            <Select
                              placeholder="Select fixed deposit type"
                              size="md"
                              value={productId}
                              onChange={(e) => setproductId(e.target.value)}
                            >
                              {!!productOptions &&
                                !!productOptions.length &&
                                productOptions.map((cur) => {
                                  return (
                                    <option value={cur.id}>{cur.name}</option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>

                    {/* Submitted on* */}
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Submitted on*</label>
                            <FormControl style={{ width: "60%" }}>
                              <div className="datepicker-100-width">
                                <DatePicker
                                  id="start-date"
                                  selected={submittedOnDate}
                                  onChange={(date) => setSubmittedOnDate(date)}
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="dd/mmm/yyyy"
                                />
                              </div>
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Field Officer</label>
                            <Select
                              value={createFixedDepositAccount.fieldOfficerId}
                              name="fieldOfficerId"
                              onChange={createFixedDepositAccountHandler}
                              placeholder="Select field officer"
                              size="md"
                            >
                              {!!fieldOfficerOptions &&
                                !!fieldOfficerOptions.length &&
                                fieldOfficerOptions.map((cur) => {
                                  return (
                                    <option value={cur.id}>
                                      {cur.displayName}
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>
                  <div style={{ width: "100%" }} className="py-1">
                    <LineThrough width="100%" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <h3 className="page-title">Terms</h3>
                  </div>

                  {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Currency*</label>
                            <Select
                              disabled
                              value={
                                !!currency && !!currency.code && currency.code
                              }
                              placeholder="Select currency"
                              size="md"
                            >
                              <option
                                value={
                                  !!currency && !!currency.code && currency.code
                                }
                              >
                                {!!currency &&
                                  !!currency.displayLabel &&
                                  currency.displayLabel}
                              </option>
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Decimal Places</label>
                            <Input
                              value={
                                !!currency && !!currency.decimalPlaces
                                  ? currency.decimalPlaces
                                  : ""
                              }
                              placeholder="Decimal Places"
                              style={{ width: "60%" }}
                              disabled
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div> */}
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Interest Compounding Period*
                            </label>
                            <Select
                              placeholder="Select Interest compounding period"
                              size="md"
                              value={
                                createFixedDepositAccount.interestCompoundingPeriodType
                              }
                              name="interestCompoundingPeriodType"
                              onChange={createFixedDepositAccountHandler}
                            >
                              {!!interestCompoundingPeriodTypeOptions &&
                                !!interestCompoundingPeriodTypeOptions.length &&
                                interestCompoundingPeriodTypeOptions.map(
                                  (cur) => {
                                    return (
                                      <option value={cur.id}>
                                        {cur.value}
                                      </option>
                                    );
                                  }
                                )}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Interest Posting Period*
                            </label>
                            <Select
                              placeholder="Select Interest posting period"
                              size="md"
                              value={
                                createFixedDepositAccount.interestPostingPeriodType
                              }
                              name="interestPostingPeriodType"
                              onChange={createFixedDepositAccountHandler}
                            >
                              {!!interestPostingPeriodTypeOptions &&
                                !!interestPostingPeriodTypeOptions.length &&
                                interestPostingPeriodTypeOptions.map((cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Interest Calculated Using*
                            </label>
                            <Select
                              placeholder="Interest calculated using"
                              size="md"
                              value={
                                createFixedDepositAccount.interestCalculationType
                              }
                              name="interestCalculationType"
                              onChange={createFixedDepositAccountHandler}
                            >
                              {!!interestCalculationTypeOptions &&
                                !!interestCalculationTypeOptions.length &&
                                interestCalculationTypeOptions.map((cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Days In Year*</label>
                            <Select
                              placeholder="Select Days in Year"
                              size="md"
                              value={
                                createFixedDepositAccount.interestCalculationDaysInYearType
                              }
                              name="interestCalculationDaysInYearType"
                              onChange={createFixedDepositAccountHandler}
                            >
                              {!!interestCalculationDaysInYearTypeOptions &&
                                !!interestCalculationDaysInYearTypeOptions.length &&
                                interestCalculationDaysInYearTypeOptions.map(
                                  (cur) => {
                                    return (
                                      <option value={cur.id}>
                                        {cur.value}
                                      </option>
                                    );
                                  }
                                )}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Deposit Amount*
                            </label>
                            <CurrencyFormat
                              value={depositAmount}
                              name="depositAmount"
                              thousandSeparator={true}
                              className="amount-input"
                              style={{
                                width: "60%",
                              }}
                              onValueChange={(values) => {
                                const { value } = values;
                                setDepositAmount(value);
                              }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center mb-10-imp">
                            <label className="text-label">
                              Deposit Period*
                            </label>
                            <Input
                              value={createFixedDepositAccount.depositPeriod}
                              onChange={createFixedDepositAccountHandler}
                              name="depositPeriod"
                              style={{ width: "60%" }}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center"></label>
                            <Select
                              placeholder="Select Deposit Period Type"
                              size="md"
                              name="depositPeriodFrequencyId"
                              value={
                                createFixedDepositAccount.depositPeriodFrequencyId
                              }
                              onChange={createFixedDepositAccountHandler}
                            >
                              {!!periodFrequencyTypeOptions &&
                                !!periodFrequencyTypeOptions.length &&
                                periodFrequencyTypeOptions.map((cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center mb-10-imp">
                            <label className="text-label">Lock-In Period</label>
                            <Input
                              style={{ width: "60%" }}
                              // name="lockinPeriodFrequency"
                              value={lockinPeriodFrequency}
                              onChange={handleLockedInFreq}
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center"></label>
                            <Select
                              placeholder="Select Lock in Period Type"
                              size="md"
                              name="lockinPeriodFrequencyType"
                              value={lockinPeriodFreqType}
                              // value={
                              //   createFixedDepositAccount.lockinPeriodFrequencyType ===
                              //   ""
                              //     ? ""
                              //     : createFixedDepositAccount.lockinPeriodFrequencyType
                              // }
                              onChange={handleLockinFreqType}
                            >
                              {!!lockinPeriodFrequencyTypeOptions &&
                                !!lockinPeriodFrequencyTypeOptions.length &&
                                lockinPeriodFrequencyTypeOptions.map((cur) => {
                                  return (
                                    <option id={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>

                  <div style={{ width: "100%" }} className="py-1">
                    <LineThrough width="100%" />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <h3 className="page-title">Settings</h3>
                  </div>

                  {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Minimum Deposit Term
                            </label>
                            <Input
                              value={minimumDepositTerm}
                              // onChange={createFixedDepositAccountHandler}
                              onChange={handleMinDepositTerm}
                              name="minDepositTerm"
                              style={{ width: "60%" }}
                              size="md"
                              disabled
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center"></label>
                            <Select
                              placeholder="Select Minimum Deposit Period"
                              size="md"
                              name="minDepositTermTypeId"
                              disabled
                              value={minimumDepTermValue}
                              // value={
                              //   createFixedDepositAccount.minDepositTermTypeId ===
                              //   ""
                              //     ? 0
                              //     : createFixedDepositAccount.minDepositTermTypeId
                              // }
                              // onChange={createFixedDepositAccountHandler}
                              onChange={handleMinDepositType}
                            >
                              {!!periodFrequencyTypeOptions &&
                                !!periodFrequencyTypeOptions.length &&
                                periodFrequencyTypeOptions.map((cur) => {
                                  return (
                                    <option id={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              And thereafter, In Multiples of:
                            </label>
                            <Input
                              value={
                                createFixedDepositAccount.inMultiplesOfDepositTerm
                              }
                              onChange={createFixedDepositAccountHandler}
                              name="inMultiplesOfDepositTerm"
                              style={{ width: "60%" }}
                              size="md"
                              disabled
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center"></label>
                            <Select
                              placeholder="Select In Multiples of Deposit Term"
                              size="md"
                              name="inMultiplesOfDepositTermTypeId"
                              disabled
                              value={
                                createFixedDepositAccount.inMultiplesOfDepositTermTypeId
                              }
                              onChange={createFixedDepositAccountHandler}
                            >
                              {!!periodFrequencyTypeOptions &&
                                !!periodFrequencyTypeOptions.length &&
                                periodFrequencyTypeOptions.map((cur) => {
                                  return (
                                    <option value={cur.id}>{cur.value}</option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div> */}
                  {/* <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Maximum Deposit Term
                            </label>
                            <Input
                              value={maximumDepositTerm}
                              // onChange={createFixedDepositAccountHandler}
                              onChange={handleMaxDepositTerm}
                              name="maxDepositTerm"
                              disabled
                              style={{ width: "60%" }}
                              size="md"
                            />
                          </div>
                        </FormElements>
                      </FormClass>
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center"></label>
                            <Select
                              placeholder="Select Maximum Deposit Period Type"
                              size="md"
                              disabled
                              name="maxDepositTermTypeId"
                              value={maximumDepTermValue}
                              // value={
                              //   createFixedDepositAccount.maxDepositTermTypeId ===
                              //   ""
                              //     ? 0
                              //     : createFixedDepositAccount.maxDepositTermTypeId
                              // }
                              onChange={handleMaxDepositType}
                            >
                              {!!periodFrequencyTypeOptions &&
                                !!periodFrequencyTypeOptions.length &&
                                periodFrequencyTypeOptions.map((cur) => {
                                  return (
                                    <option id={cur.id} key={cur.id}>
                                      {cur.value}
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div> */}
                  <div
                    className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap"
                    style={{ marginBottom: "5px", alignItems: "center" }}
                  >
                    <div className="col-sm-4 col-md-4 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label d-flex flex-align-center">
                              For premature closure
                            </label>
                            {/* <FormControl style={{ width: "60%" }}> */}
                            <FormControl
                              className="fd-FormControl"
                              style={{ marginRight: "127px" }}
                            >
                              <Checkbox
                                size="md"
                                name="preClosurePenalApplicable"
                                isChecked={preClosurePenalApplicable}
                                isDisabled
                                onChange={(e) =>
                                  setPreClosurePenalApplicable(
                                    !preClosurePenalApplicable
                                  )
                                }
                              />
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    {preClosurePenalApplicable ? (
                      <>
                        {/*  Apply penal interest */}
                        {/* <div
                          className="col-sm-4 col-md-4 px-0 "
                          style={{ marginLeft: "-30px" }}
                        >
                          <FormClass>
                            <FormElements>
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label
                                  className="text-label"
                                  style={{
                                    width: "100%",
                                    // marginLeft: "-100px",
                                    marginLeft: "-156px",
                                  }}
                                >
                                  Apply penal interest
                                </label>
                                <Input
                                  value={
                                    createFixedDepositAccount.preClosurePenalInterest
                                  }
                                  onChange={createFixedDepositAccountHandler}
                                  name="preClosurePenalInterest"
                                  disabled
                                  // style={{ width: "60%", marginRight: "60px" }}
                                  style={{ width: "90%", marginRight: "10px" }}
                                  size="md"
                                />
                              </div>
                            </FormElements>
                          </FormClass>
                        </div> */}

                        {/* <div
                          className="col-sm-4 col-md-4 px-0"
                          style={{ marginRight: "18px" }}
                        >
                          <FormClass>
                            <FormElements>
                              <div className="form-group d-flex justify-content-between align-items-center">
                                <label className="text-label d-flex flex-align-center">
                                  on
                                </label>
                                <Select
                                  size="md"
                                  name="preClosurePenalInterestOnTypeId"
                                  placeholder="Select On"
                                  value={
                                    createFixedDepositAccount?.preClosurePenalInterestOnTypeId
                                      ? createFixedDepositAccount?.preClosurePenalInterestOnTypeId
                                      : ""
                                  }
                                  disabled
                                  onChange={createFixedDepositAccountHandler}
                                >
                                  {!!preClosurePenalInterestOnTypeOptions &&
                                    !!preClosurePenalInterestOnTypeOptions.length &&
                                    preClosurePenalInterestOnTypeOptions.map(
                                      (cur) => {
                                        return (
                                          <option value={cur.id} key={cur.id}>
                                            {cur.value}
                                          </option>
                                        );
                                      }
                                    )}
                                </Select>
                              </div>
                            </FormElements>
                          </FormClass>
                        </div> */}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Transfer Interest to Linked Savings Account
                            </label>
                            <FormControl style={{ width: "60%" }}>
                              <Checkbox
                                size="md"
                                name="transferInterestToSavings"
                                isChecked={transferInterestToSavings}
                                onChange={(e) =>
                                  setTransferInterestToSavings(
                                    !transferInterestToSavings
                                  )
                                }
                              />
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">Link Savings*</label>
                            <Select
                              placeholder="Select Link Savings"
                              size="md"
                              value={createFixedDepositAccount.linkAccountId}
                              name="linkAccountId"
                              onChange={createFixedDepositAccountHandler}
                            >
                              {!!savingsAccounts &&
                                !!savingsAccounts.length &&
                                savingsAccounts.map((cur) => {
                                  return (
                                    <option value={cur.id}>
                                      {cur.savingsProductName}({cur.accountNo})
                                    </option>
                                  );
                                })}
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                  </div>
                  {/* Maturity Instruction */}
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Maturity Instruction &nbsp; *
                            </label>
                            <Select
                              placeholder="Select Instruction"
                              size="md"
                              value={maturityInstruction}
                              name="maturityInstruction"
                              onChange={handleMaturityChange}
                            >
                              <option value="200">Transfer To Savings</option>
                            </Select>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>

                    {maturityInstruction === "200" ? (
                      <div className="col-sm-12 col-md-6 px-0 ">
                        <FormClass>
                          <FormElements>
                            <div className="form-group d-flex justify-content-between align-items-center">
                              <label className="text-label">
                                Transfer to Savings*
                              </label>
                              <Select
                                placeholder="Select Savings Account"
                                size="md"
                                value={transferToSavingsId}
                                name="transferToSavingsId"
                                onChange={handleTransferTo}
                              >
                                {!!savingsAccounts &&
                                  !!savingsAccounts.length &&
                                  savingsAccounts.map((cur) => {
                                    return (
                                      <option value={cur.id}>
                                        {cur.savingsProductName}({cur.accountNo}
                                        )
                                      </option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </FormElements>
                        </FormClass>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <div className="col-sm-12 col-md-6 px-0 ">
                      <FormClass>
                        <FormElements>
                          <div className="form-group d-flex justify-content-between align-items-center">
                            <label className="text-label">
                              Is Withholding Tax Applicable
                            </label>
                            <FormControl style={{ width: "60%" }}>
                              <Checkbox
                                size="md"
                                name="withHoldTax"
                                isChecked={withHoldTax}
                                onChange={(e) => setWithHoldTax(!withHoldTax)}
                              />
                            </FormControl>
                          </div>
                        </FormElements>
                      </FormClass>
                    </div>
                    {/* {withHoldTax ? (
                      <div className="col-sm-12 col-md-6 px-0">
                        <FormClass>
                          <FormElements>
                            <div className="form-group d-flex justify-content-between align-items-center">
                              <label className="text-label">Tax Group</label>
                              <Input
                                value={
                                  !!taxGroup && !!taxGroup.name
                                    ? taxGroup.name
                                    : ""
                                }
                                placeholder="Tax Group"
                                style={{ width: "60%" }}
                                disabled
                              />
                            </div>
                          </FormElements>
                        </FormClass>
                      </div>
                    ) : null} */}
                  </div>
                  <div style={{ width: "100%" }} className="py-1">
                    <LineThrough width="100%" />
                  </div>
                </div>

                {/* Interets Charts Goes here */}
                <InterestChartsTemp
                  validFrom={validFrom}
                  handleValidFrom={handleValidFrom}
                  validTo={validTo}
                  handleValidTo={handleValidTo}
                  interests={interests}
                  setInterests={setInterests}
                  // template={fixedDepositAccountCreationTemplate}
                  template={fixedDepositAccountCreateTemplate}
                  defaultData={defaultData}
                  // renderTooltip={renderTooltip}
                />

                <div className="row">
                  <div className="col-sm-12 col-md-12 d-flex justify-content-between flex-wrap">
                    <h3 className="page-title">Charges</h3>
                  </div>

                  <Charges
                    setCharges={setCharges}
                    charges={charges}
                    // template={fixedDepositAccountCreationTemplate}
                    template={fixedDepositAccountCreateTemplate}
                  />

                  {/* For Charges */}
                </div>

                <div className="float-right" style={{ padding: "6rem 0 3rem" }}>
                  <ActionButton
                    color="#2C1DFF"
                    onClick={(e) =>
                      history.push(`/core/client/${match.params.id}`)
                    }
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/cancel.svg")
                          .default
                      }
                      alt="cancel icon"
                    />
                    Cancel
                  </ActionButton>
                  <ActionButton
                    color="#2C1DFF"
                    style={{ margin: "0px 0px 0 10px" }}
                    onClick={(e) => handleSubmitFixedDeposit()}
                  >
                    <img
                      src={
                        require("../../../../../assets/images/icons/save.svg")
                          .default
                      }
                      alt="submit icon"
                    />
                    Submit
                  </ActionButton>
                </div>
              </div>
            </ContentWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

const RenderTooltip = ({ text, id, children }) => (
  <>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${id}`}>{text}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  </>
);

export default FixedDeposit;
