import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from "antd";
import { ContentWrapper, ActionButton, TabButton } from "./styles";
import {
  getSavingsAccountDetails,
  getSavingsAccountTemplate,
  postASavingsAccountIntrest,
  blockSavingsAccount,
  deleteSavingsAccount,
  activatePndSavingsAccount,
} from "../../../../actions/SavingsAndLoanActions";
import Transactions from "./Transactions";
import Charges from "./Charges";

import ModalContainer from "../../components/ModalContainer";
import DownloadAccountStatement from "../CustomerInformation/components/accounts/DownloadAccountStatement";
import UndoApproval from "../CustomerInformation/components/accounts/UndoApproval";
import EditSavingsAccount from "../CustomerInformation/components/accounts/EditSavingsAccount";
import ActivateAccount from "../CustomerInformation/components/accounts/ActivateAccount";
import ApproveAccount from "../CustomerInformation/components/accounts/ApproveAccount";
import RejectOrWithdrawAccount from "../CustomerInformation/components/accounts/RejectOrWithdrawAccount";
import AssignStaff from "./AssignStaff";
import AddChargeModal from "./AddChargeModal";
import CloseAccount from "./CloseAccount";
import Loader from "./Loader";
import SavingsDetails from "./SavingsDetails";

import { formatCurrency } from "../../../../util/FormatAmount";
import CheckPermission from "../../../../util/CheckPermission";

import BackSvg from "../../../../assets/images/icons/back.svg";
import ArrowCancelSvg from "../../../../assets/images/icons/arrow-cancel.svg";
import ArrowForwardSvg from "../../../../assets/images/icons/arror-forward.svg";

import { clearApplicableDataTablesSavings, getApplicableDataTables, getSavingsApplicableDataTables } from "../../../../actions/utilityActions";
import Notification from './Notifications/SMS';
import EmailNotification from './Notifications/Email';
import Notes from './Notes';
import DataTables from '../CustomerInformation2/components/DataTables';

const CustomerSavingsAccount = ({ history, currentId, setCurrentView }) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  const isTableLoading = useSelector(
    (state) => state.isTableLoading.isTableLoading
  );

  let { savingsAccountDetails = {}, savingsAccountTemplate = {} } =
    savingsAndLoan || {};

  const userDataRes = useSelector((state) => state.preAuth);
  const {
    responseUserData: { permissions },
  } = userDataRes;

  const dataTablesInfo = useSelector((state) => state.savingsManageDataTables);
  const { savingsApplicableDataTables = {} } = dataTablesInfo;
  // const dataTablesInfo = useSelector((state) => state.manageDataTables);
  // const { applicableDataTables = {} } = dataTablesInfo;

  // console.log(savingsApplicableDataTables , "savingsApplicableDataTables")

  const { TabPane } = Tabs;

  let {
    clientId,
    savingsProductName,
    accountNo,
    nubanNumber,
    fieldOfficerName,
    id,
    currency = {},
    status = {},
    subStatus = {},
    summary = {},
    transactions = [],
    charges = [],
  } = savingsAccountDetails || {};

  let { accountBalance, availableBalance } = summary || {};

  useEffect(() => {
    if (currentId) {
      dispatch(getSavingsAccountDetails(currentId));
      dispatch(getSavingsAccountTemplate(currentId));
    }
    // dispatch(getApplicableDataTables("m_savings_account"));
    dispatch(getSavingsApplicableDataTables("m_savings_account"));

    return () => {
      // Clear the applicableDataTables state when the component unmounts
      dispatch(clearApplicableDataTablesSavings());
    };
  }, [dispatch, currentId]);

  const [modalState, toggleModal] = useState(false);

  const [modalContentName, setModalContentName] = useState("");

  const [, setTransId] = useState("");

  const currentContent = (e, transId) => {
    const val = e.currentTarget.id.trim();
    setTransId(transId);

    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const [awaitingAction, setAwaitingAction] = useState("");

  const displayModalContent = () => {
    switch (modalContentName) {
      // case 'deposit':
      //   return (
      //     <Deposit
      //       history={history}
      //       transId={transactionId}
      //       onHide={handleModalClose}
      //     />
      //   );
      // case 'withdraw':
      // return (
      //   <Withdraw
      //     history={history}
      //     transId={transactionId}
      //     onHide={handleModalClose}
      //   />
      // );
      case "modifyApplication":
        return (
          <EditSavingsAccount
            history={history}
            id={id}
            onHide={handleModalClose}
            userId={clientId}
          />
        );
      case "undoApproval":
        return (
          <UndoApproval
            history={history}
            id={id}
            onHide={handleModalClose}
            userId={clientId}
          />
        );
      case "addCharge":
        return (
          <AddChargeModal history={history} id={id} onHide={handleModalClose} />
        );
      case "closeAccount":
        return (
          <CloseAccount
            history={history}
            id={id}
            onHide={handleModalClose}
            savingsAccountDetails={savingsAccountDetails}
          />
        );
      case "activateAccount":
        return (
          <ActivateAccount
            history={history}
            id={id}
            onHide={handleModalClose}
          />
        );
      case "approveAccount":
        return (
          <ApproveAccount history={history} id={id} onHide={handleModalClose} />
        );
      case "assignStaff":
        return (
          <AssignStaff
            savingsAccountTemplate={savingsAccountTemplate}
            history={history}
            id={id}
            currentSavingsAccountDetails={savingsAccountDetails}
            onHide={handleModalClose}
          />
        );
      case "accountStatement":
        return (
          <DownloadAccountStatement
            savingsAccountTemplate={savingsAccountTemplate}
            history={history}
            id={id}
            accountNo={accountNo}
            onHide={handleModalClose}
          />
        );
      case "rejectWithdraw":
        return (
          <RejectOrWithdrawAccount
            action={awaitingAction}
            history={history}
            id={id}
            onHide={handleModalClose}
          />
        );
      default:
        return;
    }
  };

  const handlePostIntrest = () => {
    dispatch(postASavingsAccountIntrest(currentId, history));
  };
  const handleDeleteAccount = () => {
    dispatch(deleteSavingsAccount(currentId, history));
  };
  const handlePndChange = () => {
    let action = subStatus.blockDebit === true ? "unblockDebit" : "blockDebit";
    dispatch(activatePndSavingsAccount(currentId, action, history));
  };

  const [showMenu, setShowMenu] = useState(false);

  const handleTakeActions = () => {
    return (
      <>
        <div className="blockUi">
          <div className="container-fluid">
            <div className="spaceBetweenFlexEnd">
              <div className="new-button-menus">
                <div className="row">
                  <div className="spaceBetweenFlexEnd w-100">
                    <div
                      onClick={() => setShowMenu(false)}
                      className="p-relative mb-3 pointer"
                    >
                      <img src={ArrowCancelSvg} alt="" />
                    </div>
                  </div>
                </div>

                {/* APPROVE_SAVINGSACCOUNT */}
                {status.approved === false &&
                status.active === false &&
                status.closed === false &&
                CheckPermission("APPROVE_SAVINGSACCOUNT", permissions) ? (
                  <div
                    className=""
                    id="approveAccount"
                    onClick={(e) => {
                      currentContent(e, id);
                      setShowMenu(false);
                    }}
                  >
                    <TabButton color="#2C1DFF">
                      <span className="new-design-text"> Approve</span>
                      <img className="ml-4" src={ArrowForwardSvg} alt="" />
                    </TabButton>
                  </div>
                ) : null}

                {/* MODIFY_SAVINGSACCOUNT */}
                {/* <Link
                  to={userData && `/core/savings-application/${userData.id}`}
                > */}
                {status.approved === false &&
                status.active === false &&
                status.closed === false &&
                CheckPermission("MODIFY_SAVINGSACCOUNT", permissions) ? (
                  <div
                    className=""
                    id="modifyApplication"
                    // onClick={(e) => {
                    //   currentContent(e, id);
                    //   setShowMenu(false);
                    // }}
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(`/core/client/edit-savings-account/${id}`);
                    }}
                  >
                    <TabButton color="#2C1DFF">
                      <span className="new-design-text">
                        {" "}
                        Modify Application
                      </span>
                      <img className="ml-4" src={ArrowForwardSvg} alt="" />
                    </TabButton>
                  </div>
                ) : null}
                {/* </Link> */}

                {/* "UndoApproved_SAVINGS" */}
                {status.active === false &&
                status.approved === true &&
                status.closed === false &&
                CheckPermission("UNDOSAVING_APPROVAL", permissions) ? (
                  <div
                    className=""
                    id="undoApproval"
                    onClick={(e) => {
                      currentContent(e, id);
                      setShowMenu(false);
                    }}
                  >
                    <TabButton color="#2C1DFF">
                      <span className="new-design-text"> Undo Approval</span>

                      <img className="ml-4" src={ArrowForwardSvg} alt="" />
                    </TabButton>
                  </div>
                ) : null}

                {/* ACTIVATE_SAVINGSACCOUNT */}
                {status.active === false &&
                status.approved === true &&
                status.closed === false &&
                CheckPermission("ACTIVATE_SAVINGSACCOUNT", permissions) ? (
                  <div
                    className=""
                    id="activateAccount"
                    onClick={(e) => {
                      currentContent(e, id);
                      setShowMenu(false);
                    }}
                  >
                    <TabButton color="#2C1DFF">
                      <span className="new-design-text"> Activate</span>
                      <img className="ml-4" src={ArrowForwardSvg} alt="" />
                    </TabButton>
                  </div>
                ) : null}

                {/* Remove deposit and withdrawal tab buttons */}
                {/* {status.active ? (
                  <>
                    {CheckPermission('DEPOSIT_SAVINGSACCOUNT', permissions) ? (
                      <>
                        {' '}
                        <div
                          className=""
                          id="deposit"
                          onClick={(e) => {
                            currentContent(e, id);
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text"> Deposit</span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>{' '}
                      </>
                    ) : (
                      ''
                    )}
                    {CheckPermission('WITHDRAW_SAVINGSACCOUNT', permissions) ? (
                      <>
                        {' '}
                        <div
                          className=""
                          id="withdraw"
                          onClick={(e) => {
                            currentContent(e, id);
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text"> Withdraw</span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>{' '}
                      </>
                    ) : (
                      ''
                    )}{' '}
                  </>
                ) : null} */}

                {status.closed === false ? (
                  <>
                    {CheckPermission(
                      !fieldOfficerName
                        ? "ASSIGNSTAFF_CLIENT"
                        : "UNASSIGTAFF_CLIENT",
                      permissions
                    ) ? (
                      <div
                        className=""
                        id="assignStaff"
                        onClick={(e) => {
                          currentContent(e, id);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">
                            {!fieldOfficerName ? "Assign" : "UnAssign"}
                          </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : (
                      ""
                    )}

                    {status.active === true ? (
                      <>
                        {CheckPermission(
                          "POSTINTEREST_SAVINGSACCOUNT",
                          permissions
                        ) ? (
                          <>
                            {" "}
                            <div
                              className=""
                              onClick={(e) => {
                                e.preventDefault();
                                handlePostIntrest();
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Post Interest
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {CheckPermission(
                          "CREATE_SAVINGSACCOUNTCHARGE",
                          permissions
                        ) ? (
                          <>
                            {" "}
                            <div
                              id="addCharge"
                              onClick={(e) => {
                                currentContent(e, id);
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Add Charge
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}

                        {CheckPermission("READ_REPORT", permissions) && (
                          <div
                            id="accountStatement"
                            onClick={(e) => {
                              currentContent(e, id);
                              setShowMenu(false);
                            }}
                          >
                            <TabButton
                              color="#2C1DFF"
                              className="new-design-text"
                            >
                              <span className="new-design-text">
                                Generate Statement
                              </span>
                              <img
                                className="ml-4"
                                src={ArrowForwardSvg}
                                alt=""
                              />
                            </TabButton>
                          </div>
                        )}

                        {CheckPermission("BLOCK_SAVINGSACCOUNT", permissions) &&
                        subStatus.block === false ? (
                          <>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(
                                  blockSavingsAccount(id, "block", history)
                                );
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                {" "}
                                <span className="new-design-text">
                                  {" "}
                                  Block Account{" "}
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {CheckPermission(
                          "UNBLOCK_SAVINGSACCOUNT",
                          permissions
                        ) && subStatus.block === true ? (
                          <>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(
                                  blockSavingsAccount(id, "Unblock", history)
                                );
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Unblock Account
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}

                        <div
                          onClick={(e) => {
                            e.preventDefault();
                            handlePndChange();
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text">
                              {subStatus.blockDebit === true
                                ? "Release"
                                : "Activate"}{" "}
                              PND
                            </span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>
                        <div
                          onClick={(e) => {
                            // e.preventDefault();
                            history.push(
                              `/core/client/savings-account/transfer-fund/${id}`
                            );
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text">
                              Transfer Fund
                            </span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>
                      </>
                    ) : (
                      <>
                        {CheckPermission(
                          "REJECT_SAVINGSACCOUNT",
                          permissions
                        ) ? (
                          <>
                            {" "}
                            <div
                              id="rejectWithdraw"
                              onClick={(e) => {
                                e.preventDefault();
                                setAwaitingAction("reject");
                                currentContent(e, id);
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">Reject</span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}

                        {CheckPermission(
                          "REJECT_SAVINGSACCOUNT",
                          permissions
                        ) ? (
                          <>
                            {" "}
                            <div
                              id="rejectWithdraw"
                              onClick={(e) => {
                                e.preventDefault();
                                setAwaitingAction("withdrawnByApplicant");
                                currentContent(e, id);
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Withdrawn By Client
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}

                        {CheckPermission(
                          "CREATE_SAVINGSACCOUNTCHARGE",
                          permissions
                        ) ? (
                          <>
                            <div
                              id="addCharge"
                              onClick={(e) => {
                                currentContent(e, id);
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Add Charge
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}

                        {CheckPermission(
                          "DELETE_SAVINGSACCOUNT",
                          permissions
                        ) ? (
                          <>
                            {" "}
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                handleDeleteAccount();
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">Delete</span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="p-relative">
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      {showMenu && handleTakeActions()}
      <div className="">
        <ContentWrapper>
          <>
            <div className="row">
              <div className="col-xl-6 col-md-6">
                <p
                  onClick={() => {
                    history?.location?.pathname?.includes('deposits') ? history?.goBack() : setCurrentView(1);
                  }}
                  className="back-btn d-flex"
                >
                  <img className="mr-2" src={BackSvg} alt="" />
                  Back
                </p>
              </div>
              <div className="col-xl-6 col-md-6">
                <div className="spaceBetweenFlexEnd">
                  <ActionButton
                    onClick={() => setShowMenu(!showMenu)}
                    color="#2C1DFF"
                  >
                    Take Action
                    <img
                      src={
                        require("../../../../assets/images/icons/arrow-right.svg")
                          .default
                      }
                      className="ml-2"
                      alt="take action"
                    />
                  </ActionButton>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-xl-12">
                <p className="headerSpanBal-p">
                  {!!accountNo ? `${accountNo}` : ""}{" "}
                  {!!savingsProductName ? savingsProductName : ""}
                </p>
                {nubanNumber && (
                  <p className="account-details">
                    <span className="headerSpan">Nuban</span>:{" "}
                    {!!nubanNumber ? nubanNumber : ""}
                  </p>
                )}
                {status.active ? (
                  <p className="account-balance">
                    <span className="headerSpanBal">Ledger Balance :</span>{" "}
                    <span className="amountCurr">
                      {!!currency.code ? currency.code : ""}{" "}
                    </span>
                    {/* <span className="headerSpanAvail"> */}
                    <span className="headerSpanBal">
                      {!!accountBalance ? formatCurrency(accountBalance) : "0"}
                    </span>
                  </p>
                ) : null}{" "}
                {status.active ? (
                  <p className="account-balance">
                    <span className="headerSpanBal">Available Bal :</span>{" "}
                    <span className="amountCurr">
                      {!!currency.code ? currency.code : ""}{" "}
                    </span>
                    <span className="headerSpanBal">
                      {!!availableBalance
                        ? formatCurrency(availableBalance)
                        : "0"}
                    </span>
                  </p>
                ) : null}
              </div>
            </div>
          </>

          {/* <div className="break"></div> */}
          {/* {status.closed === false ? ( */}
          <div className="row mt-2">
            <Tabs defaultActiveKey="details" className="full-width">
              {/* SavingsDetails */}
              <TabPane tab="Details" key="details">
                <SavingsDetails savingsAccountDetails={savingsAccountDetails} />
              </TabPane>
              {status.active || status === "Done" ? (
                <TabPane tab="Transactions" key="transactions">
                  <div>
                    <Transactions
                      transactions={transactions}
                      history={history}
                      id={id}
                    />
                  </div>
                </TabPane>
              ) : null}
              {/* <TabPane tab="Email Notification" key="notification">
                  <EmailNotification
                    id={id}
                    history={history}
                    accountNumber={accountNo}
                  />
                </TabPane>
                <TabPane tab="SMS Notification" key="smsNotification">
                  <SmsNotification
                    id={id}
                    history={history}
                    accountNumber={accountNo}
                  />
                </TabPane> */}
              <TabPane tab="Charges" key="charges">
                <Charges id={id} history={history} charges={charges} />
              </TabPane>

              {/* <TabPane tab="Account Audit" key="audit">
                  <AccountAudit
                    id={id}
                    history={history}
                    accountNo={accountNo}
                  />
                </TabPane> */}
              <TabPane tab="Data Tables" key="dataTables">
                <DataTables
                  id={id}
                  type="savings_account"
                  dataTables={savingsApplicableDataTables}
                />
              </TabPane>
              {status.active || status === "Done" ? (
                <TabPane tab="SMS Alerts" key="sms-notifications">
                  <div>
                    <Notification
                      id={id}/>
                  </div>
                </TabPane>
              ) : null}
              {status.active || status === "Done" ? (
                <TabPane tab="Email Alerts" key="email-notifications">
                  <div>
                    <EmailNotification
                      id={id}/>
                  </div>
                </TabPane>
              ) : null}
              {CheckPermission('READ_SAVINGNOTE', permissions) && (
                <TabPane tab="Notes" key="notes">
                  <Notes id={id}/>
                </TabPane>
              )}
            </Tabs>
          </div>
          {/* ) : null} */}
        </ContentWrapper>
      </div>
    </div>
  );
};

export default CustomerSavingsAccount;
