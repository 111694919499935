import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ContentWrapper } from "../styles";

import AppDetails from "../components/common/AppDetails";
import AppTerms from "../components/common/AppTerms";
import AppCharges from "../components/common/AppCharges";
import AppReviews from "../components/common/AppReviews";

import SavingDetails from "../components/accounts/SavingDetails.jsx";
import SavingTerms from "../components/accounts/SavingTerms.jsx";
import SavingCharge from "../components/accounts/SavingCharge.jsx";
import SavingReview from "../components/accounts/SavingReview.jsx";
import ScrollToTop from "../../../../../components/ScrollToTop/index.jsx";
import { formatDate } from "../../../../../util/FormatDate";
// import Toast from "../../util/Toast";
import Toast from "../../../../../util/Toast";

import {
  // addSavingsCharge,
  // getSavingsChargeOptionData,
  getSavingsProductOptions,
  getSelectedSavingsProductOptions,
} from "../../../../../actions/utilityActions";
import { createSavingsAccount } from "../../../../../actions/customerActions";

import {
  getSavingsProductsTemplate,
  resetNewSavingsProductState,
} from "../../../../../actions/configurationActions";

const SavingsApplicationScreen = ({ match, history }) => {
  const dispatch = useDispatch();

  const fetchedProductData = useSelector((state) => state.selectedOptionData);
  const savingsProductOptions = useSelector(
    (state) => state.savingsProductOptions
  );

  const savingsProductsTemplate = useSelector(
    (state) => state.savingsProductsTemplate
  );

  const { savingsProductsTemplate: template } = savingsProductsTemplate;

  useEffect(() => {
    dispatch(getSavingsProductsTemplate());
    dispatch(resetNewSavingsProductState());
  }, []);


  const { productOptionsData } = fetchedProductData;

  let { productOptions } = savingsProductOptions || {};

  let { clientName } = productOptions || {};

  const fetchedSavingsChargeData = useSelector(
    (state) => state.savingsChargeData
  );

  const { chargeData } = fetchedSavingsChargeData;


  const [fieldOfficer, SetFieldOfficer] = useState("");
  const [fieldOfficerId, SetFieldOfficerId] = useState("");

  const [productType, setProductType] = useState("");
  const [productId, setProductId] = useState("");

  const [submittedDate, setSubmittedDate] = useState(new Date());
  let submittedOnDate = formatDate(submittedDate);

  const [externalId, setExternalId] = useState("");

  const [nominalAnnualInterestRate, setNominalAnnualInterestRate] =
    useState("");

  const [interestCalculationTypeChk, setInterestCalculationTypeChk] =
    useState("");
  const [interestCalculationType, setInterestCalculationType] = useState("");

  const [interestCalcDIYType, setInterestCalcDIYType] = useState("");
  const [
    interestCalculationDaysInYearType,
    setInterestCalculationDaysInYearType,
  ] = useState("");

  const [interestCompPeriodType, setInterestCompPeriodType] = useState("");
  const [interestCompoundingPeriodType, setInterestCompoundingPeriodType] =
    useState("");

  const [interestPostPeriodType, setInterestPostPeriodType] = useState("");
  const [interestPostingPeriodType, setInterestPostingPeriodType] =
    useState("");

  const [lockinPeriodFrequency, setLockinPeriodFrequency] = useState();

  const [lockinPeriodFreqType, stLockinPeriodFreqType] = useState("");
  const [lockinPeriodFrequencyType, stLockinPeriodFrequencyType] = useState("");

  const [minRequiredOpeningBalance, setMinRequiredOpeningBalance] =
    useState("");

  const [minRequiredBalance, setMinRequiredBalance] = useState("");

  const [overdraftLimit, setOverdraftLimit] = useState("");

  const [
    minOverdraftForInterestCalculation,
    setMinOverdraftForInterestCalculation,
  ] = useState("");

  const [
    nominalAnnualInterestRateOverdraft,
    setNominalAnnualInterestRateOverdraft,
  ] = useState("");

  const [overdraft, setOverdraft] = useState("");
  const [allowOverdraft, setAllowOverdraft] = useState("");

  const [enforceMinReqBal, setEnforceMinReqBal] = useState("");
  const [enforceMinRequiredBalance, setEnforceMinRequiredBalance] =
    useState("");

  const [withdrawFeeForTrans, setWithdrawFeeForTrans] = useState("");
  const [withdrawalFeeForTransfers, setWithdrawalFeeForTransfers] =
    useState("");

  const [charge, setCharge] = useState("");
  const [chargeId, setChargeId] = useState("");

  const [chargesArray, setChargesArray] = useState([]);
  const [myChargesArray, setMyChargesArray] = useState([]);

  const [charges, setCharges] = useState([]);

  const [myCharges, setMyCharges] = useState({});
  const [detailsError, setDetailsError] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [chargesError, setChargesError] = useState(false);
  const [withdrawals, setWithdrawals] = useState({
    withdrawalLimit: "",
    numberOfWithdrawals: "",
  });

  useEffect(() => {
    const createCharges = async () => {
      if (productOptionsData) {
        let myCharges = {
          index: myChargesArray.length,
          name: productOptionsData.name ? productOptionsData.name : "",
          chargeId: productOptionsData.id ? productOptionsData.id : "",
          amount: productOptionsData.amount ? productOptionsData.amount : "",
          type:
            productOptionsData.chargeCalculationType &&
            productOptionsData.chargeCalculationType.value
              ? productOptionsData.chargeCalculationType.value
              : "",
          chargeTimeTypeName:
            productOptionsData.chargeTimeType &&
            productOptionsData.chargeTimeType.value
              ? productOptionsData.chargeTimeType.value
              : "",
          chargeTimeType:
            productOptionsData.chargeTimeType &&
            productOptionsData.chargeTimeType.id
              ? productOptionsData.chargeTimeType.id
              : "",
          // date: productOptionsData.feeOnMonthDay ? formatDate(productOptionsData.feeOnMonthDay) : "",
          // date: productOptionsData.feeOnMonthDay,
          date: "",
          repayments: productOptionsData.feeInterval,
        };
        await setMyChargesArray([...myChargesArray, myCharges]);

        await setChargesArray([...chargesArray, productOptionsData]);
      }
    };
    createCharges();
  }, [productOptionsData]);

  // const handleAddCharge = async () => {
  //   // await dispatch(getSavingsChargeOptionData(chargeId));
  //   // await dispatch(addSavingsCharge(chargeData));
  //   // let hoo = [];
  //   // myChargesArray.forEach((charge) => {
  //   //   let { chargeId, amount, date } = charge;
  //   //   let newDate = new Date(date);
  //   //   hoo.push({
  //   //     chargeId,
  //   //     amount,
  //   //     dueDate: newDate,
  //   //   });
  //   // });
  //   // setCharges(hoo);
  // };

  const handleSelectedChargeDate = (selectedIndex, date) => {
    let mydata = [...myChargesArray];
    let newDate = new Date(date);
    mydata[Number(selectedIndex)].date = newDate;
    setMyChargesArray(mydata);
  };

  const handleDeleteChargeData = (position) => {
    let noo = [];
    myChargesArray.forEach((element, index) => {
      if (position !== index) {
        noo.push(element);
      }
    });
    setMyChargesArray(noo);
  };


  const handleAmountChange = (index, amount) => {
    let hhh = [...myChargesArray];
    hhh[Number(index)].amount = amount;
    setMyChargesArray(hhh);
  };

  const handleRepaymentsChange = (index, repayments) => {
    let hhh = [...myChargesArray];
    hhh[Number(index)].repayments = repayments;
    setMyChargesArray(hhh);
  };

  // const [withHoldTax, SetWithHoldTax] =  useState()

  // HANDLE CHARGE ID
  const handleCharge = (e) => {
    setCharge(e.target.value);
    let arr = e.target.querySelectorAll("option");

    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        setChargeId(x.id);
      }
    });
  };

  useEffect(() => {
    if (productId && match.params.id) {
      dispatch(getSelectedSavingsProductOptions(match.params.id, productType));
    }
  }, [productType]);

  // HANDLE PRODUCT ID
  const handleProductType = (e) => {
    setProductType(e.target.value);
    setProductId(+e.target.value);
  };

  //HANDLE SUBMITTED DATE DATA
  const handleSubmittedOn = (date) => {
    setSubmittedDate(date);
    submittedOnDate = formatDate(date);
  };
  // HANDLE FIELD OFFICER
  const handleOfficer = (e) => {
    SetFieldOfficer(e.target.value);
    SetFieldOfficerId(e.target.value);
    // let arr = e.target.querySelectorAll("option");
    // arr.forEach((x) => {
    //   if (e.target.value == x.innerHTML && x.value == x.innerHTML) {
    //     SetFieldOfficerId(x.id);
    //   }
    // });
  };
  // HANDLE OVERDRAFT
  const handleOverdraft = (e) => {
    setOverdraft(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        setAllowOverdraft(x.id);
      }
    });
  };

  // HANDLE WITHDRAWAL LIMIT
  const handleWithdrawalLimit = (e) => {
    setWithdrawals({ ...withdrawals, [e.target.name]: e.target.value });
  };
  // HANDLE MIN REQUIRED BALANCE
  const handleEnforceReqBal = (e) => {
    setEnforceMinReqBal(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        setEnforceMinRequiredBalance(x.id);
      }
    });
  };
  // HANDLE WITHDRAWAL FEE FOR TRANSFERS
  const handleWithdrawalFee = (e) => {
    setWithdrawFeeForTrans(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        setWithdrawalFeeForTransfers(x.id);
      }
    });
  };
  //HANDLE EXTERNAL ID
  const handleExternalId = (e) => setExternalId(e.target.value);
  //HANDLE NOMINAL INTERST RATE
  const handleNorminalIntRt = (e) =>
    setNominalAnnualInterestRate(e.target.value);
  //HANDLE MIN OPENING BALANCE
  const handleOpeningBal = (val) => setMinRequiredOpeningBalance(val);
  //HANDLE MIN REQUIRED BALANCE
  const handleMinimumBal = (val) => setMinRequiredBalance(val);

  //HANDLE MIN REQUIRED BALANCE
  const handleOverDraftLimit = (val) => setOverdraftLimit(val);

  //HANDLE MIN REQUIRED BALANCE
  const handleMinOverdraftForInterestCalculation = (val) =>
    setMinOverdraftForInterestCalculation(val);

  //HANDLE MIN REQUIRED BALANCE
  const handleNominalAnnualInterestRateOverdraft = (val) =>
    setNominalAnnualInterestRateOverdraft(val);

  //HANDLE LOCKED IN FREQUENCY
  const handleLockedInFreq = (e) => setLockinPeriodFrequency(e.target.value);
  //HANDLE LOCKED IN FREQUENCY TYPE
  const handleLockinFreqType = (e) => {
    stLockinPeriodFreqType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        stLockinPeriodFrequencyType(x.id);
      }
    });
  };
  //HANDLE INTEREST CALC TYPE
  const handleIntCalcType = (e) => {
    setInterestCalculationTypeChk(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        setInterestCalculationType(x.id);
      }
    });
  };
  //HANDLE SELECTED NUMBER OF DAYS IN YEAR
  const handleIntCalcDIYType = (e) => {
    setInterestCalcDIYType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        setInterestCalculationDaysInYearType(x.id);
      }
    });
  };
  //HANDLE COMPOUNDING PERIOD
  const handleCompoundingPeriod = (e) => {
    setInterestCompPeriodType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        setInterestCompoundingPeriodType(x.id);
      }
    });
  };
  //HANDLE COMPOUNDING PERIOD
  const handleIntPostPeriod = (e) => {
    setInterestPostPeriodType(e.target.value);
    let arr = e.target.querySelectorAll("option");
    arr.forEach((x) => {
      if (e.target.value == x.innerHTML) {
        setInterestPostingPeriodType(x.id);
      }
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getSavingsProductOptions(match.params.id));
    };
    fetchData();

    if (productOptionsData.nominalAnnualInterestRate) {
      setNominalAnnualInterestRate(
        productOptionsData.nominalAnnualInterestRate
      );
    }
    if (productOptionsData.nominalAnnualInterestRate == 0) {
      setNominalAnnualInterestRate("0");
    }
    if (productOptionsData.lockinPeriodFrequency) {
      setLockinPeriodFrequency(productOptionsData.lockinPeriodFrequency);
    }
    if (productOptionsData.minRequiredOpeningBalance) {
      setMinRequiredOpeningBalance(
        productOptionsData.minRequiredOpeningBalance
      );
    }
    if (productOptionsData.minRequiredBalance) {
      setMinRequiredBalance(productOptionsData.minRequiredBalance);
    }
    if (productOptionsData.overdraftLimit) {
      setOverdraftLimit(productOptionsData.overdraftLimit);
    } else {
      setOverdraftLimit("");
    }
    if (productOptionsData.minOverdraftForInterestCalculation) {
      setMinOverdraftForInterestCalculation(
        productOptionsData.minOverdraftForInterestCalculation
      );
    } else {
      setMinOverdraftForInterestCalculation("");
    }
    if (productOptionsData.nominalAnnualInterestRateOverdraft) {
      setNominalAnnualInterestRateOverdraft(
        productOptionsData.nominalAnnualInterestRateOverdraft
      );
    } else {
      setNominalAnnualInterestRateOverdraft("");
    }
    if (productOptionsData.lockinPeriodFrequencyType) {
      stLockinPeriodFreqType(
        productOptionsData.lockinPeriodFrequencyType.value
      );
      stLockinPeriodFrequencyType(
        productOptionsData.lockinPeriodFrequencyType.id
      );
    }
    if (productOptionsData.interestCalculationType) {
      setInterestCalculationTypeChk(
        productOptionsData.interestCalculationType.value
      );
      setInterestCalculationType(productOptionsData.interestCalculationType.id);
    }
    if (productOptionsData.interestCalculationDaysInYearType) {
      setInterestCalcDIYType(
        productOptionsData.interestCalculationDaysInYearType.value
      );
      setInterestCalculationDaysInYearType(
        productOptionsData.interestCalculationDaysInYearType.id
      );
    }
    if (productOptionsData.interestCompoundingPeriodType) {
      setInterestCompPeriodType(
        productOptionsData.interestCompoundingPeriodType.value
      );
      setInterestCompoundingPeriodType(
        productOptionsData.interestCompoundingPeriodType.id
      );
    }
    if (productOptionsData.interestPostingPeriodType) {
      setInterestPostPeriodType(
        productOptionsData.interestPostingPeriodType.value
      );
      setInterestPostingPeriodType(
        productOptionsData.interestPostingPeriodType.id
      );
    }
    if (productOptionsData.allowOverdraft == true) {
      setOverdraft("Yes");
      setAllowOverdraft(productOptionsData.allowOverdraft);
    } else if (productOptionsData.allowOverdraft == false) {
      setOverdraft("No");
      setAllowOverdraft(productOptionsData.allowOverdraft);
    }
    if (productOptionsData.enforceMinRequiredBalance == true) {
      setEnforceMinReqBal("Yes");
      setEnforceMinRequiredBalance(
        productOptionsData.enforceMinRequiredBalance
      );
    } else if (productOptionsData.enforceMinRequiredBalance == false) {
      setEnforceMinReqBal("No");
      setEnforceMinRequiredBalance(
        productOptionsData.enforceMinRequiredBalance
      );
    }
    if (productOptionsData.withdrawalFeeForTransfers == true) {
      setWithdrawFeeForTrans("Yes");
      setWithdrawalFeeForTransfers(
        productOptionsData.withdrawalFeeForTransfers
      );
    } else if (productOptionsData.withdrawalFeeForTransfers == false) {
      setWithdrawFeeForTrans("No");
      setWithdrawalFeeForTransfers(
        productOptionsData.withdrawalFeeForTransfers
      );
    }


    if (productOptionsData.charges) {
      setChargesArray(productOptionsData.charges);
      setMyChargesArray(
        productOptionsData.charges.map((charge) => {
          return {
            index: myChargesArray.length,
            name: charge.name,
            chargeId: charge.chargeId,
            amount: charge.amount,
            type: charge.chargeCalculationType.value,
            chargeTimeTypeName: charge.chargeTimeType.value,
            chargeTimeType: charge.chargeTimeType.id,
            // date: charge?.feeOnMonthDay,
            date: !!charge?.feeOnMonthDay
              ? formatDate(charge.feeOnMonthDay, true)
              : "",
            repayments: charge.feeInterval,
          };
        })
      );
    }
  }, [
    match,
    productOptionsData.nominalAnnualInterestRate,
    productOptionsData.lockinPeriodFrequency,
    productOptionsData.lockinPeriodFrequencyType,
    productOptionsData.minRequiredOpeningBalance,
    productOptionsData.minRequiredBalance,
    productOptionsData.overdraftLimit,
    productOptionsData.minOverdraftForInterestCalculation,
    productOptionsData.nominalAnnualInterestRateOverdraft,
    productOptionsData.withdrawalFeeForTransfers,
    productOptionsData.allowOverdraft,
    productOptionsData.enforceMinRequiredBalance,
    productOptionsData.interestCalculationType,
    productOptionsData.interestCalculationDaysInYearType,
    productOptionsData.interestCompoundingPeriodType,
    productOptionsData.interestPostingPeriodType,
    // productOptionsData.charges,
    productOptionsData,
    dispatch
    // myChargesArray.length,
  ]);


  //HANDLE SUBMITTING DATA FOR ACCOUNT CREATION
  let reviewData = {
    externalId,
    fieldOfficer,
    interestCalculationTypeChk,
    interestCalcDIYType,
    interestCompPeriodType,
    interestPostPeriodType,
    lockinPeriodFreqType,
    minRequiredOpeningBalance,
  };

  let dataHolder = {
    allowOverdraft,
    enforceMinRequiredBalance,
    withdrawalFeeForTransfers,
    lockinPeriodFrequencyType,
    interestCalculationType,
    interestPostingPeriodType,
    nominalAnnualInterestRate: parseFloat(nominalAnnualInterestRate),
    interestCompoundingPeriodType,
    submittedOnDate,
    interestCalculationDaysInYearType,
    productId,
    externalId,
    fieldOfficerId,
    minRequiredOpeningBalance,
    minRequiredBalance,
    overdraftLimit:
      overdraftLimit && (allowOverdraft || allowOverdraft === "true")
        ? Number(overdraftLimit)
        : "",
    minOverdraftForInterestCalculation:
      minOverdraftForInterestCalculation &&
      (allowOverdraft || allowOverdraft === "true")
        ? Number(minOverdraftForInterestCalculation)
        : "",
    nominalAnnualInterestRateOverdraft:
      nominalAnnualInterestRateOverdraft &&
      (allowOverdraft || allowOverdraft === "true")
        ? Number(nominalAnnualInterestRateOverdraft)
        : "",
    lockinPeriodFrequency,
    clientId: match.params.id,
    dateFormat: "dd MMM yyyy",
    monthDayFormat: "dd MMM",
    locale: "en",
    charges: [...myChargesArray],
  };

  const handleCreateSavingsSubmit = async () => {
    let newCharges = [];
    dataHolder.charges.map((charge) => {
      let { chargeId, amount, chargeTimeTypeName, date, repayments, name } =
        charge;
      let dayMonth = !!date ? formatDate(date, true) : "";
      if (chargeTimeTypeName === "Weekly Fee") {
        newCharges.push({
          dueDate: dayMonth,
          chargeId,
          amount,
          feeInterval: parseFloat(repayments),
        });
      } else if (
        chargeTimeTypeName === "Withdrawal Fee" ||
        chargeTimeTypeName === "Savings Activation" ||
        chargeTimeTypeName === "Overdraft Fee" ||
        chargeTimeTypeName === "Saving No Activity Fee"
      ) {
        newCharges.push({
          chargeId,
          amount,
        });
      } else if (chargeTimeTypeName === "Specified due date") {
        newCharges.push({
          chargeId,
          amount,
          dueDate: dayMonth,
        });
      } else if (chargeTimeTypeName === "Annual Fee") {
        newCharges.push({
          feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
          chargeId,
          amount,
        });
      } else {
        newCharges.push({
          // removing the year and the space b4 it as endpoint only accept month and day
          feeOnMonthDay: dayMonth?.substring(0, dayMonth?.length - 5),
          chargeId,
          amount,
          feeInterval: parseFloat(repayments),
        });
      }
    });
    const newData = { ...dataHolder, charges: newCharges };

    for (const key in newData) {
      if (Object.prototype.hasOwnProperty.call(newData, key)) {
        const element = newData[key];
        if (!element && typeof element !== "number") {
          delete newData[key];
        } else if ((element + "").length <= 0) {
          delete newData[key];
        }
      }
    }

    const dataToSend = newData;
    if (
      // !nubanPrefix ||
      detailsError ||
      termsError ||
      chargesError
    ) {
      dispatch(
        Toast({
          text: "Please fill the required fields",
          icon: "error",
          timer: 2000,
        })
      );
    } else if (
      overdraftLimit &&
      productOptionsData.overdraftLimit < overdraftLimit
    ) {
      dispatch(
        Toast({
          text: "Overdraft Limit can't exceed product overdraft limit",
          icon: "error",
          timer: 2000,
        })
      );
    } else {
      await dispatch(createSavingsAccount(dataToSend, history));
    }
  };

  const [currentScreen, setCurrentScreen] = useState(0);
  const renderComponent = () => {
    let currentComponet = "";
    switch (currentScreen) {
      case 0:
        currentComponet = (
          <AppDetails>
            {/* SavingDetails */}
            <SavingDetails
              setDetailsError={setDetailsError}
              submittedOnDate={submittedDate}
              handleSubmittedOn={handleSubmittedOn}
              productType={productType}
              handleProductType={handleProductType}
              fieldOfficer={fieldOfficer}
              handleOfficer={handleOfficer}
              externalId={externalId}
              handleExternalId={handleExternalId}
              handleNextScreen={handleNextCustomIndexSelect}
            />
          </AppDetails>
        );
        break;
      case 1:
        currentComponet = (
          <AppTerms error={detailsError}>
            <SavingTerms
              setTermsError={setTermsError}
              nominalAnnualInterestRate={nominalAnnualInterestRate}
              handleNorminalIntRt={handleNorminalIntRt}
              handleIntCalcType={handleIntCalcType}
              interestCalculationTypeChk={interestCalculationTypeChk}
              interestCalcDIYType={interestCalcDIYType}
              handleIntCalcDIYType={handleIntCalcDIYType}
              handleCompoundingPeriod={handleCompoundingPeriod}
              interestCompPeriodType={interestCompPeriodType}
              handleIntPostPeriod={handleIntPostPeriod}
              interestPostPeriodType={interestPostPeriodType}
              handleLockedInFreq={handleLockedInFreq}
              lockinPeriodFrequency={lockinPeriodFrequency}
              handleLockinFreqType={handleLockinFreqType}
              lockinPeriodFreqType={lockinPeriodFreqType}
              handleOpeningBal={handleOpeningBal}
              minRequiredOpeningBalance={minRequiredOpeningBalance}
              handleMinimumBal={handleMinimumBal}
              minRequiredBalance={minRequiredBalance}
              overdraftLimit={overdraftLimit}
              minOverdraftForInterestCalculation={
                minOverdraftForInterestCalculation
              }
              nominalAnnualInterestRateOverdraft={
                nominalAnnualInterestRateOverdraft
              }
              handleOverDraftLimit={handleOverDraftLimit}
              handleMinOverdraftForInterestCalculation={
                handleMinOverdraftForInterestCalculation
              }
              handleNominalAnnualInterestRateOverdraft={
                handleNominalAnnualInterestRateOverdraft
              }
              allowOverdraft={overdraft}
              handleOverdraft={handleOverdraft}
              enforceMinReqBal={enforceMinReqBal}
              handleEnforceReqBal={handleEnforceReqBal}
              withdrawFeeForTrans={withdrawFeeForTrans}
              handleWithdrawalFee={handleWithdrawalFee}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              handleWithdrawalLimit={handleWithdrawalLimit}
              withdrawalLimit={withdrawals.withdrawalLimit}
              numberOfWithdrawals={withdrawals.numberOfWithdrawals}
            />
          </AppTerms>
        );
        break;
      case 2:
        currentComponet = (
          <AppCharges error={termsError || detailsError}>
            {/* SavingCharge */}
            <SavingCharge
              setChargesError={setChargesError}
              handleNextScreen={handleNextCustomIndexSelect}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              handleChargeId={handleCharge}
              charge={charge}
              // handleAddCharge={handleAddCharge}
              chargesArray={chargesArray}
              myChargesArray={myChargesArray}
              setMyChargesArray={setMyChargesArray}
              setChargesArray={setChargesArray}
              handleSelectedChargeDate={handleSelectedChargeDate}
              handleDeleteChargeData={handleDeleteChargeData}
              handleAmountChange={handleAmountChange}
              handleRepaymentsChange={handleRepaymentsChange}
              template={template}
            />
          </AppCharges>
        );
        break;
      case 3:
        currentComponet = (
          <AppReviews error={termsError || detailsError || chargesError}>
            <SavingReview
              setChargesError={setChargesError}
              handleSubmit={handleCreateSavingsSubmit}
              handlePreviousScreen={handlePreviousCustomIndexSelect}
              reviewData={dataHolder}
              reviewDataTwo={reviewData}
              myChargesArray={myChargesArray}
              withdrawals={withdrawals}
            />
          </AppReviews>
        );
        break;
      default:
    }
    return currentComponet;
  };

  // FUNCTION HERE FOR MOVING TO THE NEXT PAGE
  const handleNextCustomIndexSelect = () => {
    let newIndex = currentScreen + 1;
    if (newIndex <= 3) {
      setCurrentScreen(newIndex);
    }
  };
  // FUNCTION HERE FOR MOVING TO THE PREVIOUS PAGE
  const handlePreviousCustomIndexSelect = () => {
    let newIndex = currentScreen - 1;
    if (newIndex >= 0) {
      setCurrentScreen(newIndex);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row justify-content-between">
          <div className="col-sm-12 col-md-6 pb-3 mt-1">
            <h3 className="page-title">Savings Application</h3>
            <p className="text-muted sub-title">Create a new savings account</p>
            <p className="font-weight sub-title mt-2">
              <span className="text-info">
                <Link to="/core/clients">Customer</Link>
              </span>
              /{" "}
              <span className="text-info">
                <Link to={`/core/client/${match.params.id}`}>
                  {!!clientName ? clientName : ""}
                </Link>
              </span>
              / Account Application
            </p>
          </div>
        </div>
        <div className="row" style={{ minHeight: "calc(100vh - 250px)" }}>
          <div className="col-sm-12 col-md-12">
            <ScrollToTop>
              <ContentWrapper className="application-wrapper">
                {renderComponent()}
              </ContentWrapper>
            </ScrollToTop>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavingsApplicationScreen;
