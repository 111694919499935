export const FETCH_LOAN_COLLATERAL_TEMPLATE_REQUEST = 'FETCH_LOAN_COLLATERAL_TEMPLATE_REQUEST';
export const FETCH_LOAN_COLLATERAL_TEMPLATE_SUCCESS = 'FETCH_LOAN_COLLATERAL_TEMPLATE_SUCCESS';
export const FETCH_LOAN_COLLATERAL_TEMPLATE_FAIL = 'FETCH_LOAN_COLLATERAL_TEMPLATE_FAIL';

export const FETCH_LOAN_COLLATERALS_REQUEST = 'FETCH_LOAN_COLLATERALS_REQUEST';
export const FETCH_LOAN_COLLATERALS_SUCCESS = 'FETCH_LOAN_COLLATERALS_SUCCESS';
export const FETCH_LOAN_COLLATERALS_FAIL = 'FETCH_LOAN_COLLATERALS_FAIL';

export const ADD_COLLATERAL_TO_LOAN_REQUEST = 'ADD_COLLATERAL_TO_LOAN_REQUEST';
export const ADD_COLLATERAL_TO_LOAN_SUCCESS = 'ADD_COLLATERAL_TO_LOAN_SUCCESS';
export const ADD_COLLATERAL_TO_LOAN_FAIL = 'ADD_COLLATERAL_TO_LOAN_FAIL';

export const REMOVE_A_LOAN_COLLATERAL_REQUEST = 'REMOVE_A_LOAN_COLLATERAL_REQUEST';
export const REMOVE_A_LOAN_COLLATERAL_SUCCESS = 'REMOVE_A_LOAN_COLLATERAL_SUCCESS';
export const REMOVE_A_LOAN_COLLATERAL_FAIL = 'REMOVE_A_LOAN_COLLATERAL_FAIL';
