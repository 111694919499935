import styled from "styled-components";



export const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;
export const ActionButton = styled.div`
  height: 45px;
  min-width: 100px;
  background: #2C1DFF !important;
  margin: 0 5px;
  border: none;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: 'AventaMedium';
  color: #fff !important;
  a {
    color: #fff !important;
    font-size: 0.875rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const NewActionButton = styled.div`
  height: 35px;
  min-width: 90px;
  background: #2C1DFF !important;
  margin: 0 5px;
  border: none;
  border-radius: 5px !important;
  font-family: 'AventaMedium';
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  color: #fff !important;
  a {
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;

export const ContentWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 0px #ededf6;
  margin-top: 5px;
  /* overflow: hidden; */
  /* overflow-x: scroll; */
`;

export const ActionAnchorButton = styled.div`
  width: max-content !important;
  font-family: 'AventaMedium';
  margin: 0 2px;
  border: none;
  font-size: 13px;
  padding: 0 15px;
  border-radius: 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #2C1DFF !important;
  text-decoration: underline;
  a {
    width: max-content !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
`;

export const AdvSearchActionButton = styled.div`
  width: max-content !important;
  height: 35px;
  background: #fff !important;
  margin: 0 2px;
  border: 1px solid #2C1DFF;
  padding: 0 15px;
  border-radius: 5px !important;
  font-family: 'AventaMedium';
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #2C1DFF !important;
  a {
    width: max-content !important;
    color: #fff !important;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none !important;
    transition: box-shadow 0.15s ease-in-out, color 0.15s ease-in-out !important;
    img {
      height: 18px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    color: #fff;
  }
`;