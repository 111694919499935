const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const formatDate = (data, submit = false, dateCase) => {
  if (!data) {
    return "";
  }
  const date = new Date(data);
  let month = date.getMonth();
  let mainMonth = month + 1;
  const year = date.getFullYear();
  const monthStr = months[month];
  let dayN = date.getDate();
  let hour = date.getHours();
  let mins = date.getMinutes();
  let secs = date.getSeconds();

  if (dayN.toString().length === 1) {
    dayN = `0${dayN}`;
  }
  if (mainMonth.toString().length === 1) {
    mainMonth = `0${mainMonth}`;
  }
  if (hour.toString().length === 1) {
    hour = `0${hour}`;
  }
  if (mins.toString().length === 1) {
    mins = `0${mins}`;
  }

  let dateFormat = dateCase || localStorage.getItem("woodDateFormat");
  // console.log(dateFormat, 'dateFormat 1st')
  if (dateFormat && !submit) {
    // console.log(dateFormat, !submit, 'dateFormat 2nd')

    switch (dateFormat) {
      case "string:dd/MMMM/yyyy":
        return `${dayN}/${monthStr}/${year}`;
      case "string:dd MMMM yyyy":
        return `${dayN} ${monthStr} ${year}`;
      case "string:dd-MMMM-yyyy":
        return `${dayN}-${monthStr}-${year}`;
      case "string:MMMM-dd-yyyy":
        return `${monthStr}-${dayN}-${year}`;
      case "string:MMMM dd yyyy":
        return `${monthStr} ${dayN} ${year}`;
      case "string:MMMM/dd/yyyy":
        return `${monthStr}/${dayN}/${year}`;
      case "string:yyyy-MM-dd":
        return `${year}-${monthStr}-${dayN}`;
      case "string:yyyy-MMMM-dd":
        return `${year}-${mainMonth}-${dayN}`;
      case "string:dd/MMM/yyyy":
        return `${dayN}/${mainMonth}/${year}`;
      case "string:hh:mm:ssdd/MMM/yyyy":
        return `${hour}:${mins}:${secs} ${dayN}/${mainMonth}/${year}`;
      case "string:dd MMMM yyyy HH:mm":
        return `${dayN} ${monthStr} ${year} ${hour}:${mins}`;
      default:
        return `${dayN} ${monthStr} ${year}`;
    }
  } else {
    return `${dayN} ${monthStr} ${year}`;
  }
};

export const formatDateArr = (data) => {
  let array = Array.isArray(data);
  if (array) {
    const currentYear = new Date().getFullYear();
    let value = formatDate([...data, currentYear]);
    return new Date(value);
  } else if (Date.parse(data)) {
    return data;
  } else return;
};

export const formatMonth = (data, submit = false, dateCase) => {
  const date = new Date(data);
  let month = date.getMonth();
  const monthStr = months[month];
  let dayN = date.getDate();

  if (dayN.toString().length === 1) {
    dayN = `0${dayN}`;
  }

  if (month.toString().length === 1) {
    month = `0${month}`;
  }
  let dateFormat = dateCase || localStorage.getItem("woodDateFormat");
  if (dateFormat && !submit) {
    switch (dateFormat) {
      default:
        return `${dayN} ${monthStr}`;
    }
  } else {
    return `${dayN} ${monthStr}`;
  }
};

export const formatDateTime = (data) => {
  if (!data) {
    return "";
  }
  const dateValue = new Date(data)?.toISOString()?.slice(0, 20);
  const formatedDate = dateValue?.split("-").reverse().join("/");
  return formatedDate;
};

export const formatDateTimeNibss = (data) => {
  if (!data) {
    return "";
  }
  const dateValue = new Date(data);
  const dateStr =
    ("00" + (dateValue.getMonth() + 1)).slice(-2) +
    "/" +
    ("00" + dateValue.getDate()).slice(-2) +
    "/" +
    dateValue.getFullYear() +
    " " +
    ("00" + dateValue.getHours()).slice(-2) +
    ":" +
    ("00" + dateValue.getMinutes()).slice(-2);
  // ("00" + date.getSeconds()).slice(-2);
  return dateStr;
};

export const formatDateTimeTeller = (data) => {
  if (!data) {
    return "";
  }
   // split the string into date and time parts
   const [dateStr, timeStr] = data?.split('T');
  
   // remove the dot and everything after it from the time string
   const cleanTimeStr = timeStr?.split('.')[0];
   
   // replace the forward slashes with hyphens in the date string
   const cleanDateStr = dateStr?.replace(/\//g, '-');
   
   // concatenate the cleaned date and time strings and create a date object
   const date = new Date(`${cleanDateStr}T${cleanTimeStr}`);
   
   // extract the month, day, and year from the date object
   const month = date.getMonth() + 1; // getMonth() returns 0-based month, so add 1
   const day = date.getDate();
   const year = date.getFullYear().toString().slice(-2); // get last two digits of year
   
   // format the date string as mm/dd/yy
   const dateString = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
   
   // return the formatted date string
   return dateString;
};

export const formatTime = (data) => {
  const dateValue = new Date(data)?.toLocaleTimeString();
  return dateValue;
};

export const checkExpired = (data) => {
  const dateValue = new Date(data).toISOString().slice(0, 10);
  const formatedDate = dateValue?.split("-").reverse().join("/");
  const currentDate = new Date()?.toISOString().slice(0, 10);
  if (currentDate > formatedDate) {
    return "incative";
  } else return "active";
};

export const formatDateString = (data, dateCase) => {
  const date = new Date(data);
  let month = date.getMonth();
  let mainMonth = month + 1;
  const year = date.getFullYear();
  let dayN = date.getDate();
  if (dayN.toString().length === 1) {
    dayN = `0${dayN}`;
  }
  if (mainMonth.toString().length === 1) {
    mainMonth = `0${mainMonth}`;
  }
  let dateFormat = dateCase || localStorage.getItem("woodDateFormat");
  if (dateFormat && data) {
    switch (dateFormat) {
      case "yyyy-MMMM-dd":
        return `${year}-${mainMonth}-${dayN}`;
      case "dd/MMM/yyyy":
        return `${dayN}/${mainMonth}/${year}`;
      default:
        return `${dayN} ${mainMonth} ${year}`;
    }
  }
};

/**
 * Convert a date string to the "dd/mm/yyyy" format if needed.
 *
 * @param {string} dateString - The input date string.
 * @returns {string} The formatted date string in "dd/mm/yyyy" format.
 */
 export const formatDateReactDatePicker = (dateString) => {
  // Check if the input date string matches the "dd/mm/yyyy" format
  const dateRegex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
  const matches = dateString.match(dateRegex);

  if (matches) {
    // If it's already in the expected format, return it as is
    return dateString;
  } else {
    // Otherwise, convert the date from "01 Jun 2023" format to "dd/mm/yyyy" format
    const inputDate = new Date(dateString);
    const day = inputDate.getDate().toString().padStart(2, '0');
    const month = (inputDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const year = inputDate.getFullYear();
    return `${day}/${month}/${year}`;
  }
}

/**
 * Convert a date string to a Date object.
 *
 * @param {string} dateString - The input date string in various formats.
 * @returns {Date} The Date object representing the converted date.
 * @throws {Error} Throws an error if the input date string cannot be parsed.
 */
 export const convertToDateObject = (dateString = new Date()) => {
  // Attempt to create a Date object from the input string
  const date = new Date(dateString);

  // Check if the Date object is invalid or NaN (indicating a parsing error)
  if (isNaN(date)) {
    throw new Error('Invalid date format');
  }

  // Set the time to noon (12:00 PM) to avoid timezone issues
  date.setHours(12, 0, 0, 0);

  return date;
}

export const convertToDateString = (date) => {
  return date ? (new Date(date)).toDateString() : '-- -- --';
}

export function formatDateChecker(submittedDate) {
  if (submittedDate) {
    const day = submittedDate[2];
    const month = months[submittedDate[1] - 1];
    const year = submittedDate[0];
    return `${day} ${month} ${year}`;
  }
}

/**
 * Formats a date from a provided date array (YYYY, MM, DD) into a YYYY-MM-DD string.
 * Pads single-digit months and days with leading zeros for consistent formatting.
 *
 * @param {number[]} dateArray - An array containing the year (index 0), month (index 1, 0-indexed), and day (index 2).
 * @returns {string} The formatted date string in YYYY-MM-DD format.
 * @throws {TypeError} - If the provided dateArray is not an array of length 3 or contains non-numeric values.
 */
export const formatDateForInput = (dateArray) => {
  if (!Array.isArray(dateArray) || dateArray.length !== 3 || !dateArray.every(x => typeof x === 'number')) {
    throw new TypeError('dateArray must be an array of length 3 containing numbers');
  }

  const year = dateArray[0];
  const month = String(dateArray[1]).padStart(2, '0'); // More concise padding
  const day = String(dateArray[2]).padStart(2, '0');

  return `${year}-${month}-${day}`;
}
