import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { ActionButton } from "../../styles.jsx";
import { formatDate } from "../../../../../util/FormatDate";
import Toast from "../../../../../util/Toast";
import { approveLoanReschedule, getLoanDetails } from "../../../../../actions/SavingsAndLoanActions.js";

const ApproveReschedule = ({ loanId, id, onHide, changeCurrentView }) => {
	const dispatch = useDispatch();

	const [approvedOnDate, setApprovedOnDate] = useState(Date.now());

	const callback = () => {
		onHide();
		changeCurrentView('default');
		dispatch(getLoanDetails(loanId));
	};
	const approveReschedule = () => {
		if (approvedOnDate) {
			const dataToSubmit = {
				approvedOnDate: formatDate(approvedOnDate, true),
				dateFormat: "dd MMM yyyy",
				locale: "en",
			};
			dispatch(approveLoanReschedule(id, dataToSubmit, callback));
		} else {
			dispatch(Toast({ text: "Please fill the required field", icon: "info" }));
		}
	};

	return (
		<>
			<Modal.Header>
				<Modal.Title>Approve loan reschedule</Modal.Title>
			</Modal.Header>
			<Modal.Body className="d-flex justify-content-center flex-column px-5">
				<label className="fs-14">Approved on*</label>
				<div className="datepicker-100-width">
					<DatePicker
						id="published-date"
						selected={approvedOnDate}
						onChange={(date) => setApprovedOnDate(date)}
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						placeholderText="dd/mmm/yyyy"
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className="float-right d-flex gap-4">
					<ActionButton color="#2C1DFF" onClick={onHide}>
						<img
							src={
								require("../../../../../assets/images/icons/cancel.svg")
									.default
							}
							alt="cancel icon"
						/>
						Cancel
					</ActionButton>
					<ActionButton
						color="#2C1DFF"
						onClick={approveReschedule}
					>
						<img
							src={
								require("../../../../../assets/images/icons/save.svg")
									.default
							}
							alt="submit icon"
						/>
						Submit
					</ActionButton>
				</div>
			</Modal.Footer>
		</>
	);
};

export default ApproveReschedule;
