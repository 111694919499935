import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackSvg from "../../../../../assets/images/icons/back.svg";
import { ContentWrapper, IconButton } from "../styles";
import { getLoanDetails } from "../../../../../actions/SavingsAndLoanActions";
import { Table } from "antd";
import { formatCurrency } from "../../../../../util/FormatAmount";
import ModalContainer from "../../../components/ModalContainer";
import { DeleteGuarantor } from "./delete";
import { Table as BootstrapTable } from "react-bootstrap";
import { fetchCollateralsAssociatedWithALoan } from "../../../../../actions/loanCollateralActions";
import CheckPermission from "../../../../../util/CheckPermission";
import { formatDate } from "../../../../../util/FormatDate";

const ViewCollateral = ({ currentId, setCurrentView }) => {
  const dispatch = useDispatch();

	const { loanCollaterals } = useSelector((state) => state.loanCollateralReducer);
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);
  let { loanDetails } = savingsAndLoan || {};
  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

  const [collateralId, setCollateralId] = useState(null);
  const [actionValue, setActionValue] = useState("");
  const [modalState, toggleModal] = useState(false);

  let {
    loanProductName,
    accountNo,
    externalId,
    loanOfficerName,
    linkedAccount,
    approvedPrincipal = 0,
    timeline = {},
    status = {},
    currency = {},
    repaymentSchedule = {},
    summary = {},
  } = loanDetails || {};

  let { periods = [], totalPrincipalDisbursed = 0 } = repaymentSchedule || {};

  let { expectedDisbursementDate = [] } = timeline || {};

  let mainPrincipalOutstanding = 0;

  periods.forEach((period) => {
    let { principalOutstanding = 0 } = period || {};
    // For Principal
    mainPrincipalOutstanding += principalOutstanding;
  });

  useEffect(() => {
    dispatch(fetchCollateralsAssociatedWithALoan(currentId));
    dispatch(getLoanDetails(currentId));
  }, [currentId]);

  const columns = status.pendingApproval ? [
    { title: "S/N", dataIndex: "serialNo", key: "serialNo" },
    { title: "Collateral Type", dataIndex: "collateralType", key: "collateralType" },
    { title: "Value", dataIndex: "value", key: "value" },
    { title: "Description", dataIndex: "description", key: "description" },
    { title: "", dataIndex: "action", key: "action" },
  ] : [
    { title: "S/N", dataIndex: "serialNo", key: "serialNo" },
    { title: "Collateral Type", dataIndex: "collateralType", key: "collateralType" },
    { title: "Value", dataIndex: "value", key: "value" },
    { title: "Description", dataIndex: "description", key: "description" },
  ];

  const displayModalContent = () => {
    switch (actionValue) {
      case "delete":
        return (
          <DeleteGuarantor
						loanId={currentId}
						collateralId={collateralId}
            handleCloseModal={handleCloseModal}
          />
        );
      default:
        break;
    }
  };

  const handleCloseModal = () => {
    toggleModal(false);
    return setActionValue("");
  };

  const handleClick = (value) => {
    setActionValue(value);
    return toggleModal(true);
  };

  let collaterals = [];

  loanCollaterals?.forEach((collateral, index) => {
		collaterals.push({
			key: collateral?.id,
			id: collateral?.id,
			serialNo: index + 1,
			collateralType: collateral?.type?.name,
			value: `${collateral?.currency?.code} ${formatCurrency(collateral?.value)}`,
			description: collateral?.description,
			action: CheckPermission("DELETE_COLLATERAL", permissions) && status.pendingApproval ? (
				<IconButton onClick={() => handleClick("delete")} className="mr-1 mb-1">
					<img
						src={
							require("../../../../../assets/images/icons/delete.svg")
								.default
						}
						alt="remove collateral"
						className="icon-img"
					/>
				</IconButton>
			) : <></>,
		});
	});

  return (
    <ContentWrapper>
      <div className="row">
        <div className="col-xl-6 col-md-6">
          <p onClick={() => setCurrentView(2)} className="back-btn d-flex">
            <img className="mr-2" src={BackSvg} alt="" />
            Back
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12 col-md-12">
          <div className="spaceBetweenFlexEnd"></div>
        </div>
      </div>
      <div className="row ml-0 mt-3">
        <div className="col">
          <p className="account-view-gua-details-main">
            {!!loanProductName ? loanProductName : ""}
          </p>
          <p className="account-view-gua-details">
            <span className="headerSpan-view-gua">Loan account</span>:{" "}
            {!!accountNo ? accountNo : ""}
          </p>
          <p className="account-view-gua-details">
            <span className="headerSpan-view-gua">Saving account</span>:{" "}
            {!!linkedAccount?.accountNo ? linkedAccount?.accountNo : ""}
          </p>
          {status.active ? (
            <p className="account-view-gua-details">
              <span className="headerSpan-view-gua">Loan Balance</span>:{" "}
              {formatCurrency(mainPrincipalOutstanding)}
            </p>
          ) : null}{" "}
          {status.active ? (
            <p className="account-view-gua-details">
              <span className="headerSpan-view-gua">Arrears By</span>:{" "}
              {formatCurrency(summary.totalOverdue)}
            </p>
          ) : null}
        </div>
      </div>

      <div className="row">
        <BootstrapTable bordered className="table-form-style col-md-5 m-4">
          <tbody>
            {status.active && !!expectedDisbursementDate.length ? (
              <tr>
                <td className="font-weight-bold md-font-weight">
                  Disbursement Date
                </td>
                <td>
                  {expectedDisbursementDate.length ? formatDate(expectedDisbursementDate) : ""}
                </td>
              </tr>
            ) : null}

            <tr>
              <td className="font-weight-bold md-font-weight">Loan Status</td>
              <td>{status.value}</td>
            </tr>

            <tr>
              <td className="font-weight-bold md-font-weight">Loan Officer</td>
              <td>{!!loanOfficerName ? loanOfficerName : "Not Assigned"}</td>
            </tr>

            <tr>
              <td className="font-weight-bold md-font-weight">External Id</td>
              <td>{!!externalId ? externalId : "Non Assigned"}</td>
            </tr>
          </tbody>
        </BootstrapTable>

        <BootstrapTable bordered className="table-form-style col-md-5 m-4">
          <tbody>
            {status.active && !!currency.displayLabel ? (
              <tr>
                <td className="font-weight-bold md-font-weight">Currency</td>
                <td>{!!currency.displayLabel ? currency.displayLabel : ""}</td>
              </tr>
            ) : null}
            <tr>
              <td className="font-weight-bold md-font-weight">Loan Purpose</td>
              <td>Not Provided</td>
            </tr>
            <tr>
              <td className="font-weight-bold md-font-weight">
                Approved Amount
              </td>
              <td>{formatCurrency(approvedPrincipal)}</td>
            </tr>

            <tr>
              <td className="font-weight-bold md-font-weight">
                Disburse Amount
              </td>
              <td>{formatCurrency(totalPrincipalDisbursed)}</td>
            </tr>
          </tbody>
        </BootstrapTable>
      </div>

      <div>
        <ModalContainer size="md" show={modalState}>
          {modalState && displayModalContent()}
        </ModalContainer>
        <div className="mt-1">
          <Table
            style={{
              overflow: "hidden",
              overflowX: "scroll",
            }}
            columns={columns}
            dataSource={collaterals}
            pagination={{ defaultPageSize: 5 }}
            defaultPageSize={5}
            onRow={(row) => ({
              onClick: () => setCollateralId(row?.id)
            })}
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default ViewCollateral;
