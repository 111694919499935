import {
  SET_ALL_REPORTS,
  GET_ALL_REPORTS_PARAMETERS,
  GET_ALL_REPORTS_FULL_PARAMETERS,
  GENERATED_REPORT,
  GET_ALL_OFFICES,
  GET_ALL_STAFF_SELECTS,
  GET_ALL_STAFFS,
  GET_ALL_CURRENCIES,
  GET_LOAN_PRODUCT,
  GET_SAVINGS_PRODUCT,
  GET_LOAN_PURPOSE,
  GET_LOAN_FUND,
  GET_REPORT_PAYMENT_TYPES,
  GET_REPORT_STATUS,
  GENERATING_REPORTS,
  SET_GENERATING_REPORTS,
  GET_PAR_SELECT,
  GET_REPORT_ACCOUNT,
  GET_REPORT_LOAN_OBLIGATIONS,
  GET_ACCOUNT_OFFICER_SELECTS,
  GET_ALL_CLIENT_SELECTS,
  GET_ALL_STAFF_SELECT_BRANCHS,
  ALL_STAFF_SELECT_RESET
} from "../constants/reportsConstants";

const initialState = {
  allReports: [],
  reportOffices: {},
  reportStaffSelects :{},
  reportClientSelects : {}, 
  reportAccountOfficerSelects : {},
  reportStaffSelectBranchs : {},
  reportParameters: {},
  reportFullParameters: {},
  generatedReport: {},
  reportStaffs: {},
  reportCurriencies: {},
  reportLoanProducts: {},
  reportSavingsProducts: {},
  reportLoanPurpose: {},
  reportLoanFund: {},
  reportLoanObligation: {},
  reportParSelect: {},
  reportPaymentTypes: {},
  reportStatus: {},
  reportAccount: {},
  generatingReports: [],
};

function setReportsReducers(state = initialState, action) {
  switch (action.type) {
    case SET_ALL_REPORTS: {
      return {
        ...state,
        allReports: action.payload,
      };
    }
    case GET_ALL_REPORTS_PARAMETERS: {
      return {
        ...state,
        reportParameters: action.payload,
      };
    }
    case GET_ALL_REPORTS_FULL_PARAMETERS: {
      return {
        ...state,
        reportFullParameters: action.payload,
      };
    }
    case GENERATED_REPORT: {
      return {
        ...state,
        generatedReport: action.payload,
      };
    }
    case GET_ALL_OFFICES: {
      return {
        ...state,
        reportOffices: action.payload,
      };
    }
    case GET_ALL_STAFF_SELECTS: {
      return {
        ...state,
        reportStaffSelects: action.payload,
      };
    }
    case GET_ALL_STAFF_SELECT_BRANCHS: {
      return {
        ...state,
        reportStaffSelectBranchs: action.payload,
      };
    }
    case ALL_STAFF_SELECT_RESET: {
      return {
        ...state,
        reportStaffSelectBranchs: null,
        error:null
      };
    }
    case GET_ACCOUNT_OFFICER_SELECTS: {
      return {
        ...state,
        reportAccountOfficerSelects: action.payload,
      };
    }
    case GET_ALL_CLIENT_SELECTS: {
      return {
        ...state,
        reportClientSelects: action.payload,
      };
    }

    case GET_ALL_STAFFS: {
      return {
        ...state,
        reportStaffs: action.payload,
      };
    }
    case GET_ALL_CURRENCIES: {
      return {
        ...state,
        reportCurriencies: action.payload,
      };
    }
    case GET_LOAN_PRODUCT: {
      return {
        ...state,
        reportLoanProducts: action.payload,
      };
    }
    case GET_SAVINGS_PRODUCT: {
      return {
        ...state,
        reportSavingsProducts: action.payload,
      };
    }
    case GET_LOAN_PURPOSE: {
      return {
        ...state,
        reportLoanPurpose: action.payload,
      };
    }
    case GET_REPORT_LOAN_OBLIGATIONS: {
      return {
        ...state,
        reportLoanObligation: action.payload,
      };
    }
    case GET_LOAN_FUND: {
      return {
        ...state,
        reportLoanFund: action.payload,
      };
    }
    case GET_REPORT_ACCOUNT: {
      return {
        ...state,
        reportAccount: action.payload,
      };
    }
    case GET_REPORT_PAYMENT_TYPES: {
      return {
        ...state,
        reportPaymentTypes: action.payload,
      };
    }
    case GET_PAR_SELECT: {
      return {
        ...state,
        reportParSelect: action.payload,
      };
    }
    case GET_REPORT_STATUS: {
      return {
        ...state,
        reportStatus: action.payload,
      };
    }
    case SET_GENERATING_REPORTS: {
      return {
        ...state,
        generatingReports: action.payload,
      };
    }
    case GENERATING_REPORTS: {
      return {
        ...state,
        generatingReports: [...state.generatingReports, action.payload],
      };
    }
    default: {
      return state;
    }
  }
}

export default setReportsReducers;
