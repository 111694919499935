import * as _ from '../constants/loanCollateralConstant';
import { SET_LOADING_STATE } from '../constants/utilityConstants';
import { deleteCollateral, getLoanCollateralTemplate, getLoanCollaterals, updatePendingLoanWithCollaterals } from '../services/Api/collaterals.loan.api.service';
import Toast from '../util/Toast';

// HANDLE ERROR
const handleError = (ex, dispatch) => {
  if (ex.response) {
    dispatch(
      Toast({
        text: ex.response.data.errors
          ? ex.response.data.errors[0].defaultUserMessage
          : ex.response.data.defaultUserMessage,
        icon: "error",
      })
    );
  } else if (ex.request) {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  } else {
    dispatch(Toast({ text: String(ex), icon: "error" }));
  }

  dispatch({ type: SET_LOADING_STATE, payload: false });
};

// FETCH LOAN COLLATERAL TEMPLATE
export const fetchCollateralTemplate = (id) => {
  return async (dispatch) => {
    dispatch({ type: _.FETCH_LOAN_COLLATERAL_TEMPLATE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getLoanCollateralTemplate(id)
      .then((response) => {
        dispatch({ type: _.FETCH_LOAN_COLLATERAL_TEMPLATE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch({ type: _.FETCH_LOAN_COLLATERAL_TEMPLATE_FAIL, payload: ex });
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

// FETCH LOAN COLLATERALS
export const fetchCollateralsAssociatedWithALoan = (loanId) => {
  return async (dispatch) => {
    dispatch({ type: _.FETCH_LOAN_COLLATERALS_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await getLoanCollaterals(loanId)
      .then((response) => {
        dispatch({ type: _.FETCH_LOAN_COLLATERALS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch({ type: _.FETCH_LOAN_COLLATERALS_FAIL, payload: ex });
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

// ADD COLLATERALS TO A PENDING LOAN
export const addCollateralsToAPendingLoan = (loanId, data, callback) => {
  return async (dispatch) => {
    dispatch({ type: _.ADD_COLLATERAL_TO_LOAN_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await updatePendingLoanWithCollaterals(loanId, data)
      .then((response) => {
        dispatch({ type: _.ADD_COLLATERAL_TO_LOAN_SUCCESS, payload: response });
        dispatch(Toast({
          text: `Collateral${data?.collateral?.length > 1 ? 's' : ''} added successfully`,
          icon: "success"
        }));
        callback?.();
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch({ type: _.ADD_COLLATERAL_TO_LOAN_FAIL, payload: ex });
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};

// REMOVE A LOAN COLLATERAL
export const removeALoanCollateral = (loanId, collateralId, callback) => {
  return async (dispatch) => {
    dispatch({ type: _.REMOVE_A_LOAN_COLLATERAL_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await deleteCollateral(loanId, collateralId)
      .then((response) => {
        dispatch({ type: _.REMOVE_A_LOAN_COLLATERAL_SUCCESS, payload: response });
        dispatch(Toast({
          text: "Collateral deleted successfully",
          icon: "success"
        }));
        callback?.();
      })
      .catch((ex) => {
        handleError(ex, dispatch);
        dispatch({ type: _.REMOVE_A_LOAN_COLLATERAL_FAIL, payload: ex });
      })
      .finally(() => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
      });
  };
};