export const SET_SAVINGS_ACCOUNT_DETAILS = "SET_SAVINGS_ACCOUNT_DETAILS";
export const SET_SAVINGS_ACCOUNT_TEMPLATE = "SET_SAVINGS_ACCOUNT_TEMPLATE";
export const SET_LOAN_DETAILS = "SET_LOAN_DETAILS";
export const SET_LOAN_DOCUMENT = "SET_LOAN_DOCUMENT";
export const SET_LOAN_TRANSACTIONS = "SET_LOAN_TRANSACTIONS";
export const SET_SAVINGS_TRANSACTIONS = "SET_SAVINGS_TRANSACTIONS";
export const SET_SAVINGS_EVENTS = "SET_SAVINGS_EVENTS";
export const SET_OPTIONS_EVENTS = "SET_OPTIONS_EVENTS";
export const SET_CUSTOMER_OPTIONS = "SET_CUSTOMER_OPTIONS";
export const SET_LOAN_ACCOUNT_TEMPLATE = "SET_LOAN_ACCOUNT_TEMPLATE";
export const SET_LOAN_DISBURSE_DETAILS = "SET_LOAN_DISBURSE_DETAILS";
export const SET_REPAY_LOAN_TEMPLATE = "SET_REPAY_LOAN_TEMPLATE";
export const SHOW_TRANSFER_MODAL_SUCCESSFUL = "SHOW_TRANSFER_MODAL_SUCCESSFUL";
export const SHOW_MULTIPLE_TRANSFER_MODAL_SUCCESSFUL = "SHOW_MULTIPLE_TRANSFER_MODAL_SUCCESSFUL";
export const SET_RESCHEDULE_LOAN_TEMPLATE = "SET_RESCHEDULE_LOAN_TEMPLATE";
export const SET_RESCHEDULE_LOAN_DETAILS = "SET_RESCHEDULE_LOAN_DETAILS";
export const SET_TRANSFER_SAVINGS_ACCOUNT_TEMPLATE =
  "SET_TRANSFER_SAVINGS_ACCOUNT_TEMPLATE";
export const SET_SAVINGS_ACCOUNT_TRANSACTION_TEMPLATE =
  "SET_SAVINGS_ACCOUNT_TRANSACTION_TEMPLATE";
export const SET_SAVINGS_ACCOUNT_CHARGE_TEMPLATE =
  "SET_SAVINGS_ACCOUNT_CHARGE_TEMPLATE";
export const SET_SAVINGS_ACCOUNT_SMS_NOTIFICATIONS =
  "SET_SAVINGS_ACCOUNT_SMS_NOTIFICATIONS";
export const SET_SAVINGS_ACCOUNT_EMAIL_NOTIFICATIONS =
  "SET_SAVINGS_ACCOUNT_EMAIL_NOTIFICATIONS";
export const SET_SAVINGS_ACCOUNT_AUDIT = "SET_SAVINGS_ACCOUNT_AUDIT";
export const SET_LOAN_ACCOUNT_AUDIT = "SET_LOAN_ACCOUNT_AUDIT";
export const SET_LOAN_ACCOUNT_CHARGES = "SET_LOAN_ACCOUNT_CHARGES";
export const SET_LOAN_ACCOUNTS_CHARGE_TEMPLATE = "SET_LOAN_ACCOUNTS_CHARGE_TEMPLATE";
export const SET_SAVINGS_CHART = "SET_SAVINGS_CHART";
export const SET_LOAN_CHART = "SET_LOAN_CHART";
export const UPDATE_CHARGES = 'UPDATE_CHARGES';
export const WAIVE_CHARGES = 'WAIVE_CHARGES';

export const LOAN_ACCOUNT_FAIL = "LOAN_ACCOUNT_FAIL "
export const  LOAN_ACCOUNT_SUCCESS = "LOAN_ACCOUNT_SUCCESS "
export const  LOAN_ACCOUNT_REQUEST = "LOAN_ACCOUNT_REQUEST "
export const  LOAN_ACCOUNT_RESET = "LOAN_ACCOUNT_RESET "

export const UPDATE_LOAN_CHARGES = 'UPDATE_LOAN_CHARGES';
export const WAIVE_LOAN_CHARGES = 'WAIVE_LOAN_CHARGES';

export const  ADD_CHARGE_TO_LOAN_ACCOUNT = "ADD_CHARGE_TO_LOAN_ACCOUNT_SUCCESS"
// export const  ADD_CHARGE_TO_LOAN_ACCOUNT_FAIL = "ADD_CHARGE_TO_LOAN_ACCOUNT_FAIL"

export const  UPDATE_LOAN_ACCOUNT_REQUEST = "UPDATE_LOAN_ACCOUNT_REQUEST "
export const  UPDATE_LOAN_ACCOUNT_SUCCESS = " UPDATE_LOAN_ACCOUNT_SUCCESS"
export const  UPDATE_LOAN_ACCOUNT_FAIL = "UPDATE_LOAN_ACCOUNT_FAIL "

export const SET_LOAN_GUARANTOR_TEMPLATE = "SET_LOAN_GUARANTOR_TEMPLATE";
export const DELETE_LOAN_GUARANTOR_FAIL = "DELETE_LOAN_GUARANTOR_FAIL";
export const DELETE_LOAN_GUARANTOR_SUCCESS = "DELETE_LOAN_GUARANTOR_SUCCESS";
export const DELETE_LOAN_GUARANTOR_REQUEST = "DELETE_LOAN_GUARANTOR_REQUEST";

export const RECOVER_LOAN_GUARANTOR_REQUEST = "RECOVER_LOAN_GUARANTOR_REQUEST";
export const RECOVER_LOAN_GUARANTOR_SUCCESS = "RECOVER_LOAN_GUARANTOR_SUCCESS";
export const RECOVER_LOAN_GUARANTOR_FAIL = "RECOVER_LOAN_GUARANTOR_FAIL";

export const DOWNLOAD_TRANSACTION_RECEIPT = "DOWNLOAD_TRANSACTION_RECEIPT";
export const DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS =
  "DOWNLOAD_TRANSACTION_RECEIPT_SUCCESS";
export const DOWNLOAD_TRANSACTION_RECEIPT_ERROR =
  "DOWNLOAD_TRANSACTION_RECEIPT_ERROR";
export const DOWNLOAD_TRANSACTION_RECEIPT_RESET =
  "DOWNLOAD_TRANSACTION_RECEIPT_RESET";

export const REPAYMENT_SCHEDULE_REQUEST = 'REPAYMENT_SCHEDULE_REQUEST';
export const REPAYMENT_SCHEDULE_SUCCESS = 'REPAYMENT_SCHEDULE_SUCCESS';
export const REPAYMENT_SCHEDULE_FAIL = 'REPAYMENT_SCHEDULE_FAIL';

export const EDIT_REPAYMENT_SCHEDULE_REQUEST = 'EDIT_REPAYMENT_SCHEDULE_REQUEST';
export const EDIT_REPAYMENT_SCHEDULE_SUCCESS = 'EDIT_REPAYMENT_SCHEDULE_SUCCESS';
export const EDIT_REPAYMENT_SCHEDULE_FAIL = 'EDIT_REPAYMENT_SCHEDULE_FAIL';

export const FETCH_LOAN_NOTES_REQUEST = "FETCH_LOAN_NOTES_REQUEST";
export const FETCH_LOAN_NOTES_FAIL = "FETCH_LOAN_NOTES_FAIL";
export const FETCH_LOAN_NOTES_SUCCESS = "FETCH_LOAN_NOTES_SUCCESS";

export const CREATE_LOAN_NOTE_REQUEST = "CREATE_LOAN_NOTE_REQUEST";
export const CREATE_LOAN_NOTE_FAIL = "CREATE_LOAN_NOTE_FAIL";
export const CREATE_LOAN_NOTE_SUCCESS = "CREATE_LOAN_NOTE_SUCCESS";

export const UPDATE_LOAN_NOTE_REQUEST = "UPDATE_LOAN_NOTE_REQUEST";
export const UPDATE_LOAN_NOTE_FAIL = "UPDATE_LOAN_NOTE_FAIL";
export const UPDATE_LOAN_NOTE_SUCCESS = "UPDATE_LOAN_NOTE_SUCCESS";

export const DELETE_LOAN_NOTE_REQUEST = "DELETE_LOAN_NOTE_REQUEST";
export const DELETE_LOAN_NOTE_FAIL = "DELETE_LOAN_NOTE_FAIL";
export const DELETE_LOAN_NOTE_SUCCESS = "DELETE_LOAN_NOTE_SUCCESS";

export const FETCH_SAVINGS_NOTES_REQUEST = "FETCH_SAVINGS_NOTES_REQUEST";
export const FETCH_SAVINGS_NOTES_FAIL = "FETCH_SAVINGS_NOTES_FAIL";
export const FETCH_SAVINGS_NOTES_SUCCESS = "FETCH_SAVINGS_NOTES_SUCCESS";

export const CREATE_SAVINGS_NOTE_REQUEST = "CREATE_SAVINGS_NOTE_REQUEST";
export const CREATE_SAVINGS_NOTE_FAIL = "CREATE_SAVINGS_NOTE_FAIL";
export const CREATE_SAVINGS_NOTE_SUCCESS = "CREATE_SAVINGS_NOTE_SUCCESS";

export const UPDATE_SAVINGS_NOTE_REQUEST = "UPDATE_SAVINGS_NOTE_REQUEST";
export const UPDATE_SAVINGS_NOTE_FAIL = "UPDATE_SAVINGS_NOTE_FAIL";
export const UPDATE_SAVINGS_NOTE_SUCCESS = "UPDATE_SAVINGS_NOTE_SUCCESS";

export const DELETE_SAVINGS_NOTE_REQUEST = "DELETE_SAVINGS_NOTE_REQUEST";
export const DELETE_SAVINGS_NOTE_FAIL = "DELETE_SAVINGS_NOTE_FAIL";
export const DELETE_SAVINGS_NOTE_SUCCESS = "DELETE_SAVINGS_NOTE_SUCCESS";
