import API_CONFIGURATION from "../services/Api/configuration.api.services";
import {
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  FILTER_USERS_REQUEST,
  FILTER_AUDIT_USERS_REQUEST,
  CLEAR_AUDIT_USERS_FILTER,
  CLEAR_FILTER,
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAIL,
  CREATE_NEW_USER_RESET,
  ALL_USERS_TEMPLATE_REQUEST,
  ALL_USERS_TEMPLATE_SUCCESS,
  ALL_USERS_TEMPLATE_FAIL,
  ALL_OFFICE_STAFFS_REQUEST,
  ALL_OFFICE_STAFFS_SUCCESS,
  ALL_OFFICE_STAFFS_FAIL,
  ALL_OFFICE_STAFFS_RESET,
  ALL_OFFICES_REQUEST,
  ALL_OFFICES_SUCCESS,
  ALL_OFFICES_FAIL,
  FILTER_OFFICES_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CLEAR_OFFICES_FILTER,
  UPDATE_OFFICE_REQUEST,
  UPDATE_OFFICE_SUCCESS,
  UPDATE_OFFICE_FAIL,
  CREATE_NEW_OFFICE_REQUEST,
  CREATE_NEW_OFFICE_SUCCESS,
  CREATE_NEW_OFFICE_FAIL,
  CREATE_NEW_OFFICE_RESET,
  ALL_CURRENCY_REQUEST,
  ALL_CURRENCY_SUCCESS,
  ALL_CURRENCY_FAIL,
  CREATE_NEW_CURRENCY_REQUEST,
  CREATE_NEW_CURRENCY_SUCCESS,
  CREATE_NEW_CURRENCY_FAIL,
  ALL_EMPLOYEES_REQUEST,
  ALL_EMPLOYEES_SUCCESS,
  ALL_EMPLOYEES_FAIL,
  FILTER_EMPLOYEES_REQUEST,
  CLEAR_EMPLOYEES_FILTER,
  CREATE_NEW_EMPLOYEE_REQUEST,
  CREATE_NEW_EMPLOYEE_SUCCESS,
  CREATE_NEW_EMPLOYEE_FAIL,
  CREATE_NEW_EMPLOYEE_RESET,
  UPDATE_EMPLOYEE_REQUEST,
  UPDATE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_FAIL,
  ALL_ENTITY_DATA_TABLE_REQUEST,
  ALL_ENTITY_DATA_TABLE_SUCCESS,
  ALL_ENTITY_DATA_TABLE_FAIL,
  FILTER_ENTITY_DATA_TABLE_REQUEST,
  CLEAR_ENTITY_DATA_TABLE_FILTER,
  CREATE_NEW_ENTITY_DATA_TABLE_REQUEST,
  CREATE_ENTITY_DATA_TABLE_SUCCESS,
  CREATE_ENTITY_DATA_TABLE_FAIL,
  CREATE_ENTITY_DATA_TABLE_RESET,
  DELETE_ENTITY_DATA_TABLE_REQUEST,
  DELETE_ENTITY_DATA_TABLE_SUCCESS,
  DELETE_ENTITY_DATA_TABLE_FAIL,
  ALL_ENTITY_DATA_TABLE_TEMPLATE_REQUEST,
  ALL_ENTITY_DATA_TABLE_TEMPLATE_SUCCESS,
  ALL_ENTITY_DATA_TABLE_TEMPLATE_FAIL,
  ALL_HOLIDAYS_REQUEST,
  ALL_HOLIDAYS_SUCCESS,
  ALL_HOLIDAYS_FAIL,
  FILTER_HOLIDAYS_REQUEST,
  CLEAR_HOLIDAYS_FILTER,
  ALL_HOLIDAYS_TEMPLATE_REQUEST,
  ALL_HOLIDAYS_TEMPLATE_SUCCESS,
  ALL_HOLIDAYS_TEMPLATE_FAIL,
  CREATE_NEW_HOLIDAY_REQUEST,
  CREATE_NEW_HOLIDAY_SUCCESS,
  CREATE_NEW_HOLIDAY_FAIL,
  CREATE_NEW_HOLIDAY_RESET,
  DELETE_HOLIDAY_REQUEST,
  DELETE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_FAIL,
  ACTIVATE_HOLIDAY_REQUEST,
  ACTIVATE_HOLIDAY_SUCCESS,
  ACTIVATE_HOLIDAY_FAIL,
  UPDATE_HOLIDAY_REQUEST,
  UPDATE_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY_FAIL,
  ALL_WORKING_DAYS_REQUEST,
  ALL_WORKING_DAYS_SUCCESS,
  ALL_WORKING_DAYS_FAIL,
  CREATE_NEW_WORKING_DAYS_REQUEST,
  CREATE_NEW_WORKING_DAYS_SUCCESS,
  CREATE_NEW_WORKING_DAYS_FAIL,
  CREATE_NEW_WORKING_DAYS_RESET,
  ALL_STANDING_INSTRUCTION_TEMPLATE_REQUEST,
  ALL_STANDING_INSTRUCTION_TEMPLATE_SUCCESS,
  ALL_STANDING_INSTRUCTION_TEMPLATE_FAIL,
  ALL_STANDING_INSTRUCTION_REQUEST,
  ALL_STANDING_INSTRUCTION_SUCCESS,
  ALL_STANDING_INSTRUCTION_FAIL,
  ALL_PAYMENT_TYPE_REQUEST,
  ALL_PAYMENT_TYPE_SUCCESS,
  ALL_PAYMENT_TYPE_FAIL,
  FILTER_PAYMENT_TYPE_REQUEST,
  CLEAR_PAYMENT_TYPE_FILTER,
  CREATE_NEW_PAYMENT_TYPE_REQUEST,
  CREATE_NEW_PAYMENT_TYPE_SUCCESS,
  UPDATE_PAYMENT_TYPE_SUCCESS,
  UPDATE_PAYMENT_TYPE_REQUEST,
  UPDATE_PAYMENT_TYPE_FAIL,
  CREATE_NEW_PAYMENT_TYPE_FAIL,
  DELETE_PAYMENT_TYPE_REQUEST,
  DELETE_PAYMENT_TYPE_SUCCESS,
  DELETE_PAYMENT_TYPE_FAIL,
  ALL_SAVINGS_PRODUCTS_REQUEST,
  ALL_SAVINGS_PRODUCTS_SUCCESS,
  ALL_SAVINGS_PRODUCTS_FAIL,
  FILTER_SAVINGS_PRODUCTS_REQUEST,
  CLEAR_SAVINGS_PRODUCTS_FILTER,
  ALL_SAVINGS_PRODUCTS_TEMPLATE_REQUEST,
  ALL_SAVINGS_PRODUCTS_TEMPLATE_SUCCESS,
  ALL_SAVINGS_PRODUCTS_TEMPLATE_FAIL,
  CREATE_NEW_SAVINGS_PRODUCT_REQUEST,
  CREATE_NEW_SAVINGS_PRODUCT_SUCCESS,
  CREATE_NEW_SAVINGS_PRODUCT_FAIL,
  CREATE_NEW_SAVINGS_PRODUCT_RESET,
  SAVINGS_PRODUCT_FAIL,
  SAVINGS_PRODUCT_SUCCESS,
  SAVINGS_PRODUCT_REQUEST,
  UPDATE_SAVINGS_PRODUCT_REQUEST,
  UPDATE_SAVINGS_PRODUCT_SUCCESS,
  UPDATE_SAVINGS_PRODUCT_FAIL,
  SAVINGS_PRODUCT_RESET,
  ALL_LOAN_PRODUCT_REQUEST,
  ALL_LOAN_PRODUCT_SUCCESS,
  ALL_LOAN_PRODUCT_FAIL,
  FILTER_LOAN_PRODUCT_REQUEST,
  CLEAR_LOAN_PRODUCT_FILTER,
  ALL_LOAN_PRODUCT_TEMPLATE_REQUEST,
  ALL_LOAN_PRODUCT_TEMPLATE_SUCCESS,
  ALL_LOAN_PRODUCT_TEMPLATE_FAIL,
  CREATE_NEW_LOAN_PRODUCT_REQUEST,
  CREATE_NEW_LOAN_PRODUCT_SUCCESS,
  CREATE_NEW_LOAN_PRODUCT_FAIL,
  CREATE_NEW_LOAN_PRODUCT_RESET,
  LOAN_PRODUCT_FAIL,
  LOAN_PRODUCT_SUCCESS,
  LOAN_PRODUCT_REQUEST,
  UPDATE_LOAN_PRODUCT_REQUEST,
  UPDATE_LOAN_PRODUCT_SUCCESS,
  UPDATE_LOAN_PRODUCT_FAIL,
  LOAN_PRODUCT_RESET,
  ALL_CHARGES_REQUEST,
  ALL_CHARGES_SUCCESS,
  ALL_CHARGES_FAIL,
  FILTER_CHARGES_REQUEST,
  CLEAR_CHARGES_FILTER,
  ALL_CHARGES_TEMPLATE_REQUEST,
  ALL_CHARGES_TEMPLATE_SUCCESS,
  ALL_CHARGES_TEMPLATE_FAIL,
  CREATE_NEW_CHARGE_REQUEST,
  CREATE_NEW_CHARGE_SUCCESS,
  CREATE_NEW_CHARGE_FAIL,
  CREATE_NEW_CHARGE_RESET,
  DELETE_CHARGE_REQUEST,
  DELETE_CHARGE_SUCCESS,
  DELETE_CHARGE_FAIL,
  CHARGE_FAIL,
  CHARGE_SUCCESS,
  CHARGE_REQUEST,
  UPDATE_CHARGE_FAIL,
  UPDATE_CHARGE_REQUEST,
  UPDATE_CHARGE_SUCCESS,
  CHARGE_RESET,
  ALL_TAX_COMPONENTS_REQUEST,
  ALL_TAX_COMPONENTS_SUCCESS,
  ALL_TAX_COMPONENTS_FAIL,
  ALL_TAX_COMPONENTS_TEMPLATE_REQUEST,
  ALL_TAX_COMPONENTS_TEMPLATE_SUCCESS,
  ALL_TAX_COMPONENTS_TEMPLATE_FAIL,
  FILTER_TAX_COMPONENTS_REQUEST,
  CLEAR_TAX_COMPONENTS_FILTER,
  UPDATE_TAX_COMPONENT_REQUEST,
  UPDATE_TAX_COMPONENT_SUCCESS,
  UPDATE_TAX_COMPONENT_FAIL,
  CREATE_NEW_TAX_COMPONENT_REQUEST,
  CREATE_NEW_TAX_COMPONENT_SUCCESS,
  CREATE_NEW_TAX_COMPONENT_FAIL,
  CREATE_NEW_TAX_COMPONENT_RESET,
  ALL_TAX_GROUPS_REQUEST,
  ALL_TAX_GROUPS_SUCCESS,
  ALL_TAX_GROUPS_FAIL,
  ALL_TAX_GROUPS_TEMPLATE_REQUEST,
  ALL_TAX_GROUPS_TEMPLATE_SUCCESS,
  ALL_TAX_GROUPS_TEMPLATE_FAIL,
  FILTER_TAX_GROUPS_REQUEST,
  CLEAR_TAX_GROUPS_FILTER,
  UPDATE_TAX_GROUP_REQUEST,
  UPDATE_TAX_GROUP_SUCCESS,
  UPDATE_TAX_GROUP_FAIL,
  CREATE_NEW_TAX_GROUP_REQUEST,
  CREATE_NEW_TAX_GROUP_SUCCESS,
  CREATE_NEW_TAX_GROUP_FAIL,
  CREATE_NEW_TAX_GROUP_RESET,
  ALL_AUDIT_REQUEST,
  ALL_AUDIT_SUCCESS,
  ALL_AUDIT_TEMPLATE_FAIL,
  ALL_AUDIT_TEMPLATE_REQUEST,
  ALL_AUDIT_TEMPLATE_SUCCESS,
  ALL_AUDIT_FAIL,
  ALL_USER_AUDIT_REQUEST,
  ALL_USER_AUDIT_SUCCESS,
  ALL_USER_AUDIT_FAIL,
  ALL_HOOK_REQUEST,
  ALL_HOOK_SUCCESS,
  ALL_HOOK_FAIL,
  FILTER_HOOK_REQUEST,
  CLEAR_HOOK_FILTER,
  CREATE_NEW_HOOK_REQUEST,
  CREATE_NEW_HOOK_SUCCESS,
  UPDATE_HOOK_SUCCESS,
  UPDATE_HOOK_REQUEST,
  UPDATE_HOOK_FAIL,
  CREATE_NEW_HOOK_FAIL,
  DELETE_HOOK_REQUEST,
  DELETE_HOOK_SUCCESS,
  DELETE_HOOK_FAIL,
  ALL_HOOK_TEMPLATE_REQUEST,
  ALL_HOOK_TEMPLATE_SUCCESS,
  ALL_HOOK_TEMPLATE_FAIL,
  UPDATE_S3_REQUEST,
  UPDATE_S3_SUCCESS,
  UPDATE_S3_FAIL,
  ALL_S3_REQUEST,
  ALL_S3_SUCCESS,
  ALL_S3_FAIL,
  UPDATE_SMTP_REQUEST,
  UPDATE_SMTP_SUCCESS,
  UPDATE_SMTP_FAIL,
  ALL_SMTP_REQUEST,
  ALL_SMTP_SUCCESS,
  ALL_SMTP_FAIL,
  UPDATE_SMS_REQUEST,
  UPDATE_SMS_SUCCESS,
  UPDATE_SMS_FAIL,
  ALL_SMS_REQUEST,
  ALL_SMS_SUCCESS,
  ALL_SMS_FAIL,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  UPDATE_NOTIFICATION_FAIL,
  ALL_NOTIFICATION_REQUEST,
  ALL_NOTIFICATION_SUCCESS,
  ALL_NOTIFICATION_FAIL,
  ALL_CONFIGURATION_REQUEST,
  ALL_CONFIGURATION_SUCCESS,
  ALL_CONFIGURATION_FAIL,
  ENABLE_CONFIGURATION_REQUEST,
  ENABLE_CONFIGURATION_SUCCESS,
  ENABLE_CONFIGURATION_FAIL,
  DISABLE_CONFIGURATION_REQUEST,
  DISABLE_CONFIGURATION_SUCCESS,
  DISABLE_CONFIGURATION_FAIL,
  FILTER_CONFIGURATION_REQUEST,
  CLEAR_CONFIGURATION_FILTER,
  UPDATE_CONFIGURATION_REQUEST,
  UPDATE_CONFIGURATION_SUCCESS,
  UPDATE_CONFIGURATION_FAIL,
  UPDATE_MCTASK_REQUEST,
  UPDATE_MCTASK_SUCCESS,
  UPDATE_MCTASK_FAIL,
  ALL_MCTASKS_REQUEST,
  ALL_MCTASKS_SUCCESS,
  ALL_MCTASKS_FAIL,
  ALL_ROLES_REQUEST,
  ALL_ROLES_SUCCESS,
  ALL_ROLES_FAIL,
  ROLE_REQUEST,
  ROLE_SUCCESS,
  ROLE_FAIL,
  FILTER_ROLES_REQUEST,
  CLEAR_ROLES_FILTER,
  CREATE_NEW_ROLE_REQUEST,
  CREATE_NEW_ROLE_SUCCESS,
  UPDATE_ROLE_SUCCESS,
  UPDATE_ROLE_REQUEST,
  UPDATE_ROLE_FAIL,
  CREATE_NEW_ROLE_FAIL,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAIL,
  ABLE_ROLE_FAIL,
  ABLE_ROLE_REQUEST,
  ABLE_ROLE_SUCCESS,
  GENERATED_USER_KEY,
  GET_DATA_TABLES,
  GET_ONE_DATA_TABLE,
  GET_USER_KEYS,
  GET_SINGLE_AUDIT_REQUEST,
  GET_SINGLE_AUDIT_SUCCESS,
  GET_PASSWORD_CHECK_REQUEST,
  GET_PASSWORD_CHECK_SUCCESS,
  GET_PASSWORD_CHECK_FAIL,
  RESET_PASSWORD_CHECK,
  FETCH_SINGLE_EMPLOYEE_REQUEST,
  FETCH_SINGLE_EMPLOYEE_FAIL,
  FETCH_SINGLE_EMPLOYEE_SUCCESS,
  UPDATE_EMPLOYEE_IMAGE_REQUEST,
  UPDATE_EMPLOYEE_IMAGE_SUCCESS,
  UPDATE_EMPLOYEE_IMAGE_FAIL,
  FETCH_EMPLOYEE_IMAGE_REQUEST,
  FETCH_EMPLOYEE_IMAGE_SUCCESS,
  FETCH_EMPLOYEE_IMAGE_FAIL,
  RESET_EMPLOYEE_IMAGE,
  RESET_USER_PASSWORD_REQUEST,
  RESET_USER_PASSWORD_SUCCESS,
  RESET_USER_PASSWORD_FAIL,
  FETCH_SINGLE_EMPLOYEE_RESET,
} from "../constants/configurationConstants";
import Toast from "../util/Toast";
import { SET_LOADING_STATE } from "../constants/utilityConstants";

//USERS & ORGANIZATION SECTION

const usersRequestFail = (payload) => {
  return { type: ALL_USERS_FAIL, payload };
};
const newUserFail = (payload) => {
  return { type: CREATE_NEW_USER_FAIL, payload };
};
const userTemplateFail = (payload) => {
  return { type: ALL_USERS_TEMPLATE_FAIL, payload };
};
const officeStaffsFail = (payload) => {
  return { type: ALL_OFFICE_STAFFS_FAIL, payload };
};
const deleteUserFail = (payload) => {
  return { type: DELETE_USER_FAIL, payload };
};
const updateUserFail = (payload) => {
  return { type: UPDATE_USER_FAIL, payload };
};
const resetUserPasswordFail = (payload) => {
  return { type: RESET_USER_PASSWORD_FAIL, payload };
};
const checkPasswordFail = (payload) => {
  return { type: GET_PASSWORD_CHECK_FAIL, payload };
};
const officesRequetFail = (payload) => {
  return { type: ALL_OFFICES_FAIL, payload };
};
const updateOfficeFail = (payload) => {
  return { type: UPDATE_OFFICE_FAIL, payload };
};
const newOfficeFail = (payload) => {
  return { type: CREATE_NEW_OFFICE_FAIL, payload };
};
const currenciesRequetFail = (payload) => {
  return { type: ALL_CURRENCY_FAIL, payload };
};
const newCurrencyFail = (payload) => {
  return { type: CREATE_NEW_CURRENCY_FAIL, payload };
};
const employeesRequestFail = (payload) => {
  return { type: ALL_EMPLOYEES_FAIL, payload };
};
const employeeRequestFail = (payload) => {
  return { type: FETCH_SINGLE_EMPLOYEE_FAIL, payload };
};
const newEmployeeFail = (payload) => {
  return { type: CREATE_NEW_EMPLOYEE_FAIL, payload };
};
const fetchEmployeeImageFail = (payload) => {
  return { type: FETCH_EMPLOYEE_IMAGE_FAIL, payload };
};
const updateEmployeeImageFail = (payload) => {
  return { type: UPDATE_EMPLOYEE_IMAGE_FAIL, payload };
};
const updateEmployeeFail = (payload) => {
  return { type: UPDATE_EMPLOYEE_FAIL, payload };
};
const entityDataTableCheckRequestFail = (payload) => {
  return { type: ALL_ENTITY_DATA_TABLE_FAIL, payload };
};
const newEntityDataTableFail = (payload) => {
  return { type: CREATE_ENTITY_DATA_TABLE_FAIL, payload };
};
const deleteEntityDataTable = (payload) => {
  return { type: DELETE_ENTITY_DATA_TABLE_FAIL, payload };
};
const entityDataTableTemplateFail = (payload) => {
  return { type: ALL_ENTITY_DATA_TABLE_TEMPLATE_FAIL, payload };
};
const holidaysRequestFail = (payload) => {
  return { type: ALL_HOLIDAYS_FAIL, payload };
};
const updateHolidayFail = (payload) => {
  return { type: UPDATE_HOLIDAY_FAIL, payload };
};
const newHolidayFail = (payload) => {
  return { type: CREATE_NEW_HOLIDAY_FAIL, payload };
};
const deleteHolidayFail = (payload) => {
  return { type: DELETE_HOLIDAY_FAIL, payload };
};
const activateHolidayFail = (payload) => {
  return { type: ACTIVATE_HOLIDAY_FAIL, payload };
};
const holidayTemplateFail = (payload) => {
  return { type: ALL_HOLIDAYS_TEMPLATE_FAIL, payload };
};
const workingDaysFail = (payload) => {
  return { type: ALL_WORKING_DAYS_FAIL, payload };
};
const newWorkingDaysFail = (payload) => {
  return { type: CREATE_NEW_WORKING_DAYS_FAIL, payload };
};
const standingInstructionTemplateFail = (payload) => {
  return { type: ALL_STANDING_INSTRUCTION_TEMPLATE_FAIL, payload };
};
const standingInstructionsRequetFail = (payload) => {
  return { type: ALL_STANDING_INSTRUCTION_FAIL, payload };
};
const paymentTypeRequestFail = (payload) => {
  return { type: ALL_PAYMENT_TYPE_FAIL, payload };
};
const updatePaymentTypeFail = (payload) => {
  return { type: UPDATE_PAYMENT_TYPE_FAIL, payload };
};
const newPaymentTypeFail = (payload) => {
  return { type: CREATE_NEW_PAYMENT_TYPE_FAIL, payload };
};
const deletePaymentTypeFail = (payload) => {
  return { type: DELETE_PAYMENT_TYPE_FAIL, payload };
};
//Get a list of all users
const getAllUsers = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_USERS_REQUEST });
    await API_CONFIGURATION.getAllUsers()
      .then((response) => {
        dispatch({ type: ALL_USERS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(usersRequestFail(ex));
      });
  };
};

//Get template for create user
const getUserTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_USERS_TEMPLATE_REQUEST });
    await API_CONFIGURATION.getUserTemplate()
      .then((response) => {
        dispatch({ type: ALL_USERS_TEMPLATE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(userTemplateFail(ex));
      });
  };
};

//Get all office staffs for create user
const getOfficeStaffs = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: ALL_OFFICE_STAFFS_REQUEST });
    await API_CONFIGURATION.getOfficeStaffs(officeId)
      .then((response) => {
        dispatch({ type: ALL_OFFICE_STAFFS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(officeStaffsFail(ex));
      });
  };
};

// Delete User
const deleteUser = (id, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_USER_REQUEST });
      const data = await API_CONFIGURATION.deleteUser(id);
      dispatch(Toast({ text: "User deleted", icon: "success" }));
      history.push("/core/user-management");
      dispatch({ type: DELETE_USER_SUCCESS, payload: data });
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(deleteUserFail(ex));
    }
  };
};

// Update User Info
const updateUserInfo = (userData, id, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_REQUEST });
      const data = await API_CONFIGURATION.editUserInfo(userData, id);
      dispatch(Toast({ text: "User info updated", icon: "success" }));
      dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateUserFail(ex));
    }
  };
};

// Reset User Password
const resetUserPassword = (id, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: RESET_USER_PASSWORD_REQUEST });
      dispatch({ type: SET_LOADING_STATE, payload: true });
      const data = await API_CONFIGURATION.resetUserPassword(id);
      dispatch(Toast({ text: "Reset Password link Sent!", icon: "success" }));
      dispatch({ type: RESET_USER_PASSWORD_SUCCESS, payload: data });
      dispatch({ type: SET_LOADING_STATE, payload: false });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(resetUserPasswordFail(ex));
    }
  };
};

// Update User Password
const updateUserPassword = (userData, id, func ,logoutHandler) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_USER_REQUEST });
      dispatch({ type: SET_LOADING_STATE, payload: true });
      const data = await API_CONFIGURATION.editUserInfo(userData, id);
      dispatch(Toast({ text: "User password updated", icon: "success" }));
      dispatch({ type: UPDATE_USER_SUCCESS, payload: data });
      dispatch({ type: SET_LOADING_STATE, payload: false });
      func();
      logoutHandler();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateUserFail(ex));
    }
  };
};

// Perform Password Check for Old password
const performPasswordCheck = (id, password) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PASSWORD_CHECK_REQUEST });
      dispatch({ type: SET_LOADING_STATE, payload: true });
      const data = await API_CONFIGURATION.performPasswordCheck(id, password);
      dispatch({ type: GET_PASSWORD_CHECK_SUCCESS, payload: data });
      dispatch({ type: SET_LOADING_STATE, payload: false });
    } catch (ex) {
      if (ex.response) {
        // dispatch(
        //   Toast({
        //     text: ex.response.data.errors
        //       ? ex.response.data.errors[0].defaultUserMessage
        //       : ex.response.data.defaultUserMessage,
        //     icon: "error",
        //   })
        // );
        dispatch({ type: SET_LOADING_STATE, payload: false });
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(checkPasswordFail(ex));
    }
  };
};

const passwordCheckReset = () => {
  return async (dispatch) => {
    dispatch({ type: RESET_PASSWORD_CHECK });
  };
};

//Get a list of all offices
const getAllOffices = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_OFFICES_REQUEST });
    await API_CONFIGURATION.getAllOffices()
      .then((response) => {
        dispatch({ type: ALL_OFFICES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(officesRequetFail(ex));
      });
  };
};

//Get a list of all currency
const getAllCurrency = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_CURRENCY_REQUEST });
    await API_CONFIGURATION.getAllCurrency()
      .then((response) => {
        dispatch({ type: ALL_CURRENCY_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(currenciesRequetFail(ex));
      });
  };
};

//Get a list of all employees
const getAllEmployees = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_EMPLOYEES_REQUEST });
    await API_CONFIGURATION.getAllEmployees()
      .then((response) => {
        dispatch({ type: ALL_EMPLOYEES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(employeesRequestFail(ex));
      });
  };
};

//Get a single employee
const getSingleEmployee = (employeeId) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_SINGLE_EMPLOYEE_RESET });
    dispatch({ type: FETCH_SINGLE_EMPLOYEE_REQUEST });
    await API_CONFIGURATION.fetchEmployeeInfo(employeeId)
      .then((response) => {
        dispatch({ type: FETCH_SINGLE_EMPLOYEE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(employeeRequestFail(ex));
      });
  };
};

//Get a list of all employees
const getAllEntityDataTableCheck = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_ENTITY_DATA_TABLE_REQUEST });
    await API_CONFIGURATION.getAllEntityDataTableCheck()
      .then((response) => {
        dispatch({ type: ALL_ENTITY_DATA_TABLE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(entityDataTableCheckRequestFail(ex));
      });
  };
};

//Get template for entity data table checks
const getEntityDataTableTemplate = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: ALL_ENTITY_DATA_TABLE_TEMPLATE_REQUEST });
    await API_CONFIGURATION.getEntityDataTableTemplate(officeId)
      .then((response) => {
        dispatch({
          type: ALL_ENTITY_DATA_TABLE_TEMPLATE_SUCCESS,
          payload: response,
        });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(entityDataTableTemplateFail(ex));
      });
  };
};

//Get a list of all Holidays
const getAllHolidays = (officeId) => {
  return async (dispatch) => {
    dispatch({ type: ALL_HOLIDAYS_REQUEST });
    await API_CONFIGURATION.getAllHolidays(officeId)
      .then((response) => {
        dispatch({ type: ALL_HOLIDAYS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(holidaysRequestFail(ex));
      });
  };
};

//Get template for create holiday
const getHolidayTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_HOLIDAYS_TEMPLATE_REQUEST });
    await API_CONFIGURATION.getHolidayTemplate()
      .then((response) => {
        dispatch({ type: ALL_HOLIDAYS_TEMPLATE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(holidayTemplateFail(ex));
      });
  };
};

//Get template for create holiday
const getWorkingDays = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_WORKING_DAYS_REQUEST });
    await API_CONFIGURATION.getWorkingDays()
      .then((response) => {
        dispatch({ type: ALL_WORKING_DAYS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(workingDaysFail(ex));
      });
  };
};

//Get a list of standing intructions
const getStandingInstructions = (params) => {
  return async (dispatch) => {
    dispatch({ type: ALL_STANDING_INSTRUCTION_REQUEST });
    await API_CONFIGURATION.getStandingInstructions(params)
      .then((response) => {
        dispatch({ type: ALL_STANDING_INSTRUCTION_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(standingInstructionsRequetFail(ex));
      });
  };
};

//Get template for standing instructions
const getStandingInstructionTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_STANDING_INSTRUCTION_TEMPLATE_REQUEST });
    await API_CONFIGURATION.getStandingInstructionsTemplate()
      .then((response) => {
        dispatch({
          type: ALL_STANDING_INSTRUCTION_TEMPLATE_SUCCESS,
          payload: response,
        });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(standingInstructionTemplateFail(ex));
      });
  };
};

//Get a list of all Payment type
const getAllPaymentType = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_PAYMENT_TYPE_REQUEST });
    await API_CONFIGURATION.getPaymentType()
      .then((response) => {
        dispatch({ type: ALL_PAYMENT_TYPE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(paymentTypeRequestFail(ex));
      });
  };
};

// Update Office Info
const updateOfficeInfo = (userData, clientId, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_OFFICE_REQUEST });
      const data = await API_CONFIGURATION.editOfficeInfo(userData, clientId);
      dispatch(Toast({ text: "Office info updated", icon: "success" }));
      dispatch({ type: UPDATE_OFFICE_SUCCESS, payload: data });
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateOfficeFail(ex));
    }
  };
};

// Update Employee Image
const fetchEmployeeImage = (employeeId) => {
  return (dispatch) => {
    dispatch({ type: RESET_EMPLOYEE_IMAGE })
    dispatch({ type: FETCH_EMPLOYEE_IMAGE_REQUEST });
    API_CONFIGURATION.fetchEmployeeImage(employeeId)
      .then(data => {
        dispatch({ type: FETCH_EMPLOYEE_IMAGE_SUCCESS, payload: data })
      })
      .catch((ex) => {
        // if (ex.response) {
        //   dispatch(
        //     Toast({
        //       text: ex.response.data.errors
        //         ? ex.response.data.errors[0].defaultUserMessage
        //         : ex.response.data.defaultUserMessage,
        //       icon: "error",
        //     })
        //   );
        // } else if (ex.request) {
        //   dispatch(Toast({ text: String(ex), icon: "error" }));
        // } else {
        //   dispatch(Toast({ text: String(ex), icon: "error" }));
        // }
        dispatch(fetchEmployeeImageFail(ex));
      });
  };
};

// Update Employee Image
const updateEmployeeImage = (image, employeeId, func, isNew = false) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_EMPLOYEE_IMAGE_REQUEST });
      const data = await API_CONFIGURATION.editEmployeeImage(
        image,
        employeeId
      );
      dispatch({ type: UPDATE_EMPLOYEE_IMAGE_SUCCESS, payload: data });
      dispatch(Toast({ text: `Employee photo ${isNew ? "uploaded" : "updated"}`, icon: "success" }));
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateEmployeeImageFail(ex));
    }
  };
};

// Update Employee Info
const updateEmployeeInfo = (employeeData, employeeId, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_EMPLOYEE_REQUEST });
      const data = await API_CONFIGURATION.editEmployeeInfo(
        employeeData,
        employeeId
      );
      dispatch(Toast({ text: "Employee info updated", icon: "success" }));
      dispatch({ type: UPDATE_EMPLOYEE_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateEmployeeFail(ex));
    }
  };
};

// Update holiday Info
const updateHolidayInfo = (holidayData, holidayId, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_HOLIDAY_REQUEST });
      const data = await API_CONFIGURATION.editHolidayInfo(
        holidayData,
        holidayId
      );
      dispatch(Toast({ text: "Holiday info updated", icon: "success" }));
      dispatch({ type: UPDATE_HOLIDAY_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateHolidayFail(ex));
    }
  };
};

// Update Payment type Info
const updatePaymentTypeInfo = (userData, clientId, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_PAYMENT_TYPE_REQUEST });
      const data = await API_CONFIGURATION.editPaymentTypeInfo(
        userData,
        clientId
      );
      dispatch(Toast({ text: "Payment type info updated", icon: "success" }));
      dispatch({ type: UPDATE_PAYMENT_TYPE_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        Toast({
          text: ex.response.data.errors
            ? ex.response.data.errors[0].defaultUserMessage
            : ex.response.data.defaultUserMessage,
          icon: "error",
        });
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updatePaymentTypeFail(ex));
    }
  };
};

// Delete Entity Data Table Checks
const deleteEntityDataTableCheck = (deleteData, entityDataTableId, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_ENTITY_DATA_TABLE_REQUEST });
      const data = await API_CONFIGURATION.deleteEntityDataTableCheck(
        deleteData,
        entityDataTableId
      );
      dispatch(Toast({ text: "Entity data check deleted", icon: "success" }));
      dispatch({ type: DELETE_ENTITY_DATA_TABLE_SUCCESS, payload: data });
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(deleteEntityDataTable(ex));
    }
  };
};

// Delete Entity Data Table Checks
const deleteHoliday = (holidayId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_HOLIDAY_REQUEST });
      const data = await API_CONFIGURATION.deleteHoliday(holidayId);
      dispatch(Toast({ text: "Holiday deleted", icon: "success" }));
      dispatch({ type: DELETE_HOLIDAY_SUCCESS, payload: data });
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(deleteHolidayFail(ex));
    }
  };
};

// Delete Payment type
const deletePaymentType = (paymentTypeId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_PAYMENT_TYPE_REQUEST });
      const data = await API_CONFIGURATION.deletePaymentType(paymentTypeId);
      dispatch(Toast({ text: "Payment type deleted", icon: "success" }));
      dispatch({ type: DELETE_PAYMENT_TYPE_SUCCESS, payload: data });
    } catch (ex) {
      if (ex.response) {
        Toast({
          text: ex.response.data.errors
            ? ex.response.data.errors[0].defaultUserMessage
            : ex.response.data.defaultUserMessage,
          icon: "error",
        });
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(deletePaymentTypeFail(ex));
    }
  };
};

// FILTER USERS
const filterUsers = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_USERS_REQUEST, payload: filterParam });
  };
};

// FILTER OFFICES
const filterOffices = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_OFFICES_REQUEST, payload: filterParam });
  };
};

// FILTER EMPLOYEES
const filterEmployees = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_EMPLOYEES_REQUEST, payload: filterParam });
  };
};

// FILTER ENTITY DATA TABLE CHECKS
const filterEntityDataTable = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_ENTITY_DATA_TABLE_REQUEST, payload: filterParam });
  };
};

// FILTER HOLIDAYS
const filterHolidays = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_HOLIDAYS_REQUEST, payload: filterParam });
  };
};

// FILTER PAYMENT TYPE
const filterPaymentType = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_PAYMENT_TYPE_REQUEST, payload: filterParam });
  };
};

//RESET SINGLE USER STATE
const clearFilteredUsers = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_FILTER });
  };
};

//RESET SINGLE OFFICE STATE
const clearFilteredOffices = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_OFFICES_FILTER });
  };
};

//RESET SINGLE PAYMENT TYPE STATE
const clearFilteredPaymentType = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_PAYMENT_TYPE_FILTER });
  };
};

//RESET SINGLE HOLIDAY STATE
const clearFilteredHolidays = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_HOLIDAYS_FILTER });
  };
};

//RESET NEW USER STATE
const resetNewUserState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_USER_RESET });
  };
};
//RESET NEW OFFICE STATE
const resetNewOfficerState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_OFFICE_RESET });
  };
};
//RESET ALL OFFICE STAFFS STATE
const resetOfficerStaffs = () => {
  return (dispatch) => {
    dispatch({ type: ALL_OFFICE_STAFFS_RESET });
  };
};
//RESET ALL EMPLOYEES STATE
const clearFilteredEmplyees = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_EMPLOYEES_FILTER });
  };
};
//RESET NEW OFFICE STATE
const resetNewEmployeeState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_EMPLOYEE_RESET });
  };
};
//RESET ALL ENTITY DATA TABLE CHECKS STATE
const clearFilteredEntityDataTable = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ENTITY_DATA_TABLE_FILTER });
  };
};
//RESET NEW USER STATE
const resetNewEntityDataTableState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_ENTITY_DATA_TABLE_RESET });
  };
};
//RESET NEW OFFICE STATE
const resetNewHolidayState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_HOLIDAY_RESET });
  };
};

//RESET NEW WORKING DAYS STATE
const resetNewWorkingDaysState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_WORKING_DAYS_RESET });
  };
};

//CREATE NEW USER
const createNewUser = (userData, func) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_USER_REQUEST });
    await API_CONFIGURATION.createNewUser(userData)
      .then(async (response) => {
        func();
        dispatch({ type: CREATE_NEW_USER_SUCCESS, payload: response });
        dispatch(Toast({ text: "User created", icon: "success" }));
        // if(imgPayload !== null){
        //   dispatch(uploadCustomerImage(response.clientId, imgPayload, history))
        // }
        // if(imgPayload === null) {
        //   history.push(`/core/client/${response.clientId}`);
        // }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newUserFail(ex));
      });
  };
};

//CREATE USER API KEY
const generateUserApiKey = (userData, headers) => {
  return async (dispatch) => {
    dispatch({ type: GENERATED_USER_KEY });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.generateUserApiKey(userData, headers)
      .then(async (response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GENERATED_USER_KEY, payload: response });
        dispatch(Toast({ text: "Api Generated", icon: "success" }));
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newUserFail(ex));
      });
  };
};

//Get USER API KEY
const getUserApiKeys = (id, headers) => {
  return async (dispatch) => {
    dispatch({ type: GET_USER_KEYS });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getUserApiKeys(id, headers)
      .then(async (response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch({ type: GET_USER_KEYS, payload: response && response.data });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newUserFail(ex));
      });
  };
};

//Get USER API KEY
const manageApiKeys = (id, headers, userId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.manageApiKey(id, headers)
      .then(async (response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        dispatch(
          getUserApiKeys(userId, {
            action: "DISPLAY",
          })
        );
        dispatch(Toast({ text: "Api Keys Updated", icon: "success" }));
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newUserFail(ex));
      });
  };
};

//CREATE NEW OFFICE
const createNewOffice = (officeData, func) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_OFFICE_REQUEST });
    await API_CONFIGURATION.createNewOffice(officeData)
      .then(async (response) => {
        dispatch({ type: CREATE_NEW_OFFICE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Office created", icon: "success" }));
        // if(imgPayload !== null){
        //   dispatch(uploadCustomerImage(response.clientId, imgPayload, history))
        // }
        // if(imgPayload === null) {
        //   history.push(`/core/client/${response.clientId}`);
        // }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newOfficeFail(ex));
      });
  };
};

//CREATE NEW CURRENCY
const createNewCurrency = (currencyData, isDelete) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_CURRENCY_REQUEST });
    await API_CONFIGURATION.createNewCurrency(currencyData)
      .then(async (response) => {
        dispatch({ type: CREATE_NEW_CURRENCY_SUCCESS, payload: response });
        dispatch(
          Toast({
            text: isDelete ? "Currency deleted" : "Currency created",
            icon: "success",
          })
        );
        // if(imgPayload !== null){
        //   dispatch(uploadCustomerImage(response.clientId, imgPayload, history))
        // }
        // if(imgPayload === null) {
        //   history.push(`/core/client/${response.clientId}`);
        // }
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newCurrencyFail(ex));
      });
  };
};

//CREATE NEW EMPLOYEE
const createNewEmployee = (employeeData, image, func) => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_EMPLOYEE_REQUEST });
    API_CONFIGURATION.createNewEmployee(employeeData)
      .then((response) => {
        if (image) {
          dispatch(updateEmployeeImage(image, response?.resourceId, func, true));
          dispatch({ type: CREATE_NEW_EMPLOYEE_SUCCESS, payload: response });
          dispatch(Toast({ text: "Employee created", icon: "success" }));
        } else {
          dispatch({ type: CREATE_NEW_EMPLOYEE_SUCCESS, payload: response });
          dispatch(Toast({ text: "Employee created", icon: "success" }));
        }
        func();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newEmployeeFail(ex));
      });
  };
};

//CREATE NEW ENTITY DATA TABLE CHECKS
const createNewEntityDataTableCheck = (entityData) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_ENTITY_DATA_TABLE_REQUEST });
    await API_CONFIGURATION.createNewEntityDataTableCheck(entityData)
      .then(async (response) => {
        dispatch({
          type: CREATE_ENTITY_DATA_TABLE_SUCCESS,
          payload: response,
        });
        dispatch(
          Toast({
            text: "Entity data table checks created",
            icon: "success",
          })
        );
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newEntityDataTableFail(ex));
      });
  };
};

//CREATE NEW PAYMENT TYPE
const createNewPaymentType = (paymentTypeData, func) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_PAYMENT_TYPE_REQUEST });
    await API_CONFIGURATION.createNewPaymentType(paymentTypeData)
      .then(async (response) => {
        dispatch({ type: CREATE_NEW_PAYMENT_TYPE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Office created", icon: "success" }));
        func();
      })
      .catch((ex) => {
        if (ex.response) {
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          });
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newPaymentTypeFail(ex));
      });
  };
};

// Activate Holiday
const activateHoliday = (holidayData, holidayId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ACTIVATE_HOLIDAY_REQUEST });
      const data = await API_CONFIGURATION.activateHoliday(
        holidayData,
        holidayId
      );
      dispatch(Toast({ text: "Holiday activated", icon: "success" }));
      dispatch({ type: ACTIVATE_HOLIDAY_SUCCESS, payload: data });
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(activateHolidayFail(ex));
    }
  };
};
//CREATE NEW HOLIDAY
const createNewHoliday = (holidayData, functionTorun) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_HOLIDAY_REQUEST });
    await API_CONFIGURATION.createNewHoliday(holidayData)
      .then(async (response) => {
        dispatch({ type: CREATE_NEW_HOLIDAY_SUCCESS, payload: response });
        dispatch(Toast({ text: "Holiday created", icon: "success" }));
        functionTorun();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
              timer: 4000,
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newHolidayFail(ex));
      });
  };
};
//CREATE NEW HOLIDAY

const createWorkingDays = (workingDaysData) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_WORKING_DAYS_REQUEST });
    await API_CONFIGURATION.createWorkingDay(workingDaysData)
      .then(async (response) => {
        dispatch({ type: CREATE_NEW_WORKING_DAYS_SUCCESS, payload: response });
        dispatch(Toast({ text: "Working Days updated", icon: "success" }));
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
              timer: 4000,
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newWorkingDaysFail(ex));
      });
  };
};

//--- audit trails
const auditRequestFail = (payload) => {
  return { type: ALL_AUDIT_FAIL, payload };
};
const auditTemplateFail = (payload) => {
  return { type: ALL_AUDIT_TEMPLATE_FAIL, payload };
};

//--- user audit
const userAuditRequestFail = (payload) => {
  return { type: ALL_USER_AUDIT_FAIL, payload };
};

//Get all audit
const getAllAudit = (
  actionName,
  checkerDateTimeFrom,
  checkerDateTimeTo,
  checkerId,
  entityName,
  makerDateTimeFrom,
  makerDateTimeTo,
  makerId,
  resourceId,
  status
) => {
  return async (dispatch) => {
    dispatch({ type: ALL_AUDIT_REQUEST });
    await API_CONFIGURATION.getAllAudit(
      actionName,
      checkerDateTimeFrom,
      checkerDateTimeTo,
      checkerId,
      entityName,
      makerDateTimeFrom,
      makerDateTimeTo,
      makerId,
      resourceId,
      status
    )
      .then((response) => {
        dispatch({ type: ALL_AUDIT_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(auditRequestFail(ex));
      });
  };
};

//Get all audit
const getAllUserAudit = (
  actionName,
  checkerDateTimeFrom,
  checkerDateTimeTo,
  checkerId,
  entityName,
  makerDateTimeFrom,
  makerDateTimeTo,
  makerId,
  resourceId,
  status
) => {
  return async (dispatch) => {
    dispatch({ type: ALL_USER_AUDIT_REQUEST });
    await API_CONFIGURATION.getAllUserAudit(
      actionName,
      checkerDateTimeFrom,
      checkerDateTimeTo,
      checkerId,
      entityName,
      makerDateTimeFrom,
      makerDateTimeTo,
      makerId,
      resourceId,
      status
    )
      .then((response) => {
        dispatch({ type: ALL_USER_AUDIT_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(userAuditRequestFail(ex));
      });
  };
};
// FILTER AUDIT USERS
const filterAuditUsers = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_AUDIT_USERS_REQUEST, payload: filterParam });
  };
};

//RESET SINGLE AUDIT USER STATE
const clearFilteredAuditUsers = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_AUDIT_USERS_FILTER });
  };
};
//get audit search template

const getAuditTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_AUDIT_TEMPLATE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getAuditTemplate()
      .then((response) => {
        dispatch({
          type: ALL_AUDIT_TEMPLATE_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(auditTemplateFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};
const getSingleAudit = (id) => {
  return async (dispatch) => {
    dispatch({ type: GET_SINGLE_AUDIT_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getSingleAudit(id)
      .then((response) => {
        dispatch({
          type: GET_SINGLE_AUDIT_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(auditTemplateFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

//--- manage hooks
const hookRequestFail = (payload) => {
  return { type: ALL_HOOK_FAIL, payload };
};
const updateHookFail = (payload) => {
  return { type: UPDATE_HOOK_FAIL, payload };
};
const newHookFail = (payload) => {
  return { type: CREATE_NEW_HOOK_FAIL, payload };
};
const deleteHookFail = (payload) => {
  return { type: DELETE_HOOK_FAIL, payload };
};
const hookTemplateFail = (payload) => {
  return { type: ALL_HOOK_TEMPLATE_FAIL, payload };
};

//RESET SINGLE HOOK STATE
const clearFilteredHook = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_HOOK_FILTER });
  };
};
// FILTER HOOK
const filterHook = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_HOOK_REQUEST, payload: filterParam });
  };
};

//Get a list of all hook
const getAllHook = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_HOOK_REQUEST });
    await API_CONFIGURATION.getHook()
      .then((response) => {
        dispatch({ type: ALL_HOOK_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(hookRequestFail(ex));
      });
  };
};

// Update Hook Info
const updateHookInfo = (userData, clientId, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_HOOK_REQUEST });
      const data = await API_CONFIGURATION.editHookInfo(userData, clientId);
      dispatch(Toast({ text: "Hook info updated", icon: "success" }));
      dispatch({ type: UPDATE_HOOK_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        Toast({
          text: ex.response.data.errors
            ? ex.response.data.errors[0].defaultUserMessage
            : ex.response.data.defaultUserMessage,
          icon: "error",
        });
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateHookFail(ex));
    }
  };
};

// Delete Hook
const deleteHook = (hookId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_HOOK_REQUEST });
      const data = await API_CONFIGURATION.deleteHook(hookId);
      dispatch(Toast({ text: "Hook deleted", icon: "success" }));
      dispatch({ type: DELETE_HOOK_SUCCESS, payload: data });
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(deleteHookFail(ex));
    }
  };
};

//CREATE NEW HOOK
const createNewHook = (paymentTypeData, func) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_HOOK_REQUEST });
    await API_CONFIGURATION.createNewHook(paymentTypeData)
      .then(async (response) => {
        dispatch({ type: CREATE_NEW_HOOK_SUCCESS, payload: response });
        dispatch(Toast({ text: "Hook created", icon: "success" }));
        func();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newHookFail(ex));
      });
  };
};

//Get template for create savings product
const getHookTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_HOOK_TEMPLATE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getHookTemplate()
      .then((response) => {
        dispatch({
          type: ALL_HOOK_TEMPLATE_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(hookTemplateFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

//PRODUCTS SECTION

// --- savings products
const savingsProductRequestFail = (payload) => {
  return { type: ALL_SAVINGS_PRODUCTS_FAIL, payload };
};
const savingsProductTemplateFail = (payload) => {
  return { type: ALL_SAVINGS_PRODUCTS_TEMPLATE_FAIL, payload };
};
const newSavingsProductFail = (payload) => {
  return { type: CREATE_NEW_SAVINGS_PRODUCT_FAIL, payload };
};
const aSavingsProductRequestFail = (payload) => {
  return { type: SAVINGS_PRODUCT_FAIL, payload };
};
const updateSavingsProductFail = (payload) => {
  return { type: UPDATE_SAVINGS_PRODUCT_FAIL, payload };
};

// filter savings products
const filterSavingsProduct = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_SAVINGS_PRODUCTS_REQUEST, payload: filterParam });
  };
};

//clear filtered savings products
const clearFilteredSavingsProducts = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SAVINGS_PRODUCTS_FILTER });
  };
};

//RESET NEW SAVINGS PRODUCT STATE
const resetNewSavingsProductState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_SAVINGS_PRODUCT_RESET });
  };
};

//RESET SAVING PRODUCT STATE
const resetSavingsProduct = () => {
  return (dispatch) => {
    dispatch({ type: SAVINGS_PRODUCT_RESET });
  };
};

//Get a list of all savings product
const getAllSavingsProducts = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_SAVINGS_PRODUCTS_REQUEST });
    await API_CONFIGURATION.getAllSavingsProducts()
      .then((response) => {
        dispatch({
          type: ALL_SAVINGS_PRODUCTS_SUCCESS,
          payload: response,
        });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(savingsProductRequestFail(ex));
      });
  };
};

//Get template for create savings product
const getSavingsProductsTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_SAVINGS_PRODUCTS_TEMPLATE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getSavingsProductsTemplate()
      .then((response) => {
        dispatch({
          type: ALL_SAVINGS_PRODUCTS_TEMPLATE_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(savingsProductTemplateFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

const createSavingsProduct = (savingsProductData, history) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_SAVINGS_PRODUCT_REQUEST });
    await API_CONFIGURATION.createSavingsProduct(savingsProductData)
      .then(async (response) => {
        dispatch({
          type: CREATE_NEW_SAVINGS_PRODUCT_SUCCESS,
          payload: response,
        });
        dispatch(Toast({ text: "Savings product created", icon: "success" }));
        history.push("/core/products/savings-products");
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
              timer: 4000,
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newSavingsProductFail(ex));
      });
  };
};
// get a savings product
const getSavingsProduct = (id) => {
  return async (dispatch) => {
    dispatch({ type: SAVINGS_PRODUCT_REQUEST });
    await API_CONFIGURATION.getSavingsProduct(id)
      .then((response) => {
        dispatch({ type: SAVINGS_PRODUCT_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(aSavingsProductRequestFail(ex));
      });
  };
};

// Update Savings Product Info
const updateSavingsProductInfo = (spData, id, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SAVINGS_PRODUCT_REQUEST });
      const data = await API_CONFIGURATION.editSavingsProductInfo(spData, id);
      dispatch({ type: UPDATE_SAVINGS_PRODUCT_SUCCESS, payload: data });
      dispatch(
        Toast({ text: "Savings product info updated", icon: "success" })
      );
      history.push("/core/products/savings-products");
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateSavingsProductFail(ex));
    }
  };
};

// --- loans products
const loanProductRequestFail = (payload) => {
  return { type: ALL_LOAN_PRODUCT_FAIL, payload };
};
const loanProductTemplateFail = (payload) => {
  return { type: ALL_LOAN_PRODUCT_TEMPLATE_FAIL, payload };
};
const newLoanProductFail = (payload) => {
  return { type: CREATE_NEW_LOAN_PRODUCT_FAIL, payload };
};
const aLoanProductRequestFail = (payload) => {
  return { type: LOAN_PRODUCT_FAIL, payload };
};
const updateLoanProductFail = (payload) => {
  return { type: UPDATE_LOAN_PRODUCT_FAIL, payload };
};

// filter loan products
const filterLoanProduct = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_LOAN_PRODUCT_REQUEST, payload: filterParam });
  };
};

//clear filtered loan products
const clearFilteredLoanProducts = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_LOAN_PRODUCT_FILTER });
  };
};

//RESET NEW SAVINGS PRODUCT STATE
const resetNewLoanProductState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_LOAN_PRODUCT_RESET });
  };
};

//RESET SAVING PRODUCT STATE
const resetLoanProduct = () => {
  return (dispatch) => {
    dispatch({ type: LOAN_PRODUCT_RESET });
  };
};

//Get a list of all loan product
const getAllLoanProducts = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_LOAN_PRODUCT_REQUEST });
    await API_CONFIGURATION.getAllLoanProducts()
      .then((response) => {
        dispatch({ type: ALL_LOAN_PRODUCT_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(loanProductRequestFail(ex));
      });
  };
};

//Get template for create loan product
const getLoanProductsTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_LOAN_PRODUCT_TEMPLATE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getLoanProductsTemplate()
      .then((response) => {
        dispatch({
          type: ALL_LOAN_PRODUCT_TEMPLATE_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(loanProductTemplateFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

const createLoanProduct = (loanProductData, history) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_LOAN_PRODUCT_REQUEST });
    await API_CONFIGURATION.createLoanProduct(loanProductData)
      .then(async (response) => {
        dispatch({
          type: CREATE_NEW_LOAN_PRODUCT_SUCCESS,
          payload: response,
        });
        dispatch(Toast({ text: "Loan product created", icon: "success" }));
        history.push("/core/products/loan-products");
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
              timer: 4000,
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newLoanProductFail(ex));
      });
  };
};
// get a loan product
const getLoanProduct = (id) => {
  return async (dispatch) => {
    dispatch({ type: LOAN_PRODUCT_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getLoanProduct(id)
      .then((response) => {
        dispatch({ type: LOAN_PRODUCT_SUCCESS, payload: response });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(aLoanProductRequestFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

// Update Loan Product Info
const updateLoanProductInfo = (spData, id, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_LOAN_PRODUCT_REQUEST });
      const data = await API_CONFIGURATION.editLoanProductInfo(spData, id);
      dispatch({ type: UPDATE_LOAN_PRODUCT_SUCCESS, payload: data });
      dispatch(Toast({ text: "Loan product info updated", icon: "success" }));
      history.push("/core/products/loan-products");
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateLoanProductFail(ex));
    }
  };
};

// Charges funcs
const chargesRequestFail = (payload) => {
  return { type: ALL_CHARGES_FAIL, payload };
};
const chargesTemplateRequestFail = (payload) => {
  return { type: ALL_CHARGES_TEMPLATE_FAIL, payload };
};
const newChargeFail = (payload) => {
  return { type: CREATE_NEW_CHARGE_FAIL, payload };
};
const deleteChargeFail = (payload) => {
  return { type: DELETE_CHARGE_FAIL, payload };
};
const aChargeRequestFail = (payload) => {
  return { type: CHARGE_FAIL, payload };
};
const updateChargeFail = (payload) => {
  return { type: UPDATE_CHARGE_FAIL, payload };
};

// filter charges
const filterCharges = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_CHARGES_REQUEST, payload: filterParam });
  };
};
//clear filtered charges
const clearFilteredCharges = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CHARGES_FILTER });
  };
};

//RESET NEW CHARGE STATE
const resetNewChargeState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_CHARGE_RESET });
  };
};

//RESET CHARGE STATE
const resetCharge = () => {
  return (dispatch) => {
    dispatch({ type: CHARGE_RESET });
  };
};

//get list of charges
const getAllCharges = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_CHARGES_REQUEST });
    await API_CONFIGURATION.getAllCharges()
      .then((response) => {
        dispatch({ type: ALL_CHARGES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(chargesRequestFail(ex));
      });
  };
};

//Get template for charges
const getChargesTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_CHARGES_TEMPLATE_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.getChargesTemplate()
      .then((response) => {
        dispatch({
          type: ALL_CHARGES_TEMPLATE_SUCCESS,
          payload: response,
        });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(chargesTemplateRequestFail(ex));
      });
    dispatch({ type: SET_LOADING_STATE, payload: false });
  };
};

//create new charge
const createNewCharge = (data, history) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_CHARGE_REQUEST });
    await API_CONFIGURATION.createNewCharge(data)
      .then(async (response) => {
        dispatch({
          type: CREATE_NEW_CHARGE_SUCCESS,
          payload: response,
        });
        dispatch(
          Toast({ text: "Charge created successfully", icon: "success" })
        );
        history.push("/core/charges");
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
              timer: 4000,
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newChargeFail(ex));
      });
  };
};

// Delete Charge
const deleteCharge = (id, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_CHARGE_REQUEST });
      const data = await API_CONFIGURATION.deleteCharge(id);
      dispatch(Toast({ text: "Charge deleted", icon: "success" }));
      dispatch({ type: DELETE_CHARGE_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(deleteChargeFail(ex));
    }
  };
};
// get a charge
const getCharge = (id) => {
  return async (dispatch) => {
    dispatch({ type: CHARGE_REQUEST });
    await API_CONFIGURATION.getCharge(id)
      .then((response) => {
        dispatch({ type: CHARGE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(aChargeRequestFail(ex));
      });
  };
};

// Update Charge Info
const updateChargeInfo = (cData, id, history) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CHARGE_REQUEST });
      const data = await API_CONFIGURATION.editChargeInfo(cData, id);
      dispatch({ type: UPDATE_CHARGE_SUCCESS, payload: data });
      dispatch(Toast({ text: "Charge info updated", icon: "success" }));
      history.push("/core/charges");
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateChargeFail(ex));
    }
  };
};

//manage tax configuration

//manage tax components

const taxComponentsRequetFail = (payload) => {
  return { type: ALL_TAX_COMPONENTS_FAIL, payload };
};
const updateTaxComponentFail = (payload) => {
  return { type: UPDATE_TAX_COMPONENT_FAIL, payload };
};
const newTaxComponentFail = (payload) => {
  return { type: CREATE_NEW_TAX_COMPONENT_FAIL, payload };
};
const taxComponentTemplateFail = (payload) => {
  return { type: ALL_TAX_COMPONENTS_TEMPLATE_FAIL, payload };
};

// FILTER TAX COMPONENTS
const filterTaxComponents = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_TAX_COMPONENTS_REQUEST, payload: filterParam });
  };
};

//RESET FILTERED TAX COMPONENTS
const clearFilteredTaxComponents = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_TAX_COMPONENTS_FILTER });
  };
};

//RESET NEW TAX COMPONENT STATE
const resetNewTaxComponentState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_TAX_COMPONENT_RESET });
  };
};

//Get a list of all tax components
const getAllTaxComponents = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_TAX_COMPONENTS_REQUEST });
    await API_CONFIGURATION.getAllTaxComponents()
      .then((response) => {
        dispatch({ type: ALL_TAX_COMPONENTS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(taxComponentsRequetFail(ex));
      });
  };
};

//Get template for create Tax component
const getTaxComponentsTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_TAX_COMPONENTS_TEMPLATE_REQUEST });
    await API_CONFIGURATION.getTaxComponentsTemplate()
      .then((response) => {
        dispatch({
          type: ALL_TAX_COMPONENTS_TEMPLATE_SUCCESS,
          payload: response,
        });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(taxComponentTemplateFail(ex));
      });
  };
};

//CREATE NEW TAX COMPONENT
const createNewTaxComponent = (tCData, func) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_TAX_COMPONENT_REQUEST });
    await API_CONFIGURATION.createNewTaxComponent(tCData)
      .then(async (response) => {
        dispatch({ type: CREATE_NEW_TAX_COMPONENT_SUCCESS, payload: response });
        dispatch(Toast({ text: "Tax component created", icon: "success" }));
        func();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newTaxComponentFail(ex));
      });
  };
};

// Update Tax component Info
const updateTaxComponentInfo = (tCData, tId, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_TAX_COMPONENT_REQUEST });
      const data = await API_CONFIGURATION.editTaxComponentInfo(tCData, tId);
      dispatch(Toast({ text: "Tax Component info updated", icon: "success" }));
      dispatch({ type: UPDATE_TAX_COMPONENT_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateTaxComponentFail(ex));
    }
  };
};

//manage tax groups

const taxGroupsRequetFail = (payload) => {
  return { type: ALL_TAX_GROUPS_FAIL, payload };
};
const updateTaxGroupFail = (payload) => {
  return { type: UPDATE_TAX_GROUP_FAIL, payload };
};
const newTaxGroupFail = (payload) => {
  return { type: CREATE_NEW_TAX_GROUP_FAIL, payload };
};
const taxGroupTemplateFail = (payload) => {
  return { type: ALL_TAX_GROUPS_TEMPLATE_FAIL, payload };
};

// FILTER TAX GROUPS
const filterTaxGroups = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_TAX_GROUPS_REQUEST, payload: filterParam });
  };
};

//RESET FILTERED TAX GROUPS
const clearFilteredTaxGroups = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_TAX_GROUPS_FILTER });
  };
};

//RESET NEW TAX GROUP STATE
const resetNewTaxGroupState = () => {
  return (dispatch) => {
    dispatch({ type: CREATE_NEW_TAX_GROUP_RESET });
  };
};

//Get a list of all tax groups
const getAllTaxGroups = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_TAX_GROUPS_REQUEST });
    await API_CONFIGURATION.getAllTaxGroups()
      .then((response) => {
        dispatch({ type: ALL_TAX_GROUPS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(taxGroupsRequetFail(ex));
      });
  };
};

//Get template for create Tax group
const getTaxGroupsTemplate = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_TAX_GROUPS_TEMPLATE_REQUEST });
    await API_CONFIGURATION.getTaxGroupsTemplate()
      .then((response) => {
        dispatch({
          type: ALL_TAX_GROUPS_TEMPLATE_SUCCESS,
          payload: response,
        });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(taxGroupTemplateFail(ex));
      });
  };
};

//CREATE NEW TAX GROUP
const createNewTaxGroup = (tCData, func) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_TAX_GROUP_REQUEST });
    await API_CONFIGURATION.createNewTaxGroup(tCData)
      .then(async (response) => {
        dispatch({ type: CREATE_NEW_TAX_GROUP_SUCCESS, payload: response });
        dispatch(Toast({ text: "Tax group created", icon: "success" }));
        func();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newTaxGroupFail(ex));
      });
  };
};

// Update Tax group Info
const updateTaxGroupInfo = (tCData, tId, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_TAX_GROUP_REQUEST });
      const data = await API_CONFIGURATION.editTaxGroupInfo(tCData, tId);
      dispatch(Toast({ text: "Tax Group info updated", icon: "success" }));
      dispatch({ type: UPDATE_TAX_GROUP_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateTaxGroupFail(ex));
    }
  };
};

//--- external services
const s3RequetFail = (payload) => {
  return { type: ALL_S3_FAIL, payload };
};
const updateS3Fail = (payload) => {
  return { type: UPDATE_S3_FAIL, payload };
};
const SMTPRequetFail = (payload) => {
  return { type: ALL_SMTP_FAIL, payload };
};
const updateSMTPFail = (payload) => {
  return { type: UPDATE_SMTP_FAIL, payload };
};
const SMSRequetFail = (payload) => {
  return { type: ALL_SMS_FAIL, payload };
};
const updateSMSFail = (payload) => {
  return { type: UPDATE_SMS_FAIL, payload };
};
const notificationRequetFail = (payload) => {
  return { type: ALL_NOTIFICATION_FAIL, payload };
};
const updateNotificationFail = (payload) => {
  return { type: UPDATE_NOTIFICATION_FAIL, payload };
};

//Get a list of all s3 amazon
const getAllS3 = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_S3_REQUEST });
    await API_CONFIGURATION.getAllS3()
      .then((response) => {
        dispatch({ type: ALL_S3_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(s3RequetFail(ex));
      });
  };
};

// Update S3 Info
const updateS3Info = (tCData, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_S3_REQUEST });
      const data = await API_CONFIGURATION.editS3Info(tCData);
      dispatch(Toast({ text: "S3 info updated", icon: "success" }));
      dispatch({ type: UPDATE_S3_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateS3Fail(ex));
    }
  };
};

//Get a list of all SMTP
const getAllSMTP = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_SMTP_REQUEST });
    await API_CONFIGURATION.getAllSMTP()
      .then((response) => {
        dispatch({ type: ALL_SMTP_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(SMTPRequetFail(ex));
      });
  };
};

// UpdateSMTP Info
const updateSMTPInfo = (tCData, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SMTP_REQUEST });
      const data = await API_CONFIGURATION.editSMTPInfo(tCData);
      dispatch(Toast({ text: "SMTP info updated", icon: "success" }));
      dispatch({ type: UPDATE_SMTP_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateSMTPFail(ex));
    }
  };
};

//Get a list of all SMS
const getAllSMS = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_SMS_REQUEST });
    await API_CONFIGURATION.getAllSMS()
      .then((response) => {
        dispatch({ type: ALL_SMS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(SMSRequetFail(ex));
      });
  };
};

// UpdateSMS Info
const updateSMSInfo = (tCData, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SMS_REQUEST });
      const data = await API_CONFIGURATION.editSMSInfo(tCData);
      dispatch(Toast({ text: "SMS info updated", icon: "success" }));
      dispatch({ type: UPDATE_SMS_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateSMSFail(ex));
    }
  };
};

//Get a list of all notification
const getAllNotification = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_NOTIFICATION_REQUEST });
    await API_CONFIGURATION.getAllNotification()
      .then((response) => {
        dispatch({ type: ALL_NOTIFICATION_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(notificationRequetFail(ex));
      });
  };
};

// UpdateNotification Info
const updateNotificationInfo = (tCData, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_NOTIFICATION_REQUEST });
      const data = await API_CONFIGURATION.editNotificationInfo(tCData);
      dispatch(Toast({ text: "Notification info updated", icon: "success" }));
      dispatch({ type: UPDATE_NOTIFICATION_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateNotificationFail(ex));
    }
  };
};

//--configuration

const configurationsRequetFail = (payload) => {
  return { type: ALL_CONFIGURATION_FAIL, payload };
};
const updateConfigurationFail = (payload) => {
  return { type: UPDATE_CONFIGURATION_FAIL, payload };
};
const enableConfigurationFail = (payload) => {
  return { type: ENABLE_CONFIGURATION_FAIL, payload };
};
const disableConfigurationFail = (payload) => {
  return { type: DISABLE_CONFIGURATION_FAIL, payload };
};

// FILTER CONFIGURATION
const filterConfigurations = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_CONFIGURATION_REQUEST, payload: filterParam });
  };
};

//RESET FILTERED CONFIGURATION
const clearFilteredConfigurations = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CONFIGURATION_FILTER });
  };
};

//Get a list of all configuration
const getAllConfigurations = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_CONFIGURATION_REQUEST });
    await API_CONFIGURATION.getAllConfigurations()
      .then((response) => {
        dispatch({ type: ALL_CONFIGURATION_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(configurationsRequetFail(ex));
      });
  };
};

// Update Configuration Info
const updateConfigurationInfo = (tCData, id, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CONFIGURATION_REQUEST });
      const data = await API_CONFIGURATION.editConfigurationInfo(tCData, id);
      dispatch(Toast({ text: "Configuration info updated", icon: "success" }));
      dispatch({ type: UPDATE_CONFIGURATION_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateConfigurationFail(ex));
    }
  };
};

//Disable configuration
const disableConfiguration = (data, id, func) => {
  return async (dispatch) => {
    // console.log(data)
    dispatch({ type: DISABLE_CONFIGURATION_REQUEST });
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION?.disableConfiguration(data, id)
      .then(async (response) => {
        // console.log(response)
        dispatch({ type: DISABLE_CONFIGURATION_SUCCESS, payload: response });
        dispatch(Toast({ text: "Configuration disabled", icon: "success" }));
        dispatch({ type: SET_LOADING_STATE, payload: false });
        func();
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(disableConfigurationFail(ex));
      });
  };
};

//Enable configuration
const enabledConfiguration = (data, id, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: ENABLE_CONFIGURATION_REQUEST });
    await API_CONFIGURATION.enabledConfiguration(data, id)
      .then(async (response) => {
        dispatch({ type: ENABLE_CONFIGURATION_SUCCESS, payload: response });
        dispatch(Toast({ text: "Configuration enabled", icon: "success" }));
        dispatch({ type: SET_LOADING_STATE, payload: false });
        func();
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(enableConfigurationFail(ex));
      });
  };
};

// --- MARKER CHECKER TASKS
const mcTasksRequetFail = (payload) => {
  return { type: ALL_MCTASKS_FAIL, payload };
};
const updateMCTasksFail = (payload) => {
  return { type: UPDATE_MCTASK_FAIL, payload };
};

//Get a list of all mcTasks
const getAllMCTasks = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_MCTASKS_REQUEST });
    await API_CONFIGURATION.getAllMCTasks()
      .then((response) => {
        dispatch({ type: ALL_MCTASKS_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(mcTasksRequetFail(ex));
      });
  };
};

// Update MCTasks Info
const updateMCTasksInfo = (tCData, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_MCTASK_REQUEST });
      const data = await API_CONFIGURATION.editMCTasksInfo(tCData);
      dispatch(Toast({ text: "Maker Checker info updated", icon: "success" }));
      dispatch({ type: UPDATE_MCTASK_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateMCTasksFail(ex));
    }
  };
};

//--- manage Roles and Permissions
const rolesRequestFail = (payload) => {
  return { type: ALL_ROLES_FAIL, payload };
};
const roleRequestFail = (payload) => {
  return { type: ROLE_FAIL, payload };
};
const updateRolesFail = (payload) => {
  return { type: UPDATE_ROLE_FAIL, payload };
};
const newRolesFail = (payload) => {
  return { type: CREATE_NEW_ROLE_FAIL, payload };
};
const deleteRoleFail = (payload) => {
  return { type: DELETE_ROLE_FAIL, payload };
};
const ableRoleFail = (payload) => {
  return { type: ABLE_ROLE_FAIL, payload };
};

//RESET SINGLE ROLES STATE
const clearFilteredRoles = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ROLES_FILTER });
  };
};
// FILTER ROLES
const filterRoles = (filterParam) => {
  return (dispatch) => {
    dispatch({ type: FILTER_ROLES_REQUEST, payload: filterParam });
  };
};

//Get a list of all roles
const getAllRoles = () => {
  return async (dispatch) => {
    dispatch({ type: ALL_ROLES_REQUEST });
    await API_CONFIGURATION.getAllRoles()
      .then((response) => {
        dispatch({ type: ALL_ROLES_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(rolesRequestFail(ex));
      });
  };
};

//Get a role
const getRole = (id) => {
  return async (dispatch) => {
    dispatch({ type: ROLE_REQUEST });
    await API_CONFIGURATION.getRole(id)
      .then((response) => {
        dispatch({ type: ROLE_SUCCESS, payload: response });
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(roleRequestFail(ex));
      });
  };
};

// Update Roles Info
const updateRolesInfo = (userData, id, func) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_ROLE_REQUEST });
      const data = await API_CONFIGURATION.editRoleInfo(userData, id);
      dispatch(Toast({ text: "Role info updated", icon: "success" }));
      dispatch({ type: UPDATE_ROLE_SUCCESS, payload: data });
      func();
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(updateRolesFail(ex));
    }
  };
};

// Delete Roles
const deleteRole = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_ROLE_REQUEST });
      const data = await API_CONFIGURATION.deleteRole(id);
      dispatch(Toast({ text: "Role deleted", icon: "success" }));
      dispatch({ type: DELETE_ROLE_SUCCESS, payload: data });
    } catch (ex) {
      if (ex.response) {
        dispatch(
          Toast({
            text: ex.response.data.errors
              ? ex.response.data.errors[0].defaultUserMessage
              : ex.response.data.defaultUserMessage,
            icon: "error",
          })
        );
      } else if (ex.request) {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      } else {
        dispatch(Toast({ text: String(ex), icon: "error" }));
      }
      dispatch(deleteRoleFail(ex));
    }
  };
};

//CREATE NEW ROLE
const createNewRole = (data, func) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_NEW_ROLE_REQUEST });
    await API_CONFIGURATION.createNewRole(data)
      .then(async (response) => {
        dispatch({ type: CREATE_NEW_ROLE_SUCCESS, payload: response });
        dispatch(Toast({ text: "Role added", icon: "success" }));
        func();
      })
      .catch((ex) => {
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(newRolesFail(ex));
      });
  };
};

//Enable/Disable role
const ableRole = (data, id, action, func) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: ABLE_ROLE_REQUEST });
    await API_CONFIGURATION.ableRole(data, id, action)
      .then(async (response) => {
        dispatch({ type: ABLE_ROLE_SUCCESS, payload: response });
        dispatch(Toast({ text: `Role ${action}d `, icon: "success" }));
        dispatch({ type: SET_LOADING_STATE, payload: false });
        func();
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(ableRoleFail(ex));
      });
  };
};

//Get Data Tables
const getDataTables = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_DATA_TABLES });
    await API_CONFIGURATION.getDataTables()
      .then(async (response) => {
        dispatch({ type: GET_DATA_TABLES, payload: response });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(ableRoleFail(ex));
      });
  };
};

//Get Data Tables
const getASingleDataTable = (name) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    dispatch({ type: GET_ONE_DATA_TABLE });
    await API_CONFIGURATION.getASingleDataTables(name)
      .then(async (response) => {
        dispatch({ type: GET_ONE_DATA_TABLE, payload: response });
        dispatch({ type: SET_LOADING_STATE, payload: false });
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(ableRoleFail(ex));
      });
  };
};

//Get Data Tables
const deleteASingleDataTable = (name, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.deleteASingleDataTable(name)
      .then(async (response) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push("/core/manage-data-tables");
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(ableRoleFail(ex));
      });
  };
};

//Create Data Tables
const createADataTable = (data, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.createDataTables(data)
      .then(async (response) => {
        dispatch(
          Toast({ text: `DataTable Created Successfully`, icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push("/core/manage-data-tables");
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(ableRoleFail(ex));
      });
  };
};

//Create Data Tables
const updateADataTable = (datatableName, data, history) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING_STATE, payload: true });
    await API_CONFIGURATION.updateDataTables(datatableName, data)
      .then(async (response) => {
        dispatch(
          Toast({ text: `DataTable Updated Successfully`, icon: "success" })
        );
        dispatch({ type: SET_LOADING_STATE, payload: false });
        history.push(
          `/core/manage-data-tables/details/${response.resourceIdentifier}`
        );
      })
      .catch((ex) => {
        dispatch({ type: SET_LOADING_STATE, payload: false });
        if (ex.response) {
          dispatch(
            Toast({
              text: ex.response.data.errors
                ? ex.response.data.errors[0].defaultUserMessage
                : ex.response.data.defaultUserMessage,
              icon: "error",
            })
          );
        } else if (ex.request) {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        } else {
          dispatch(Toast({ text: String(ex), icon: "error" }));
        }
        dispatch(ableRoleFail(ex));
      });
  };
};

export {
  getAllUsers,
  filterAuditUsers,
  clearFilteredAuditUsers,
  filterUsers,
  clearFilteredUsers,
  createNewUser,
  deleteUser,
  updateUserInfo,
  updateUserPassword,
  resetUserPassword,
  performPasswordCheck,
  passwordCheckReset,
  resetNewUserState,
  getUserTemplate,
  getOfficeStaffs,
  resetOfficerStaffs,
  getAllOffices,
  filterOffices,
  clearFilteredOffices,
  updateOfficeInfo,
  createNewOffice,
  resetNewOfficerState,
  getAllCurrency,
  createNewCurrency,
  getSingleEmployee,
  getAllEmployees,
  clearFilteredEmplyees,
  filterEmployees,
  createNewEmployee,
  resetNewEmployeeState,
  updateEmployeeInfo,
  fetchEmployeeImage,
  updateEmployeeImage,
  getAllEntityDataTableCheck,
  clearFilteredEntityDataTable,
  filterEntityDataTable,
  createNewEntityDataTableCheck,
  getEntityDataTableTemplate,
  resetNewEntityDataTableState,
  deleteEntityDataTableCheck,
  getAllHolidays,
  filterHolidays,
  clearFilteredHolidays,
  createNewHoliday,
  getHolidayTemplate,
  resetNewHolidayState,
  activateHoliday,
  updateHolidayInfo,
  deleteHoliday,
  getWorkingDays,
  createWorkingDays,
  resetNewWorkingDaysState,
  getStandingInstructions,
  getStandingInstructionTemplate,
  getAllPaymentType,
  updatePaymentTypeInfo,
  deletePaymentType,
  filterPaymentType,
  clearFilteredPaymentType,
  createNewPaymentType,
  getAllSavingsProducts,
  resetNewSavingsProductState,
  filterSavingsProduct,
  clearFilteredSavingsProducts,
  getSavingsProductsTemplate,
  createSavingsProduct,
  getSavingsProduct,
  updateSavingsProductInfo,
  resetSavingsProduct,
  getAllLoanProducts,
  resetNewLoanProductState,
  filterLoanProduct,
  clearFilteredLoanProducts,
  getLoanProductsTemplate,
  createLoanProduct,
  getLoanProduct,
  updateLoanProductInfo,
  resetLoanProduct,
  getAllCharges,
  filterCharges,
  clearFilteredCharges,
  getChargesTemplate,
  resetNewChargeState,
  createNewCharge,
  deleteCharge,
  resetCharge,
  getCharge,
  updateChargeInfo,
  getAllTaxComponents,
  filterTaxComponents,
  clearFilteredTaxComponents,
  updateTaxComponentInfo,
  createNewTaxComponent,
  resetNewTaxComponentState,
  getTaxComponentsTemplate,
  getAllTaxGroups,
  filterTaxGroups,
  clearFilteredTaxGroups,
  updateTaxGroupInfo,
  createNewTaxGroup,
  resetNewTaxGroupState,
  getTaxGroupsTemplate,
  getAllAudit,
  getAllUserAudit,
  getAuditTemplate,
  getSingleAudit,
  getAllHook,
  updateHookInfo,
  deleteHook,
  filterHook,
  clearFilteredHook,
  createNewHook,
  getHookTemplate,
  getAllS3,
  updateS3Info,
  getAllSMTP,
  updateSMTPInfo,
  getAllSMS,
  updateSMSInfo,
  getAllNotification,
  updateNotificationInfo,
  getAllConfigurations,
  filterConfigurations,
  clearFilteredConfigurations,
  updateConfigurationInfo,
  disableConfiguration,
  enabledConfiguration,
  getAllMCTasks,
  updateMCTasksInfo,
  getAllRoles,
  clearFilteredRoles,
  filterRoles,
  updateRolesInfo,
  deleteRole,
  createNewRole,
  ableRole,
  getRole,
  generateUserApiKey,
  getDataTables,
  createADataTable,
  getASingleDataTable,
  deleteASingleDataTable,
  updateADataTable,
  getUserApiKeys,
  manageApiKeys,
};
