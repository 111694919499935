import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs } from "antd";
import { ContentWrapper, ActionButton, TabButton, FormClass, FormElements } from "./styles";
import ModalContainer from "../../components/ModalContainer";
// import LoanRepayment from "../CustomerInformation/components/loans/LoanRepayment";
import ApproveLoan from "../CustomerInformation/components/loans/ApproveLoan";
import {
  getLoanDetails,
  deleteLoan,
} from "../../../../actions/SavingsAndLoanActions";
import AccountDetails from "./AccountDetails";
import RepaymentSchedule from "./RepaymentSchedule";
import LoanDocuments from "./LoanDocuments";
import Notes from "./Notes";
import RejectLoanModal from "./RejectLoanModal";
import AssignLoanOfficerModal from "./AssignStaff";
import WithdrawnByClientModal from "./WithdrawnByClientModal";
import AddCollateralModal from "./AddCollateralModal";
import DisburseLoanModal from "./DisburseLoanModal";
import PrepayLoanModal from "./PrepayLoanModal";
import LoanForClosureModal from "./LoanForClosureModal";
import GenerateLetterModal from "./GenerateLetterModal";
import LoanRepaymentModal from "./LoanRepaymentModal";
import UndoDisburseModal from "./UndoDisburseModal";
import UndoApproveLoanModal from "./UndoApproveLoanModal";
import WaiveInterestModal from "./WaiveInterestModal";
import RescheduleLoan from "./RescheduleLoan";
import WriteOffLoanAccountModal from "./WriteOffLoanAccountModal";
import CloseLoanAccountScheduleModal from "./CloseLoanAccountScheduleModal";
import RecoverRepaymentModal from "./RecoverRepaymentModal";
import RecoverFromGuarantorModal from "./RecoverFromGuarantorModal";
import Transactions from "./Transactions";

import LoanDetails from "./LoanDetails";

import { formatCurrency } from "../../../../util/FormatAmount";

import BackSvg from "../../../../assets/images/icons/back.svg";
import ArrowCancelSvg from "../../../../assets/images/icons/arrow-cancel.svg";
import ArrowForwardSvg from "../../../../assets/images/icons/arror-forward.svg";

import { getApplicableDataTables } from "../../../../actions/utilityActions";
import CheckPermission from "../../../../util/CheckPermission";
import LoanCharges from "./LoanCharges";
import { getLoanOfferLetters, getLoanAccountStatement } from "../../../../actions/customerActions";
import EditLoanAccount from "../CustomerInformation/components/loans/EditLoanAccount";
import LoanGuarantorModal from "./LoanGuarantor";
import AddChargeModal from "./AddChargeModal";
import CurrencyFormat from "react-currency-format";
import { Select, Textarea } from "@chakra-ui/react";
import { Table } from "react-bootstrap";
import { IconButton } from "../styles";
import { addCollateralsToAPendingLoan, fetchCollateralTemplate, fetchCollateralsAssociatedWithALoan } from "../../../../actions/loanCollateralActions";
import DataTables from "../CustomerInformation2/components/DataTables";
import PreviewRepaymentSchedule from "./RescheduleLoan/PreviewRepaymentSchedule";
import { CLEAR_LOAN_OFFER_LETTER } from "../../../../constants/customerConstants";

const CustomerLoanDetails = ({ currentId, history, setCurrentView , currentTab}) => {
  const dispatch = useDispatch();
  const savingsAndLoan = useSelector((state) => state.savingsAndLoan);

  const fetchLoanLetters = (id) => {
    dispatch({ type: CLEAR_LOAN_OFFER_LETTER });
    dispatch(getLoanOfferLetters(id));
  };

  const fetchLoanStatement = (id) => {
    dispatch({ type: CLEAR_LOAN_OFFER_LETTER });
    dispatch(getLoanAccountStatement(id));
  };

  let { loanDetails } = savingsAndLoan || {};

  const { responseUserData: { permissions } } = useSelector((state) => state.preAuth);

  const dataTablesInfo = useSelector((state) => state.manageDataTables);
  const { applicableDataTables = {} } = dataTablesInfo;

  const { TabPane } = Tabs;

  let {
    clientId,
    loanProductName,
    accountNo,
    loanOfficerName,
    linkedAccount,
    currency = {},
    notes = [],
    transactions = [],
    id,
    status = {},
    repaymentSchedule = {},
    loanOfficerId,
    summary = {},
    charges = [],
  } = loanDetails || {};  


  let { periods = [] , totalRepaymentExpected } = repaymentSchedule || {};

  let { totalOutstanding} = summary || {};

  let mainPrincipalOutstanding = 0;

  periods.forEach((period) => {
    let { principalOutstanding = 0 } = period || {};
    // For Principal
    mainPrincipalOutstanding += principalOutstanding;
  });

  useEffect(() => {
    dispatch(getLoanDetails(currentId));
    dispatch(getApplicableDataTables("m_loan"));
  }, [dispatch, currentId]);

  const [resourceId, setResourceId] = useState(undefined);
  const [modalState, toggleModal] = useState(false);

  const [closeType, setCloseType] = useState("");

  const [modalContentName, setModalContentName] = useState("");

  const currentContent = (e) => {
    const val = e.currentTarget.id.trim();
    if (val) {
      toggleModal(true);
      setModalContentName(val);
    }
  };

  const handleModalClose = () => toggleModal(false);

  const displayModalContent = () => {
    switch (modalContentName) {
      case "addCharge":
        return (
          <AddChargeModal
            id={id}
            userId={clientId}
            history={history}
            onHide={handleModalClose} 
          />
        );
      case "undoApproval":
        return (
          <UndoApproveLoanModal
            id={id}
            onHide={handleModalClose}
            history={history}
            userId={clientId}
          />
        );
      case "approve":
        return (
          <ApproveLoan
            userId={clientId}
            transId={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "modifyApplication":
        return (
          <EditLoanAccount
            history={history}
            id={id}
            onHide={handleModalClose}
            userId={clientId}
            currentTab={currentTab}
          />
        );
      case "disburseLoan":
        return (
          <DisburseLoanModal
            id={id}
            transId={id}
            clientId={clientId}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "reject":
        return (
          <RejectLoanModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "assignLoanOfficer":
        return (
          <AssignLoanOfficerModal
            id={id}
            onHide={handleModalClose}
            history={history}
            action={!!loanOfficerName ? "toLoanOfficerId" : ""}
            loanOfficerId={loanOfficerId}
          />
        );
      case "withdrawnByClient":
        return (
          <WithdrawnByClientModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      // case "addCollateral":
      //   return (
      //     <AddCollateralModal
      //       id={id}
      //       onHide={handleModalClose}
      //       history={history}
      //     />
      //   );
      case "prepayLoan":
        return (
          <PrepayLoanModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "loanForeclosure":
        return (
          <LoanForClosureModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "loanRepayment":
        return (
          <LoanRepaymentModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "undoDisbursal":
        return (
          <UndoDisburseModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "addGuarantor":
        return (
          <LoanGuarantorModal
            id={id}
            clientId={clientId}
            currency={currency}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "waiveInterest":
        return (
          <WaiveInterestModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "writeOffLoanAccount":
        return (
          <WriteOffLoanAccountModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "closeLoanAccountSchedule":
        return (
          <CloseLoanAccountScheduleModal
            id={id}
            onHide={handleModalClose}
            history={history}
            closeType={closeType}
          />
        );
      case "recoveryRepayment":
        return (
          <RecoverRepaymentModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "recoverFromGuarantor":
        return (
          <RecoverFromGuarantorModal
            id={id}
            onHide={handleModalClose}
            history={history}
          />
        );
      case "generateLetter":
      case "generateStatement":
        return <GenerateLetterModal id={id} onHide={handleModalClose} />;
      default:
        return;
    }
  };

  const handleLoanDelete = () => {
    dispatch(deleteLoan(id));
  };

  const [showMenu, setShowMenu] = useState(false);
  
  const [view, setView] = useState('default');

  const { loanCollateralTemplate } = useSelector(state => state.loanCollateralReducer);
	const { loanCollaterals } = useSelector((state) => state.loanCollateralReducer);

  const [collaterals, setCollaterals] = useState([]);
  const [collateralToBeAdded, setCollateralToBeAdded] = useState({
    type: '',
    value: '',
    description: '',
  });

  const changeCurrentView = (view) => setView(view);

  const resetCollateralToBeAdded = () => {
    setCollateralToBeAdded({
      type: '',
      value: '',
      description: '',
    });
  };

  const addCollateral = () => {
    setCollaterals(collaterals => [
      ...collaterals.filter(collateral => collateral.type),
      {
        ...collateralToBeAdded,
        id: collaterals.length,
        name: loanCollateralTemplate?.allowedCollateralTypes?.find(
          type => type?.id == collateralToBeAdded?.type
        )?.name,
      },
    ]);
    resetCollateralToBeAdded();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCollateralToBeAdded(collateral => ({
      ...collateral,
      [name]: value,
    }));
  };

  const modifyCollateralValue = (id, value) => {
    setCollaterals(collaterals.map(
      collateral => (id === collateral.id ? {
        ...collateral,
        value
      } : collateral))
    );
  };

  const removeCollateral = (idToBeRemoved) => {
    setCollaterals(
      collaterals
      ?.filter(collateral => collateral?.id !== idToBeRemoved)
    );
  };

  const callback = () => {
    changeCurrentView('default');
    setCollaterals([]);
    dispatch(fetchCollateralsAssociatedWithALoan(currentId));
  };

  const submitCollaterals = () => {
    dispatch(addCollateralsToAPendingLoan(currentId, {
      collateral: collaterals?.map(collateral => ({
        type: Number(collateral?.type),
        value: collateral?.value,
        description: collateral?.description
      })),
      locale:"en",
      dateFormat: "dd MMM yyyy",
    }, callback));
  };

  useEffect(() => {
    clientId && dispatch(fetchCollateralTemplate(clientId));
    currentId && dispatch(fetchCollateralsAssociatedWithALoan(currentId));
  }, [clientId, currentId]);

  const handleTakeActions = () => {
    return (
      <>
        {" "}
        <div className="blockUi">
          <div className="container-fluid">
            <div className="spaceBetweenFlexEnd">
              <div className="new-button-menus">
                <div className="row">
                  <div className="spaceBetweenFlexEnd w-100">
                    <div
                      onClick={() => setShowMenu(false)}
                      className="p-relative mb-3 pointer"
                    >
                      <img src={ArrowCancelSvg} alt="" />
                    </div>
                  </div>
                </div>
                {/* "APPROVE_LOAN" */}
                {!status.closed && status.value !== "Rejected" ? (
                  <>
                    {status.pendingApproval &&
                      CheckPermission("APPROVE_LOAN", permissions) ? (
                      <div
                        id="approve"
                        onClick={(e) => {
                          currentContent(e);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">Approve</span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}{" "}
                    {/* MODIFY_LOANACCOUNT */}
                    {!status.active &&
                      !status.waitingForDisbursal &&
                      CheckPermission("UPDATE_LOAN", permissions) ? (
                      <div
                        className=""
                        id="modifyApplication"
                        onClick={(e) => {
                          e.stopPropagation();
                          history.push(`/core/client/edit-loan-account/${id}`);
                        }}
                      >
                        <TabButton color="#005BFC">
                          <span className="new-design-text">
                            Modify Application{" "}
                          </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {/* "REJECT_LOAN" */}
                    {status.active === false &&
                      !status.waitingForDisbursal &&
                      CheckPermission("REJECT_LOAN", permissions) ? (
                      <div
                        id="reject"
                        onClick={(e) => {
                          currentContent(e);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">Reject </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {/* "UndoApproved_LOAN" */}
                    {status.value === "Approved" &&
                      status.waitingForDisbursal &&
                      CheckPermission("APPROVALUNDO_LOAN", permissions) ? (
                      <div
                        id="undoApproval"
                        onClick={(e) => {
                          currentContent(e);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">
                            Undo Approval{" "}
                          </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {/* "Approved" */}
                    {status.value === "Approved" &&
                      status.waitingForDisbursal &&
                      CheckPermission("DISBURSE_LOAN", permissions) &&
                      CheckPermission("DISBURSETOSAVINGS_LOAN", permissions) ? (
                      <div
                        id="disburseLoan"
                        onClick={(e) => {
                          currentContent(e);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">Disbursal</span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {/* "prepayLoan" */}
                    {status.active && CheckPermission("REPAYMENT_LOAN", permissions) ? (
                      <div
                        id="prepayLoan"
                        onClick={(e) => {
                          currentContent(e);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">Prepay Loan </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {/* "FORECLOSURE_LOAN" */}
                    {status.active &&
                      CheckPermission("FORECLOSURE_LOAN", permissions) &&
                      CheckPermission("FORECLOSUREWITHLINKED_LOAN", permissions) ? (
                      <div
                        id="loanForeclosure"
                        onClick={(e) => {
                          currentContent(e);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">ForeClosure </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}{" "}
                    {/* "REPAYMENT_LOAN" */}
                    {status.active && CheckPermission("REPAYMENT_LOAN", permissions) ? (
                      <div
                        id="loanRepayment"
                        onClick={(e) => {
                          currentContent(e);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">
                            Make Repayment{" "}
                          </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {status.active &&  CheckPermission("DISBURSALUNDO_LOAN", permissions) ? (
                      <div
                        id="undoDisbursal"
                        onClick={(e) => {
                          currentContent(e);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">
                            Undo Disbursal{" "}
                          </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {/* "AddGuarantor" */}
                    {status.active &&
                      CheckPermission("UPDATE_GUARANTOR", permissions) &&
                      CheckPermission("CREATE_GUARANTOR", permissions) ? (
                      <div
                        id="addGuarantor"
                        onClick={(e) => {
                          currentContent(e);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">
                            Add Guarantor{" "}
                          </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {/* "View Guarantor" */}
                    {status.active && CheckPermission("READ_GUARANTOR", permissions) ? (
                      <div
                        id="addGuarantor"
                        onClick={(e) => {
                          setCurrentView(3);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">
                            View Guarantor{" "}
                          </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {/* "View Collateral(s)" */}
                    {loanCollaterals?.length > 0 &&
                      status?.active &&
                      CheckPermission("READ_COLLATERAL", permissions) ? (
                      <div
                        id="addCollateral"
                        onClick={(e) => {
                          setCurrentView(4);
                          setShowMenu(false);
                        }}
                      >
                        <TabButton color="#2C1DFF">
                          <span className="new-design-text">
                            View Collateral{" "}
                          </span>
                          <img className="ml-4" src={ArrowForwardSvg} alt="" />
                        </TabButton>
                      </div>
                    ) : null}
                    {/*  "WAIVEINTERESTPORTION_LOAN" */}
                    {status.active ? (
                      <>
                        {CheckPermission(
                          "WAIVEINTERESTPORTION_LOAN",
                          permissions
                        ) ? (
                          <>
                            <div
                              id="waiveInterest"
                              onClick={(e) => {
                                currentContent(e);
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Waive Interest{" "}
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}

                        {(CheckPermission("READ_RESCHEDULELOAN", permissions) &&
                          CheckPermission("CREATE_RESCHEDULELOAN", permissions)) ? (
                          <>
                            {" "}
                            <div
                              id="reschedule"
                              onClick={(e) => {
                                // currentContent(e);
                                setShowMenu(false);
                                changeCurrentView('reschedule');
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Reschedule{" "}
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}

                        {CheckPermission("WRITEOFF_LOAN", permissions) ? (
                          <>
                            {" "}
                            <div
                              id="writeOffLoanAccount"
                              onClick={(e) => {
                                currentContent(e);
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Write-Off{" "}
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {/* 
                        {CheckPermission(
                          "CLOSEASRESCHEDULED_LOAN",
                          permissions
                        ) ? (
                          <>
                            {" "}
                            <div
                              id="closeLoanAccountSchedule"
                              onClick={(e) => {
                                currentContent(e);
                                setCloseType("scheduledClose");
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Close{" "}
                                  <span className="small-text">
                                    (As Restructure)
                                  </span>{" "}
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )} */}
                      </>
                    ) : (
                      // assignLoanOfficer
                      <>
                        {CheckPermission(
                          "UPDATELOANOFFICER_LOAN",
                          permissions
                        ) ? (
                          <>
                            {" "}
                            <div
                              id="assignLoanOfficer"
                              onClick={(e) => {
                                currentContent(e);
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  {!!loanOfficerName ? "Change" : "Assign"} Loan
                                  Officer{" "}
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {/* withdrawnByClient */}
                        {CheckPermission("WITHDRAW_LOAN", permissions) ? (
                          <>
                            <div
                              id="withdrawnByClient"
                              onClick={(e) => {
                                currentContent(e);
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Withdrawn by Client{" "}
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        
                        {/* DELETE_LOAN */}
                        {CheckPermission("DELETE_LOAN", permissions) ? (
                          <>
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                handleLoanDelete();
                                setShowMenu(false);
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">Delete </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {/* addCollateral */}
                        {status.pendingApproval && CheckPermission("CREATE_COLLATERAL", permissions) ? (
                          <>
                            {" "}
                            <div
                              id="addCollateral"
                              onClick={(e) => {
                                // currentContent(e);
                                setShowMenu(false);
                                changeCurrentView('collateral');
                              }}
                            >
                              <TabButton color="#2C1DFF">
                                <span className="new-design-text">
                                  Add Collateral{" "}
                                </span>
                                <img
                                  className="ml-4"
                                  src={ArrowForwardSvg}
                                  alt=""
                                />
                              </TabButton>
                            </div>{" "}
                          </>
                        ) : (
                          ""
                        )}
                        {/* "View Collateral(s)" */}
                        {loanCollaterals?.length > 0 &&
                          (status?.pendingApproval || status?.waitingForDisbursal) &&
                          CheckPermission("READ_COLLATERAL", permissions) ? (
                          <div
                            id="addCollateral"
                            onClick={(e) => {
                              setCurrentView(4);
                              setShowMenu(false);
                            }}
                          >
                            <TabButton color="#2C1DFF">
                              <span className="new-design-text">
                                View Collateral{" "}
                              </span>
                              <img className="ml-4" src={ArrowForwardSvg} alt="" />
                            </TabButton>
                          </div>
                        ) : null}
                      </>
                    )}
                    {/* ADD_CHARGE */}
                    {CheckPermission("CREATE_LOANCHARGE", permissions) ? (
                      <>
                        <div
                          id="addCharge"
                          onClick={(e) => {
                            currentContent(e);
                            setShowMenu(false);
                          }}
                        >
                          <TabButton color="#2C1DFF">
                            <span className="new-design-text">Add Charge </span>
                            <img
                              className="ml-4"
                              src={ArrowForwardSvg}
                              alt=""
                            />
                          </TabButton>
                        </div>{" "}
                      </>
                    ) : (
                      ""
                    )}
                    {/* <div className="my-3">
                      <Dropdown
                        overlay={
                          status.active === true ? activeMenu : nonActiveMenu
                        }
                        placement="bottomLeft"
                      >
                        <ActionButton color="#2C1DFF">
                          <img
                            src={
                              require("../../../../assets/images/icons/button-dropdown.svg")
                                .default
                            }
                            className="mr-2"
                            alt="more"
                          />
                          More Options
                        </ActionButton>
                      </Dropdown>
                    </div> */}
                  </>
                ) : null}
                {/* "GENERATE LOAN LETTER AND STATEMENT" */}
                {(status.active || status.closed) &&
                  CheckPermission('READ_REPORT', permissions) ? (
                  <>
                    <div
                      id="generateLetter"
                      onClick={(e) => {
                        fetchLoanLetters(id, currency?.code);
                        currentContent(e);
                        setShowMenu(false);
                      }}
                    >
                      <TabButton color="#2C1DFF">
                        <span className="new-design-text">
                          Generate Letter{" "}
                        </span>
                        <img
                          className="ml-4"
                          src={ArrowForwardSvg}
                          alt=""
                        />
                      </TabButton>
                    </div>
                    <div
                      id="generateStatement"
                      onClick={(e) => {
                        fetchLoanStatement(id);
                        currentContent(e);
                        setShowMenu(false);
                      }}
                    >
                      <TabButton color="#2C1DFF">
                        <span className="new-design-text">
                          Generate Statement{" "}
                        </span>
                        <img
                          className="ml-4"
                          src={ArrowForwardSvg}
                          alt=""
                        />
                      </TabButton>
                    </div>
                  </>
                ) : <></>}

                {/* "RECOVERYPAYMENT_LOAN" */}
                {status.closed === true &&
                  status.active === false &&
                  status.value === "Closed (written off)" &&
                  status.id === 601 &&
                  CheckPermission("RECOVERYPAYMENT_LOAN", permissions) ? (
                  <>
                    <div
                      id="recoveryRepayment"
                      onClick={(e) => {
                        currentContent(e);
                        setShowMenu(false);
                      }}
                    >
                      <TabButton color="#2C1DFF">
                        <span className="new-design-text">
                          Recovery Repayment{" "}
                        </span>
                        <img className="ml-4" src={ArrowForwardSvg} alt="" />
                      </TabButton>
                    </div>{" "}
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <ModalContainer show={modalState} dialogClassName="document_infoModal">
        {modalState && displayModalContent()}
      </ModalContainer>
      {showMenu && handleTakeActions()}
      <div className="">
        {view === 'collateral' ? (
          <ContentWrapper>
            <div className="row">
              <div className="col-xl-6 col-md-6">
                <p onClick={() => changeCurrentView('default')} className="back-btn d-flex">
                  <img className="mr-2" src={BackSvg} alt="" />
                  Back
                </p>
              </div>
            </div>
            <h3 className="py-5" style={{ fontWeight: 600 }}>Add Collateral</h3>
            <div className="d-flex flex-column w-100">
              <div className="d-flex justify-content-between w-100" style={{ gap: '1rem' }}>
                <div className="form-group d-flex flex-column select-100-width" style={{ width: '33%' }}>
                  <label className="text-label mt-1">Collateral Type</label>
                  <Select
                    placeholder="Select one"
                    size="md"
                    value={collateralToBeAdded.type}
                    name="type"
                    onChange={handleChange}
                  >
                    {loanCollateralTemplate?.allowedCollateralTypes?.map((type) => (
                      <option className="w-100" value={type.id} key={type.id}>
                        {type.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="form-group d-flex flex-column" style={{ width: '33%' }}>
                  <label className="text-label mt-1">Value</label>
                  <div className="d-flex flex-column">
                    <CurrencyFormat
                      value={collateralToBeAdded?.value}
                      thousandSeparator={true}
                      className="amount-input"
                      // prefix={template?.currency?.displaySymbol}
                      onValueChange={({ value }) => {
                        setCollateralToBeAdded(collateral => ({
                          ...collateral,
                          value
                        }));
                      }}
                    />
                    <div className="d-flex align-items-end mt-1">
                      <img
                        src={require("../../../../assets/images/icons/info-circle-2.svg").default}
                        width={18}
                        height={18}
                        alt="tooltip icon"
                      />
                      <span className="fs-10 ml-1">Amount the collateral guarantees.</span>
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex flex-column" style={{ width: '33%' }}>
                  <label className="text-label mt-1">Description</label>
                  <div className="w-100">
                    <Textarea
                      style={{ fontSize: "12px", minWidth: '100%' }}
                      name="description"
                      value={collateralToBeAdded?.description}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-self-end" style={{ width: "fit-content" }}>
                <ActionButton
                  color={
                    collateralToBeAdded.type &&
                    collateralToBeAdded.value &&
                    collateralToBeAdded.description
                    ? "#2C1DFF"
                    : "#e3e3ff"
                  }
                  disabled={
                    !collateralToBeAdded.type &&
                    !collateralToBeAdded.value &&
                    !collateralToBeAdded.description
                  }
                  onClick={addCollateral}
                >
                  <img
                    src={
                      require("../../../../assets/images/icons/add.svg")
                        .default
                    }
                    alt="forward icon"
                  />
                  Add Collateral
                </ActionButton>
              </div>
            </div>

            {collaterals
              ?.filter(collateral => collateral.type)
              ?.length ? (
              <Table className="child-table mt-4">
                <thead>
                  <tr style={{ backgroundColor: "#FAFAFA" }}>
                    <th>S/N</th>
                    <th>Collateral Type</th>
                    <th>Value</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {collaterals?.map((collateral, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{collateral?.name}</td>
                      <CurrencyFormat
                        value={collateral?.value}
                        thousandSeparator={true}
                        className="amount-input mt-1"
                        // prefix={template?.currency?.displaySymbol}
                        onValueChange={({ value }) => {
                          modifyCollateralValue(collateral.id, value);
                        }}
                      />
                      <td>{collateral?.description}</td>
                      <td>
                        <IconButton
                          onClick={() => removeCollateral(collateral?.id)}
                          id="removeCollateral"
                          className="mr-1 mb-1"
                        >
                          <img
                            src={
                              require("../../../../assets/images/icons/delete.svg")
                                .default
                            }
                            alt="remove collateral"
                            className="icon-img"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : <></>}

            {collaterals.length > 0 && (
              <div className="d-flex justify-content-end mt-5">
                <ActionButton
                  onClick={submitCollaterals}
                  color={"#2C1DFF"}
                >
                  Submit
                  <img
                    src={
                      require("../../../../assets/images/icons/arrow-right.svg")
                        .default
                    }
                    className="ml-2"
                    alt="submit collaterals"
                  />
                </ActionButton>
              </div>
            )}
          </ContentWrapper>
        ) : view === 'reschedule' ? (
          <ContentWrapper>
            <div className="row mb-4">
              <div className="col-xl-6 col-md-6">
                <p onClick={() => changeCurrentView('default')} className="back-btn d-flex">
                  <img className="mr-2" src={BackSvg} alt="" />
                  Back
                </p>
              </div>
            </div>
            <RescheduleLoan
              id={currentId}
              changeCurrentView={changeCurrentView}
              resourceId={resourceId}
              setResourceId={setResourceId}
            />
          </ContentWrapper>
        ) : view === 'view_schedule' ? (
          <ContentWrapper>
            <div className="row">
              <div className="col-xl-6 col-md-6">
                <p onClick={() => changeCurrentView('reschedule')} className="back-btn d-flex">
                  <img className="mr-2" src={BackSvg} alt="" />
                  Back
                </p>
              </div>
            </div>
            <PreviewRepaymentSchedule
              loanId={currentId}
              id={resourceId}
              changeCurrentView={changeCurrentView}
            />
          </ContentWrapper>
        ) : (
          <ContentWrapper>
            <div className="row">
              <div className="col-xl-6 col-md-6">
                <p onClick={() => setCurrentView(1)} className="back-btn d-flex">
                  <img className="mr-2" src={BackSvg} alt="" />
                  Back
                </p>
              </div>
              {loanDetails && status.value !== "Rejected"
               ? <div className="col-xl-6 col-md-6">
                <div className="spaceBetweenFlexEnd">
                  <ActionButton
                    onClick={() => setShowMenu(!showMenu)}
                    color="#2C1DFF"
                  >
                    Take Action
                    <img
                      src={
                        require("../../../../assets/images/icons/arrow-right.svg")
                          .default
                      }
                      className="ml-2"
                      alt="take action"
                    />
                  </ActionButton>
                </div>
              </div> : null}

            </div>
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div className="spaceBetweenFlexEnd"></div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-xl-12 col-md-12">
                <p className="account-details">
                  <span className="headerSpan">Linked account</span>:{" "}
                  {!!linkedAccount?.accountNo ? linkedAccount?.accountNo : ""}
                </p>
              </div>
              <div className="col">
                <p className="account-details">
                  <span className="headerSpan">Product</span>:{" "}
                  {!!loanProductName ? loanProductName : ""}
                </p>
                <p className="account-details">
                  <span className="headerSpan">Account No</span>:{" "}
                  {!!accountNo ? accountNo : ""}
                </p>
                {status.active ? (
                  <p className="account-details">
                    <span className="headerSpan">Loan Balance</span>:{" "}
                    {formatCurrency(totalOutstanding)}
                  </p>
                ) : null}{" "}
                {status.active ? (
                  <p className="account-details">
                    <span className="headerSpan">Arrears By</span>:{" "}
                    {formatCurrency(summary.totalOverdue)}
                  </p>
                ) : null}
              </div>
            </div>
            {/* <div className="break"></div> */}

            {/* Chart Goes here */}
            {/* <LoanChart id={id} /> */}

            <div className="break"></div>
            <div className="row mt-2">
              <Tabs defaultActiveKey="loanDetails" className="full-width">
                <TabPane tab="Loan Details" key="loanDetails">
                  <div className="full-width">
                    <LoanDetails loanDetails={loanDetails} />
                  </div>
                </TabPane>
                <TabPane tab="Account Details" key="1">
                  <div className="full-width">
                    <AccountDetails details={loanDetails} />
                  </div>
                </TabPane>
                <TabPane tab="Repayment Schedule" key="2">
                  <RepaymentSchedule
                    loanId={id}
                    accountNo={accountNo}
                    repaymentSchedule={repaymentSchedule}
                  />
                </TabPane>
                <TabPane tab="Charges" key="3">
                  <LoanCharges
                    accountNo={accountNo}
                    id={id}
                    history={history}
                    charges={charges}
                  />
                </TabPane>
                <TabPane tab="Transactions" key="4">
                  <Transactions
                    transactions={transactions}
                    id={id}
                    accountNo={accountNo}
                  />
                </TabPane>{" "}
                {/* <TabPane tab="Loan Audit" key="audit">
                  <LoanAudit accountNo={accountNo} id={id} />
                </TabPane> */}
                <TabPane tab="Loan Documents" key="5">
                  <LoanDocuments
                    id={id}
                    history={history}
                  />
                </TabPane>
                <TabPane tab="Data Tables" key="dataTables">
                  <DataTables
                    id={id}
                    type="loan"
                    dataTables={applicableDataTables}
                  />
                </TabPane>
                {CheckPermission("READ_LOANNOTE", permissions) ? (
                  <>
                    {" "}
                    <TabPane tab="Notes" key="6">
                      <Notes id={id} />
                    </TabPane>
                  </>
                ) : (
                  ""
                )}
              </Tabs>
            </div>
          </ContentWrapper>
        )}
      </div>
    </>
  );
};

export default CustomerLoanDetails;
